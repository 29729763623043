import { CallWayCall } from 'src/app/dispatcher/models/dispatcher';

export const getInfoAbountIncomingCall = (invitation): CallWayCall => {
  const callUuid = invitation.incomingInviteRequest.message.callId;
  let queue = '';
  let callId = '';
  let prevQueue = '';
  let tbrs = '0';
  if (invitation.incomingInviteRequest.message?.headers['X-Callcenter-Payload']) {
    const info = getInviteHeadersParams(invitation?.incomingInviteRequest?.message?.headers['X-Callcenter-Payload'][0]?.raw);
    console.log(info);
    queue = info.qid;
    callId = info.sid;
    prevQueue = info.pqid;
    tbrs = info.tbrs;
  }
  const phone = invitation.incomingInviteRequest.message.from.uri.normal.user;
  const cseq = invitation.incomingInviteRequest.message.cseq;
  const callType = invitation.incomingPassOrDriver?.callType;
  return { call_uuid: callUuid, call_id: callId, message: '', phone_number: phone, queue, cseq, call_type: callType, prevQueue, tbrs };
};

const getInviteHeadersParams = (str: string): any => {
  const response = {};
  if (str) {
    const headersArr = str.slice(1).split('&');
    headersArr.forEach(headerItem => {
      const param = headerItem.split('=');
      if (param.length && param[0] && param[1]) {
        response[param[0]] = param[1];
      }
    });
  }
  return response;
};
