import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  AddWithdrawalCardResult,
  DispatcherAccount,
  PreCheckInterface,
  WithdrawalCard
} from '../models/dispatcher.models';

@Injectable({
  providedIn: 'root'
})
export class DispatcherApiService {

  constructor(private httpClient: HttpClient) {
  }

  getAccount(): Observable<DispatcherAccount> {
    return this.httpClient.get<DispatcherAccount>('dispatcher/me');
  }

  addWithdrawalCard(): Observable<AddWithdrawalCardResult> {
    return this.httpClient.post<AddWithdrawalCardResult>('dispatcher/withdrawal_card', null);
  }

  setDefaultWithdrawalCard(withdrawalCard: WithdrawalCard): Observable<void> {
    return this.httpClient.put<void>('dispatcher/withdrawal_card', {
      uuid: withdrawalCard.uuid,
      is_default: true,
    });
  }

  updateWithdrawalCardTitle(withdrawalCard: WithdrawalCard, title: string): Observable<void> {
    return this.httpClient.put<void>('dispatcher/withdrawal_card', {
      uuid: withdrawalCard.uuid,
      title,
    });
  }

  deleteWithdrawalCard(withdrawalCard: WithdrawalCard): Observable<void> {
    return this.httpClient.delete<void>(`dispatcher/withdrawal_card/${withdrawalCard.uuid}`);
  }

  getPreStartCheck(): Observable<{data: PreCheckInterface}> {
    return this.httpClient.get<{data: PreCheckInterface}>('operator/working-shifts/pre-start-check');
  }

}
