import {Passenger, Fare, AddedKmToOrderInterface} from './../../interfaces/order.interface';
import { TimezoneService, TaxiServicesService, StateService, HandleEventService } from 'src/app/services';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { getWaypoints, getPreorderInfoObj } from '../utils/form-utils';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {shareReplay} from 'rxjs/operators';
import {OrderClass} from '../../classes/order.class';

@Injectable()
export class OrderFormService {
  generalForm: UntypedFormGroup;
  bonusBalance: number;
  completedRequestsCount: number;
  order: OrderClass;
  fare: Fare;
  paymentType = 'cash';
  addedValue = 0;
  amount: number; // order amount. like in order-form-estimate.ts amount
  blockPassReason;
  intercityMinKM;
  isEditMode = false;

  orderFormOpened = false; // !!!use dialogRef from order-modal service instead

  focusOnFirstAddress$ = new Subject();
  overflowAddressesContainer$: Subject<number> = new Subject();

  disableSaveButtons$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isEstimationProgress$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  overdraftBalance$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  overdraftBalanceObservable$: Observable<number> = this.overdraftBalance$.asObservable().pipe(shareReplay(1));
  overdraftIncluded$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  addDistance$: BehaviorSubject<AddedKmToOrderInterface> = new BehaviorSubject<AddedKmToOrderInterface>(null);
  preorderMessageActive$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  addressFormSelect$: Subject<boolean> = new Subject();



  initialProductId = '';

  public orderFormOpenedStream$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private fb: UntypedFormBuilder,
    private tzService: TimezoneService,
    private handleEventService: HandleEventService,
    private taxiServicesService: TaxiServicesService,
    private stateService: StateService
  ) {}

  generateAddressForm(order?: OrderClass) {
    if (order) {
      return this.fb.group({
        addresses: this.fb.array(this.buildAddresses(order.waypoints)),
        disp_comment: [order.disp_comment ? order.disp_comment : ''],
        pass_comment: [order.pass_comment ? order.pass_comment : ''],
        entrance: [order.start_comment ? order.start_comment : ''], // entrance number
        features: [this.getOrderFeatures(order)]
      });
    } else {
      this.paymentType = 'cash';
      return this.fb.group({
        addresses: this.fb.array([this.buildDestAddress(true), this.buildDestAddress(true)]),
        disp_comment: [{ value: '', disabled: true }],
        pass_comment: [{ value: '', disabled: true }],
        entrance: [{ value: '', disabled: true }], // entrance number
        features: []
      });
    }
  }

  checkIntercityDistance(): void {
    let result: any = '';
    if (this.fare?.incity?.length > 0 && this.fare?.intercity?.length > 0) {
      result = (this.fare.intercity || []).reduce((acc: any, curr: any) => {
        return acc + curr.distance;
      }, 0);

      result = +result / 1000;
    } else {
      this.fare?.breakdown.forEach(b => {
        if (b.display_name && (b.display_name === 'near_intercity' || b.display_name === 'intercity')) {
          result = b.unit_qty;
        }
      });
    }

    if (this.intercityMinKM > 0 && Number(result) > Number(this.intercityMinKM) && !this.isEditMode) {
      this.isEstimationProgress$.next(true);
      const basic = this.generalForm.get('basic');
      const serviceId = basic.value.taxiService;
      let serviceProducts = [];
      if (this.taxiServicesService.productsList[serviceId]) {
        serviceProducts = this.taxiServicesService.productsList[serviceId].filter(product => product.show_in_list);
      }
      const defProduct = serviceProducts.find(product => product.is_intercity_default);
      const defProductId = defProduct && defProduct.id ? defProduct.id : '';

      basic.get('tariff').patchValue(defProductId);
      this.handleEventService.openSnackBar('UTAX_TARIFF_CHANGED_TO_INTERCITY_DEFAULT', 3 , true);
    } else {
      this.isEstimationProgress$.next(false);
    }
  }

  prepareOrderToSave(paymentMethodInfo?, fare?) {
    const formValue = this.generalForm.getRawValue();
    const waypoints = formValue.addresses.addresses;
    const callId = formValue.basic.call_id ? { call_id: formValue.basic.call_id } : {};
    const response = {
      comment: formValue.addresses.disp_comment,
      id: this.order ? this.order.id : null,
      pass_comment: formValue.addresses.pass_comment,
      passenger_id: formValue.basic.passengerId,
      ...this.getFareIdToSave(fare),
      phone_number: formValue.basic.contactPhone,
      payment_type: this.paymentType,
      product_id: formValue.basic.tariff,
      is_incomplete: formValue.type.isIncomplete,
      start_comment: formValue.addresses.entrance,
      service: formValue.basic.taxiService,
      ...callId,
      ...paymentMethodInfo,
      ...getPreorderInfoObj(formValue),
      ...getWaypoints(waypoints),
      overdraft_amount: this.overdraftBalance$.value
    };
    if (callId.call_id) {
      response['call_id'] = callId.call_id;
    }
    if (formValue.basic.receiver?.phone_number) {
      response.receiver = formValue.basic.receiver;
    }
    return response;
  }

  generateBasicOrderForm(order?: OrderClass, serviceIdNum?: number): UntypedFormGroup {
    if (order) {
      this.isEditMode = true;
      return this.fb.group({
        taxiService: [order.service_id],
        tariff: [order.product.id],
        clientPhone: [
          { value: order.passenger.phone_number, disabled: true },
          [Validators.pattern('[+]?38[0-9]{10}'), Validators.required]
        ],
        contactPhone: [order.phone_number, [Validators.pattern('[+]?38[0-9]{10}')]],
        passengerId: [order.passenger.id, Validators.required],
        passengerName: [order.passenger.name],
        commentAboutPassenger: [order.passenger.comment],
        call_id: [''],
        receiver: this.fb.group({
          name: [order.receiver?.name || ''],
          phone_number: [order.receiver?.phone_number || ''],
          is_sender: [typeof order.receiver?.is_sender === 'boolean' ? order.receiver?.is_sender : null],
          is_sender_pay: [order.receiver?.is_sender_pay || false]
        }),
      });
    } else {
      this.isEditMode = false;
      const serviceId = localStorage.getItem('service') ? +localStorage.getItem('service') : serviceIdNum;
      const defProductId = this.getDefaultProductId(serviceId);

      return this.fb.group({
        taxiService: [serviceId],
        tariff: [defProductId ? defProductId : '', [Validators.required]],
        clientPhone: ['', [Validators.pattern('[+]?38[0-9]{10}'), Validators.required]],
        contactPhone: [{ value: '', disabled: true }, [Validators.pattern('[+]?38[0-9]{10}')]],
        passengerId: [null, Validators.required],
        passengerName: [{ value: '', disabled: true }],
        commentAboutPassenger: [{ value: '', disabled: true }],
        call_id: [''],
        receiver: this.fb.group({
          name: [''],
          phone_number: [''],
          is_sender: [null],
          is_sender_pay: [null]
        }),
      });
    }
  }

  private getDefaultProductId(serviceId: number) {
    let serviceProducts = [];
    if (this.taxiServicesService.productsList[serviceId]) {
      serviceProducts = this.taxiServicesService.productsList[serviceId].filter(product => product.show_in_list);
    }
    const defProduct = serviceProducts.find(product => product.is_default);
    let defProductId = defProduct && defProduct.id ? defProduct.id : '';
    if (!defProductId) {
      defProductId = serviceProducts && serviceProducts.length > 0 ? serviceProducts[0].id : '';
    }
    return defProductId;
  }

  generateTypeForm(order?: OrderClass): UntypedFormGroup {
    return this.fb.group({
      isPreorder: [{ value: order ? order.is_preorder : false, disabled: !order }],
      preorderedTime: [{ value: this.getPreorderTime(order), disabled: !order }],
      preorderedDate: [{ value: this.getPreorderDate(order), disabled: !order }],
      isIncomplete: [{ value: order ? order.is_incomplete : false, disabled: !order }],
      intercity: [{ value: order ? order.is_intercity : false, disabled: !order }],
      incity: [{ value: order ? order.is_by_the_city : false, disabled: !order }]
    });
  }

  buildDestAddress(disabled: boolean, address?: any): UntypedFormGroup {
    if (address) {
      let isAddressInHometown = true;
      let addressCityCode;
      let intercityAddressName = '';
      let addressCity;
      if (address.relation_id && address.relation_id.split('-').length > 1) {
        // relation id can by: null, 123-aj2j131, dlkjda-qewdk-qewq:123-aj2jr323, 654132456. Where 123 addressCityCode or just addressCode
        const relationIdArray = (address.relation_id as string).split(':');
        if (relationIdArray.length > 1) {
          // pass relationId format like dlkjda-qewdk-qewq:123-aj2jr323
          const relationId = relationIdArray.find(code => code.split('-').length === 2);
          addressCityCode = +relationId.split('-')[0];
        } else {
          addressCityCode = +(address.relation_id as string).split('-')[0];
        }
        addressCity = this.stateService.dumbStore.citiesWithAddresses.find(item => item.code === addressCityCode);
        const orderCity = this.stateService.dumbStore.citiesWithAddresses.find(
          item => item.parent_id === this.order?.city_id
        );
        isAddressInHometown = addressCityCode === orderCity?.code;
        if (!isAddressInHometown) {
          if (address && address.name) {
            intercityAddressName = address.name;
          }
        }
      }

      const nameForAddress = this.getNameForAddress(isAddressInHometown, addressCityCode, address, addressCity);
      const cityKey = addressCity && addressCity.key ? addressCity.key : '';

      return this.fb.group({
        name: [nameForAddress],
        id: [address.id],
        lat: [address.lat, [Validators.required]],
        lng: [address.lng, [Validators.required]],
        type: [address.type],
        intercity: [address.intercity],
        ord: [address.ord],
        address_id: [address.address_id],
        relation_id: [address.relation_id],
        cityWithAddresses: this.fb.group({
          cityKey: [isAddressInHometown || !addressCityCode ? '' : cityKey],
          name: [isAddressInHometown || !addressCityCode ? '' : intercityAddressName],
          relation_id: ['']
        })
      });
    }
    return this.fb.group({
      name: [{ value: '', disabled }, [Validators.required]],
      lat: [{ value: '', disabled }, [Validators.required]],
      lng: [{ value: '', disabled }, [Validators.required]],
      type: [{ value: '', disabled }],
      ord: [{ value: '', disabled }],
      intercity: [{ value: false, disabled }],
      address_id: [{ value: null, disabled }],
      id: [{ value: null, disabled }],
      relation_id: [{ value: '', disabled }],
      cityWithAddresses: this.fb.group({
        cityKey: [''],
        name: [''],
        relation_id: ['']
      })
    });
  }

  setPassengerInForm(passenger: Passenger) {
    if (passenger.data.is_blocked) {
      this.blockPassReason = {
        time: this.tzService.utcToTz(passenger.block_time).format('DD.MM.YYYY HH:mm'),
        reason: passenger.block_reason ? passenger.block_reason.name : '',
        reason_comment: passenger.block_comment ? passenger.block_comment : ''
      };
    }else {
      this.blockPassReason = null;
    }
    const basic = this.generalForm.get('basic');
    if (!this.order) {
      this.overdraftBalance$.next(passenger.overdraft_balance);
    }
    this.bonusBalance = passenger.bonus_balance;
    this.completedRequestsCount = passenger.completed_requests_count;
    basic.get('passengerId').patchValue(passenger.data.id);
    basic.get('clientPhone').patchValue(passenger.data.phone_number);
    basic.get('passengerName').patchValue(passenger.data.name);
    basic.get('commentAboutPassenger').patchValue(passenger.data.comment);
    console.log('PC: OrderFormService -> setPassengerInForm -> passenger', passenger);
  }

  clearForm() {
    console.log('Clear form');
    this.generalForm.controls.addresses.reset();
    // this.bonusBalance = undefined;
    // this.completedRequestsCount = undefined;
    this.fare = undefined;
  }

  setCallWayInfo(formInfo, services: any[], transferQueues?: any[]) {
    let phoneCode = '';
    if (formInfo.call.phone_number[0] == '+') {
      phoneCode = '';
    } else if (
      formInfo.call.phone_number[0] === '3' &&
      formInfo.call.phone_number[1] === '8'
    ) {
      phoneCode = '+';
    } else {
      phoneCode = '+38';
    }

    if (formInfo.queue && formInfo.call) {
      if (formInfo.queue.isSpecial) {
        const prevQueueId = formInfo.call.prevQueue;
        const prevQueue = transferQueues.find(q => q.mappingId === prevQueueId);
        formInfo.queue = prevQueue;
      }

      const taxi = services.find(s => s.id === formInfo.queue.taxiServiceId);
      const serviceId = ((taxi && taxi.externalId) ? +taxi.externalId : formInfo.queue.taxiServiceId);
      (this.generalForm.controls.basic as UntypedFormGroup).controls.taxiService.patchValue(serviceId);
      const defaultProductId = this.getDefaultProductId(serviceId);
      (this.generalForm.controls.basic as UntypedFormGroup).controls.tariff.patchValue(formInfo.queue.productId || defaultProductId);
      (this.generalForm.controls.basic as UntypedFormGroup).controls.clientPhone.patchValue(`${phoneCode}${formInfo.call.phone_number}`);
      (this.generalForm.controls.basic as UntypedFormGroup).controls.call_id.patchValue(formInfo.call.call_id);
      this.initialProductId = formInfo.queue.productId;
    } else if (formInfo.call) {
      (this.generalForm.controls.basic as UntypedFormGroup).controls.clientPhone.patchValue(`${phoneCode}${formInfo.call.phone_number}`);
      (this.generalForm.controls.basic as UntypedFormGroup).controls.call_id.patchValue(formInfo.call.call_id);
    }
  }

  checkboxesChange(rout: string) {
    const isIncity = (this.generalForm.controls.type as UntypedFormGroup).controls.incity.value;
    switch (rout) {
      case 'incity':
        if (isIncity) {
          this.generalForm.markAsTouched();
          (this.generalForm.controls.type as UntypedFormGroup).controls.isIncomplete.setValue(true);
        }
        break;
    }
  }

  clear() {
    this.bonusBalance = 0;
    this.overdraftBalance$.next(0);
    this.completedRequestsCount = 0;
    this.order = null;
    this.fare = null;
  }

  getTimeToLock(): number {
    const secondsToWait = this.taxiServicesService.servicesSettings[this.order.service_id]
      .requests_operator_lock_timeout;
    return secondsToWait * 1000;
  }

  private getNameForAddress(isAddressInHometown, addressCityCode, address, addressCity): string {
    let addressName = '';
    if (isAddressInHometown || !addressCityCode || !addressCity) {
      if (address && address.name) {
        addressName = address.name;
      }
    } else {
      if (addressCity && addressCity.name) {
        addressName = `мм ${addressCity.name}`;
      }
    }
    return addressName;
  }

  private getFareIdToSave(fare?): object {
    if (fare) {
      return { fare_id: fare.id };
    }
    if (this.order && this.fare.id === this.order.fare.id) {
      return {};
    } else {
      return { fare_id: this.fare.id };
    }
  }

  private getOrderFeatures(order) {
    if (order && order.features.length > 0) {
      return order.features.map(feature => feature.key);
    } else {
      return '';
    }
  }

  private buildAddresses(waypoints: any[]) {
    waypoints.sort((a, b) => {
      return a.ord - b.ord;
    });
    const arr = [];
    if (waypoints.length > 0) {
      waypoints.forEach(el => {
        arr.push(this.buildDestAddress(false, el));
      });
      return arr;
    }
  }

  private getPreorderTime(order: OrderClass): string {
    if (order && order.is_preorder) {
      let min = this.tzService.utcToTz(order.assigned_at).get('minute').toString();
      if (min.length === 1) {
        min = `0${min}`;
      }
      let hour = this.tzService.utcToTz(order.assigned_at).get('hour').toString();
      if (hour.length === 1) {
        hour = `0${hour}`;
      }
      return `${hour}.${min}`;
    } else {
      let min = moment()
        .minutes()
        .toString();
      let hours = moment()
        .hours()
        .toString();
      if (min.length === 1) {
        min = `0${min}`;
      }
      if (hours.length === 1) {
        hours = `0${hours}`;
      }
      return `${hours}.${min}`;
    }
  }

  private getPreorderDate(order: OrderClass) {
    if (order && order.is_preorder) {
      return this.tzService.utcToTz(order.assigned_at);
    } else {
      return moment();
    }
  }
}
