import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CallWayCall, Queue } from 'src/app/dispatcher/models/dispatcher';
import { OrderFormService } from './order-form.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { OrdersService } from 'src/app/services/orders.service';
import { TaxiServicesService } from 'src/app/services/taxi-services.service';
import { BehaviorSubject, Observable } from 'rxjs';
import {map, tap} from 'rxjs/operators';
import * as _ from 'lodash';
import {HandleEventService} from '@global-services/handle-event-service.service';
import {TranslateService} from '@ngx-translate/core';
import {GeneralOrdersApiService} from "@global-services/general-orders-api.service";
import {OrderFormComponent} from "../containers/order-form/order-form.component";

@Injectable({
  providedIn: 'root'
})
export class OrderModalService {
  dialogRef: MatDialogRef<OrderFormComponent>;
  mode = '';
  mode$ = new BehaviorSubject(this.mode);

  constructor(
    private dialog: MatDialog,
    private orderFormService: OrderFormService,
    private http: HttpClient,
    private taxiService: TaxiServicesService,
    private ordersService: OrdersService,
    private handleEventService: HandleEventService,
    private translate: TranslateService,
    private generalOrdersApiService: GeneralOrdersApiService
  ) {
    this.mode$.subscribe(mode => {
      this.mode = mode;
    });
  }

  editOrder(order) {
    this.ordersService.modalOpened = true;
    const config = {
      data: {
        order,
        id: order.id
      },
      disableClose: true,
      height: '100vh',
      width: '100vw',
      panelClass: 'order-form-modal-container',
      autoFocus: false
    };
    const lockParams: any = { id: order.id };
    if (this.taxiService.servicesSettings[order.service_id].sending_round_enabled === '1') {
      lockParams.mode = 'default';
    }

    this.lock(lockParams, order).subscribe(() => {
      if (!this.orderFormService.orderFormOpened) {
        this.dialogRef = this.dialog.open(OrderFormComponent, config);
      }
      this.orderFormService.orderFormOpened = true;
      this.dialogRef.afterClosed().subscribe(res => {
        // @TODO unsub!!
        this.dialogRef = null;
        this.orderFormService.fare = null;
        this.orderFormService.orderFormOpened = false;
        this.ordersService.modalOpened = false;
        this.orderFormService.preorderMessageActive$.next(false);
        this.unlock(order.id).subscribe(() => {
          this.ordersService.updateOrders([], true);
        });
        this.mode$.next('');
      });
    });

    return this.dialogRef;
  }

  createOrder(call?: CallWayCall, queue?: Queue) {
    if (!_.isEmpty(this.taxiService.productsList)) {
      const config = {
        data: {
          call,
          queue
        },
        disableClose: true,
        height: '100vh',
        width: '100vw',
        panelClass: 'order-form-modal-container',
        autoFocus: false
      };

      if (!this.orderFormService.orderFormOpened) {
        this.dialogRef = this.dialog.open(OrderFormComponent, config);
      }

      this.orderFormService.orderFormOpened = true;
      this.dialogRef.afterClosed().subscribe(() => {
        // @TODO unsub!!
        this.dialogRef = null;
        this.orderFormService.fare = null;
        this.orderFormService.orderFormOpened = false;
        this.orderFormService.preorderMessageActive$.next(false);
        this.mode$.next('');
        this.generalOrdersApiService.markCreatingRequestFinish()
          .pipe(
            tap(() => {
              this.generalOrdersApiService.isNewOrder = false;
            })
          )
          .subscribe();
      });
    }
  }

  closeCreateOrderDialog() {
    this.dialogRef?.close();
  }

  lock(params, order?): Observable<boolean> {
    const httpParams: HttpParams = new HttpParams({ fromObject: params });
    return this.http.put<boolean>('operator/requests/lock', {}, { params: httpParams }).pipe(
      tap(res => {
        if (params.mode === 'normal' && !res) {
          let translation = '';
          if (order?.lock_mode === 'normal') {
            translation = this.translate.instant('UTAX_ORDER_ALREADY_EDITED', {name: order.locked_user.name});
          } else if (order?.lock_mode === 'default') {
            translation = this.translate.instant('UTAX_ORDER_ALREADY_VIEWED', {name: order.locked_user.name});
          }
          this.handleEventService.openSnackBar(translation);
          this.mode$.next('default');
        } else {
          this.mode$.next(params.mode);
        }
      })
    );
  }

  unlock(id: string): Observable<any> {
    const httpParams: HttpParams = new HttpParams({ fromObject: { id } });

    return this.http.put('operator/requests/unlock', {}, { params: httpParams }).pipe(
      map(res => {
        this.mode$.next(null);
        this.orderFormService.clear();
      })
    );
  }
}
