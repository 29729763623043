import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { DispatcherApiService } from '../../services/dispatcher-api.service';
import { WithdrawalCard } from '../../models/dispatcher.models';
import { ThemePalette } from '@angular/material/core';
import { HandleEventService } from '../../../services';
import { BalanceTransaction } from '../../models/balance.models';
import { BalanceApiService } from '../../services/balance-api.service';
import {
  WithdrawalReceiptStatus,
  WithdrawalRequest,
  WithdrawalState,
  WithdrawalTransaction
} from '../../models/withdrawal.models';
import { WithdrawalApiService } from '../../services/withdrawal-api.service';
import { HttpErrorResponse } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { BalanceService } from '../../services/balance.service';
import { appendItemsWithoutDuplicates } from '../../../utils/array-utils';
import { MatDialog } from '@angular/material/dialog';
import {ConfirmDeleteDialogComponent, InfoModalComponent} from '../../../shared/components';
import {LocalStorage} from "ngx-webstorage";
import {finalize} from "rxjs/operators";

@Component({
  selector: 'utax-cabinet-balance',
  templateUrl: './cabinet-balance.component.html',
  styleUrls: ['./cabinet-balance.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CabinetBalanceComponent implements OnInit {

  @LocalStorage()
  public savedCardItn;

  private readonly transactionsPageSize = 20;

  private withdrawalCardWindowTimer: number;

  transactionsPage = 0;
  hasMoreTransactions = true;
  transactionsLoading = false;

  withdrawalTransactionsPage = 0;
  hasMoreWithdrawalTransactions = true;
  withdrawalTransactionsLoading = false;

  withdrawalState: WithdrawalState;
  withdrawalCards: WithdrawalCard[];
  transactions: BalanceTransaction[];
  withdrawalTransactions: WithdrawalTransaction[];

  withdrawalLoading = false;
  addWithdrawalCardLoading = false;
  withdrawalCardLoading = false;

  withdrawalAmount: number;
  selectedWithdrawalCard: string;
  withdrawalRequest: WithdrawalRequest;
  withdrawalVerificationCode: string;
  withdrawalErrorMessage: string;

  editableWithdrawalCard: string;
  withdrawalItnValue: string;
  withdrawalMeUuid: string;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private handleEventService: HandleEventService,
    private dispatcherApiService: DispatcherApiService,
    private balanceApiService: BalanceApiService,
    private withdrawalApiService: WithdrawalApiService,
    private translateService: TranslateService,
    private balanceService: BalanceService,
    private matDialog: MatDialog,
  ) {
  }

  ngOnInit(): void {
    this.updateWithdrawalState();
    this.updateDispatcherAccount();
    this.updateTransactions();
    this.updateWithdrawalTransactions();
  }

  private updateWithdrawalState(): void {
    this.withdrawalApiService.getState()
      .subscribe(withdrawalState => {
        this.withdrawalState = withdrawalState;
        this.changeDetectorRef.detectChanges();
      });
  }

  private updateDispatcherAccount(): void {
    this.dispatcherApiService.getAccount()
      .subscribe(dispatcherAccount => {
        this.withdrawalMeUuid = dispatcherAccount.uuid;
        this.withdrawalCards = dispatcherAccount.withdrawal_cards?.filter(withdrawalCard => withdrawalCard.is_active && withdrawalCard.type === 'card') || [];
        if (!this.selectedWithdrawalCard) {
          this.selectedWithdrawalCard = this.withdrawalCards.find(withdrawalCard => withdrawalCard.is_default)?.uuid;
          if (this.selectedWithdrawalCard) {
            this.changeCard(this.selectedWithdrawalCard);
          }
        }
        this.changeDetectorRef.detectChanges();
      });
  }

  private updateTransactions(): void {
    this.transactionsPage = 0;
    this.hasMoreTransactions = true;
    this.loadMoreTransactions();
  }

  private updateWithdrawalTransactions(): void {
    this.withdrawalTransactionsPage = 0;
    this.hasMoreWithdrawalTransactions = true;
    this.loadMoreWithdrawalTransactions();
  }

  requestWithdrawal(): void {
    this.withdrawalLoading = true;
    this.withdrawalErrorMessage = null;
    this.withdrawalRequest = null;
    this.changeDetectorRef.detectChanges();

    this.withdrawalApiService.requestWithdrawal(this.selectedWithdrawalCard, this.withdrawalAmount)
      .subscribe(withdrawalRequest => {
        this.withdrawalLoading = false;
        this.withdrawalRequest = withdrawalRequest;
        this.withdrawalVerificationCode = withdrawalRequest.verification_code;
        this.changeDetectorRef.detectChanges();
      }, error => {
        this.withdrawalLoading = false;
        this.withdrawalErrorMessage = this.getWithdrawalErrorMessage(error);
        this.changeDetectorRef.detectChanges();
      });
  }

  withdraw(): void {
    this.withdrawalLoading = true;
    this.withdrawalErrorMessage = null;
    this.changeDetectorRef.detectChanges();
    if (this.withdrawalState.require_tin) {
      this.updateStorage();
    }

    this.withdrawalApiService.withdraw(
        this.withdrawalRequest,
        this.withdrawalVerificationCode,
        this.withdrawalItnValue)
      .pipe(
        finalize(() => {
          this.withdrawalLoading = false;
        }),
      )
      .subscribe(() => {
        this.withdrawalAmount = null;
        this.withdrawalRequest = null;
        this.changeDetectorRef.detectChanges();
        this.handleEventService.openSnackBar(this.translateService.instant('CABINET_WITHDRAWAL_SUCCESS'));
        this.balanceService.refreshBalance();
        this.updateWithdrawalTransactions();
      }, error => {
        this.withdrawalLoading = false;
        this.withdrawalErrorMessage = this.getWithdrawalErrorMessage(error);
        this.changeDetectorRef.detectChanges();
      });
  }

  cancelWithdrawalRequest(): void {
    this.withdrawalLoading = true;
    this.withdrawalErrorMessage = null;
    this.changeDetectorRef.detectChanges();

    this.withdrawalApiService.cancelRequest(this.withdrawalRequest)
      .pipe(
        finalize(() => {
          this.withdrawalLoading = false;
        }),
      )
      .subscribe(() => {
        this.withdrawalRequest = null;
        this.changeDetectorRef.detectChanges();
      }, error => {
        this.withdrawalLoading = false;
        if (error instanceof HttpErrorResponse && error.error?.code === 'invalid_request_status') {
          this.withdrawalRequest = null;
        } else {
          this.withdrawalErrorMessage = this.getWithdrawalErrorMessage(error);
        }
        this.changeDetectorRef.detectChanges();
      });
  }

  private getWithdrawalErrorMessage(error: any): string {
    if (error instanceof HttpErrorResponse) {
      switch (error.error?.code) {
        case 'withdrawal_is_not_enabled':
          return this.translateService.instant('WITHDRAWAL_ERROR_IS_NOT_ENABLED');
        case 'invalid_amount':
          return this.translateService.instant('WITHDRAWAL_ERROR_INVALID_AMOUNT');
        case 'withdrawal_request_throttle':
          return this.translateService.instant('WITHDRAWAL_ERROR_REQUEST_THROTTLE');
        case 'active_request_already_exists':
          return this.translateService.instant('WITHDRAWAL_ERROR_REQUEST_ALREADY_EXISTS');
        case 'not_enough_balance':
          return this.translateService.instant('WITHDRAWAL_ERROR_NOT_ENOUGH_BALANCE');
        case 'invalid_request_status':
          return this.translateService.instant('WITHDRAWAL_ERROR_INVALID_REQUEST_STATUS');
        case 'expired_verification_code':
        case 'invalid_verification_code':
          return this.translateService.instant('WITHDRAWAL_ERROR_INVALID_VERIFICATION_CODE');
        case 'too_many_attempts':
          return this.translateService.instant('WITHDRAWAL_ERROR_TOO_MANY_ATTEMPTS');
        case 'phone_number_is_not_verified':
          return this.translateService.instant('WITHDRAWAL_ERROR_PHONE_NUMBER_IS_NOT_VERIFIED');
      }
    }

    return this.translateService.instant('WITHDRAWAL_ERROR_UNKNOWN');
  }

  getWithdrawalCardIcon(withdrawalCard: WithdrawalCard): string {
    if (withdrawalCard.is_disabled) {
      return 'credit-card-lock';
    } else if (withdrawalCard.is_default) {
      return 'credit-card-check';
    } else {
      return 'credit-card';
    }
  }

  getWithdrawalCardIconColor(withdrawalCard: WithdrawalCard): ThemePalette {
    if (withdrawalCard.is_disabled) {
      return 'warn';
    } else if (withdrawalCard.is_default) {
      return 'accent';
    } else {
      return undefined;
    }
  }

  getBalanceTransactionIcon(transaction: BalanceTransaction): string {
    switch (transaction.type) {
      case 'salary_payment':
        return 'phone';
      case 'salary_penalty':
        return 'alert';
      case 'salary_bonus':
        return 'wallet-plus';
      case 'withdrawal_approval':
      case 'negative_withdrawal_approval':
        return 'swap-horizontal-bold';
      default:
        return 'help-circle';
    }
  }

  getBalanceTransactionDescription(transaction: BalanceTransaction): string {
    if (transaction.type === 'salary_payment' && transaction.call_record_type) {
      return 'CALL_RECORD_TYPE_' + transaction.call_record_type.toUpperCase();
    } else {
      return 'TRANSACTION_TYPE_' + transaction.type.toUpperCase();
    }
  }

  getWithdrawalTransactionIcon(transaction: WithdrawalTransaction): string {
    switch (transaction.type) {
      case 'withdrawal':
        switch (transaction.withdrawal_receipt?.status) {
          case WithdrawalReceiptStatus.SUCCEEDED:
            return 'credit-card-check';
          case WithdrawalReceiptStatus.FAILED:
            return 'credit-card-remove';
          case WithdrawalReceiptStatus.PROCESSING:
          case WithdrawalReceiptStatus.UNDEFINED:
            return 'credit-card-clock';
          default:
            return 'credit-card';
        }
      case 'revert_withdrawal':
        return 'credit-card-refund';
      case 'withdrawal_approval':
        return 'check-decagram';
      case 'negative_withdrawal_approval':
        return 'alert-decagram';
      default:
        return 'help-circle';
    }
  }

  getWithdrawalTransactionIconColor(transaction: WithdrawalTransaction): ThemePalette {
    switch (transaction.withdrawal_receipt?.status) {
      case WithdrawalReceiptStatus.SUCCEEDED:
        return 'accent';
      case WithdrawalReceiptStatus.FAILED:
        return 'warn';
    }

    return undefined;
  }

  getWithdrawalTransactionDescription(transaction: WithdrawalTransaction): string {
    return 'WITHDRAWAL_TRANSACTION_TYPE_' + transaction.type.toUpperCase();
  }

  addWithdrawalCard(): void {
    this.addWithdrawalCardLoading = true;

    this.dispatcherApiService.addWithdrawalCard()
      .subscribe(result => {
        this.addWithdrawalCardLoading = false;
        this.changeDetectorRef.detectChanges();

        const windowRef = window.open(result.redirect_url, '_blank');

        this.withdrawalCardWindowTimer = window.setInterval(() => {
          if (windowRef.closed) {
            window.clearInterval(this.withdrawalCardWindowTimer);
            this.updateDispatcherAccount();
          }
        }, 1000);
      }, () => {
        this.addWithdrawalCardLoading = false;
        this.changeDetectorRef.detectChanges();
      });
  }

  setDefaultWithdrawalCard(withdrawalCard: WithdrawalCard): void {
    this.withdrawalCardLoading = true;
    this.changeDetectorRef.detectChanges();

    this.dispatcherApiService.setDefaultWithdrawalCard(withdrawalCard)
      .subscribe(() => {
        this.withdrawalCardLoading = false;
        this.withdrawalCards.forEach(item => {
          item.is_default = item.uuid === withdrawalCard.uuid;
        });
        this.changeDetectorRef.detectChanges();
      }, () => {
        this.withdrawalCardLoading = false;
        this.changeDetectorRef.detectChanges();
      });
  }

  startEditWithdrawalCardTitle(withdrawalCard: WithdrawalCard): void {
    this.editableWithdrawalCard = withdrawalCard.uuid;
    this.changeDetectorRef.detectChanges();
  }

  cancelEditWithdrawalCardTitle(): void {
    this.editableWithdrawalCard = null;
    this.changeDetectorRef.detectChanges();
  }

  updateWithdrawalCardTitle(withdrawalCard: WithdrawalCard, title: string): void {
    this.editableWithdrawalCard = null;
    this.changeDetectorRef.detectChanges();

    if (title === withdrawalCard.title || title.length === 0) {
      return;
    }

    this.dispatcherApiService.updateWithdrawalCardTitle(withdrawalCard, title)
      .subscribe(() => {
        withdrawalCard.title = title;
        this.changeDetectorRef.detectChanges();
        this.handleEventService.openSnackBar(this.translateService.instant('CABINET_WITHDRAWAL_CARD_UPDATED'));
      });
  }

  deleteWithdrawalCard(withdrawalCard: WithdrawalCard): void {
    if (this.withdrawalCards.length < 2) {
      this.matDialog.open(InfoModalComponent, {
        data: {
          title: `UTAX_NOT_ALLOW_DELETE_CARD_TITLE`,
          description: `UTAX_NOT_ALLOW_DELETE_CARD_DESCRIPTION`,
          config: {
            descriptionPosition: 'center',
            buttonClass: 'button-white'
          }
        },
        backdropClass: 'request-dialog-backdrop',
        panelClass: 'request-reject-dialog-container',
        width: '442px'
      });
    } else {
      const params = {
        title: withdrawalCard.title,
        mask: withdrawalCard.masked_card,
      };

      const dialogRef = this.matDialog.open(ConfirmDeleteDialogComponent, {
        panelClass: 'confirm-delete-dialog',
        data: {
          title: this.translateService.instant('CABINET_WITHDRAWAL_CARD_DELETE_DIALOG_TITLE', params),
          content: this.translateService.instant('CABINET_WITHDRAWAL_CARD_DELETE_DIALOG_CONTENT', params),
        }
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.deleteWithdrawalCardInternal(withdrawalCard);
        }
      });
    }
  }

  private deleteWithdrawalCardInternal(withdrawalCard: WithdrawalCard): void {
    this.withdrawalCardLoading = true;
    this.changeDetectorRef.detectChanges();

    this.dispatcherApiService.deleteWithdrawalCard(withdrawalCard)
      .subscribe(() => {
        this.withdrawalCardLoading = false;
        this.withdrawalCards = this.withdrawalCards.filter(item => item.uuid !== withdrawalCard.uuid);
        this.changeDetectorRef.detectChanges();
        this.removeCardFromStorage(withdrawalCard);
        this.handleEventService.openSnackBar(this.translateService.instant('CABINET_WITHDRAWAL_CARD_DELETED'));
      }, () => {
        this.withdrawalCardLoading = false;
        this.changeDetectorRef.detectChanges();
      });
  }

  loadMoreTransactions(): void {
    if (this.transactionsLoading || !this.hasMoreTransactions) {
      return;
    }

    this.transactionsLoading = true;

    this.balanceApiService.getTransactions(this.transactionsPage + 1, this.transactionsPageSize)
      .subscribe(transactions => {
        this.transactionsLoading = false;
        this.transactionsPage = transactions.meta.current_page;
        this.hasMoreTransactions = transactions.meta.last_page > this.transactionsPage;
        if (this.transactionsPage > 1) {
          appendItemsWithoutDuplicates(this.transactions, transactions.data, transaction => transaction.id,
            (a, b) => b.created_at.localeCompare(a.created_at));
        } else {
          this.transactions = transactions.data;
        }
        this.changeDetectorRef.detectChanges();
      }, () => {
        this.transactionsLoading = false;
      });
  }

  loadMoreWithdrawalTransactions(): void {
    if (this.withdrawalTransactionsLoading || !this.hasMoreWithdrawalTransactions) {
      return;
    }

    this.withdrawalTransactionsLoading = true;

    this.withdrawalApiService.getTransactions(this.withdrawalTransactionsPage + 1, this.transactionsPageSize)
      .subscribe(transactions => {
        this.withdrawalTransactionsLoading = false;
        this.withdrawalTransactionsPage = transactions.meta.current_page;
        this.hasMoreWithdrawalTransactions = transactions.meta.last_page > this.withdrawalTransactionsPage;
        if (this.withdrawalTransactionsPage > 1) {
          appendItemsWithoutDuplicates(this.withdrawalTransactions, transactions.data, transaction => transaction.id,
            (a, b) => b.created_at.localeCompare(a.created_at));
        } else {
          this.withdrawalTransactions = transactions.data;
        }
        this.changeDetectorRef.detectChanges();
      }, () => {
        this.withdrawalTransactionsLoading = false;
      });
  }

  public changeCard(value: any) {
    this.withdrawalItnValue = this.getItnValue(value);
  }

  private getItnValue(uuidCard: string): string {
    if (!this.savedCardItn) {
      return '';
    }
    const findCard = this.savedCardItn.find(card => card.uuid === uuidCard);
    return findCard ? findCard.itn : '';
  }

  private updateStorage(): void {
    const findCard = this.savedCardItn?.find(card => card.uuid === this.selectedWithdrawalCard);
    if (findCard) {
      findCard.itn = this.withdrawalItnValue;
    } else {
      this.savedCardItn = this.savedCardItn || [];
      this.savedCardItn.push({uuid: this.selectedWithdrawalCard, itn: this.withdrawalItnValue});
      this.savedCardItn = [...this.savedCardItn];
    }
  }
  private removeCardFromStorage(withdrawalCard: WithdrawalCard): void {
    if (this.savedCardItn) {
      this.savedCardItn = this.savedCardItn.filter(card => card.uuid !== withdrawalCard.uuid);
    }
  }

}
