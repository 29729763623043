import {debounceTime, filter, map, mergeMap, startWith, switchMap, takeUntil, tap} from 'rxjs/operators';
import {UntypedFormArray, UntypedFormGroup} from '@angular/forms';
import {
  AfterViewInit, ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren
} from '@angular/core';
import {Order, Service} from 'src/app/interfaces/order.interface';
import {ApiOrderFormService, OrderFormService, OrderModalService} from '../../services';
import {BehaviorSubject, Observable, of, Subject} from 'rxjs';
import {Address, AddressSearchInterface} from 'src/app/interfaces/address.interface';
import {MatAutocompleteSelectedEvent, MatAutocompleteTrigger} from '@angular/material/autocomplete';
import {AllowIn, ShortcutInput} from 'ng-keyboard-shortcuts';
import {MatInput} from '@angular/material/input';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {KsModalComponent, YesNoModalComponent} from 'src/app/shared/components';
import {CityCanSearchAddress} from 'src/app/interfaces/state.interface';
import {GlobalDataService} from '@global-services/global-data.service';
import {OrdersService} from '@global-services/orders.service';
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {OrderClass} from '../../../classes/order.class';
import {AddressService} from '../../../services';

@UntilDestroy()
@Component({
  selector: 'utax-order-from-addresses',
  templateUrl: './order-from-addresses.component.html',
  styleUrls: ['./order-from-addresses.component.scss']
})
export class OrderFromAddressesComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {

  @ViewChildren('addressSearch', {read: MatAutocompleteTrigger}) autoTriggers: QueryList<MatAutocompleteTrigger>;
  @ViewChildren('secondaryArrressSearch', {read: MatAutocompleteTrigger}) autoTriggersSecond: QueryList<MatAutocompleteTrigger>;

  @ViewChildren('addressSearch', { read: MatInput })
  private addressSearch: QueryList<MatInput>;
  @ViewChild('addressesContainer')
  private addressesContainer: ElementRef;

  private forceLockStart = false;
  public selectLangSearch: string;
  private searchAddressMethod = 'new';
  public filteredOptionsDispComment$: Observable<string[]>;
  public filteredOptionsPassComment$: Observable<string[]>;



  constructor(
    public addressService: AddressService,
    public orderFormService: OrderFormService,
    public orderModalService: OrderModalService,
    public apiOrdFormService: ApiOrderFormService,
    private dialog: MatDialog,
    private cdr: ChangeDetectorRef,
    public globalDataService: GlobalDataService,
    private ordersService: OrdersService,

  ) {
  }
  @Input('form')
  set showStatus(form) {
    this.addressForm = form;
  }
  @Input() order: OrderClass;
  @Input() services: Service[];
  @Input() isIncity: boolean;
  @Input('citiesWithAddresses')
  set citiesWithAddresses(cities) {
    cities.forEach(element => {
      this.intercityCities.push({
        ...element,
        name: `мм ${element.name}`,
        isCityHasAddresses: true
      });
    });
  }

  addressSearch$: Subject<AddressSearchInterface> = new Subject<AddressSearchInterface>();
  streetId$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  addressForm: UntypedFormGroup;
  addressType = 'all';
  addresses: Address[] = [];
  addressesPopular: Address[] = [];
  lastQuery = '';
  passangerId: string;
  optionOpened = false;
  shortcuts$: Observable<ShortcutInput[]>;

  numberOfOpenedAutocomplenePanel: number;
  selectedAddressIndex;

  private intercityCities: CityCanSearchAddress[] = [];
  private ignoreNextCallOfAlt = false;
  private componentDestroyed$ = new Subject();
  ngOnInit() {
    // this.addressForm.valueChanges.subscribe(data => {
    //   console.log('FormValue', data, this.addressForm);
    // });

    // updating addresses on addressSearch$ change
    this.filteredOptionsDispComment$ = this.addressForm.get('disp_comment').valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value || '')),
    );
    this.filteredOptionsPassComment$ = this.addressForm.get('pass_comment').valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value || '')),
    );
    this.selectLangSearch = this.initDefaultSearchLang();
    this.addressSearch$
      .pipe(
        debounceTime(300),
        filter(data => data.query.length === 0 || data.query.length > 1),
        takeUntil(this.componentDestroyed$)
      )
      .subscribe((data: AddressSearchInterface) => {
        this.getAddresses(data);
      });
    // getting addresses after address input change
    this.addressService.addresses$.pipe(takeUntil(this.componentDestroyed$)).subscribe((addresses: Address[]) => {
      if (this.numberOfOpenedAutocomplenePanel === 1 && this.addressesFormArray.length === 2) {
        addresses = addresses.filter(address => !this.addressesPopular.some( a => a.address_id === address.address_id));
        this.addresses = this.addressesPopular.concat(addresses);
      } else {
        this.addresses = addresses;
      }
    });
    //
    this.addressService.popularAddresses$.pipe(takeUntil(this.componentDestroyed$)).subscribe((addresses: Address[]) => {
      this.addressesPopular = addresses;
    });

    // focus on first address after passenger load
    (this.orderFormService.generalForm.controls.basic as UntypedFormGroup)
      .get('passengerId')
      .valueChanges.pipe(takeUntil(this.componentDestroyed$))
      .subscribe(passId => {
        if (this.passangerId === undefined || this.passangerId !== passId) {
          this.passangerId = passId;
          this.getAddresses({query: this.addressSearch.first.value});
        }
        if (!this.order) {
          this.changeSearchLang(this.selectLangSearch);
          setTimeout(() => {
            this.addressSearch.first.focus();
          }, 100);
        }
      });

    this.orderFormService.focusOnFirstAddress$.pipe(takeUntil(this.componentDestroyed$)).subscribe(() => {
      this.addressSearch.first.focus();
    });
    this.shortcutHelper();
  }

  ngOnChanges(): void {
    if (this.isIncity) {
      // set same value to second address
      (this.addressForm.controls.addresses as UntypedFormArray).controls[1].setValue(
        (this.addressForm.controls.addresses as UntypedFormArray).controls[0].value,
        { emitEvent: false }
      );
      // remove all extra addresses
      while ((this.addressForm.controls.addresses as UntypedFormArray).controls.length > 2) {
        (this.addressForm.controls.addresses as UntypedFormArray).removeAt(
          (this.addressForm.controls.addresses as UntypedFormArray).controls.length - 1
        );
      }
    }
  }

  get addressesFormArray(): UntypedFormArray {
    return this.addressForm.controls.addresses as UntypedFormArray;
  }

  onFocus(i, event) {
    if (
      i === 0 &&
      this.orderModalService.mode &&
      this.orderModalService.mode === 'normal' &&
      this.order &&
      this.order.cab &&
      this.ordersService.delDriverFromOrderStatus$.value !== 'completed' &&
      !this.forceLockStart
    ) {
      this.forceLock(event.target);
    } else if (
      i === 0 &&
      this.orderModalService.mode &&
      this.orderModalService.mode === 'normal' &&
      !this.forceLockStart
    ) {
      this.forceLockStart = true;
      this.orderFormService.disableSaveButtons$.next(true);
      this.orderModalService.lock({ id: this.order.id, mode: 'force' }).subscribe(() => {
        this.forceLockStart = false;
        this.orderFormService.disableSaveButtons$.next(false);
      });
    }
  }

  changeCity(changeType: 'default' | 'secondary', addressIndex?: number): void {
    if (!this.selectLangSearch) {
      this.selectLangSearch = this.addressService.cityObj?.settings
        .find(setting => setting.key === 'system_locale')?.value === 'ua' ? 'uk' : 'ru';
    }
    const addressFieldValue = this.addressesFormArray.getRawValue()[addressIndex];
    switch (changeType) {
      case 'default':
        // set city from service in form
        this.addressService.setCity(this.services, this.orderFormService.generalForm.getRawValue().basic.taxiService);
        if (addressFieldValue && addressFieldValue.name === '') {
          this.setAddressSearch('', false);
        }
        break;

      case 'secondary':
        // get serivceid of city
        const secondaryAddressCity = this.intercityCities.find(city => {
          return city.key === addressFieldValue.cityWithAddresses.cityKey;
        });
        this.addressService.city = secondaryAddressCity;
        console.log('secondaryAddressCity', this.addressService.city);
        if (addressFieldValue && addressFieldValue.cityWithAddresses.name === '') {
          this.setAddressSearch('', false);
        }
        break;
      default:
        break;
    }
  }

  openKsModal() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.panelClass = 'yes-no-modal-container';
    this.dialog.open(KsModalComponent, dialogConfig);
  }

  forceLock(el) {
    let dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      title: 'UTAX_FRONT_ADDRESS_EDIT_WARNING'
    };
    dialogConfig.width = '400px';
    dialogConfig.panelClass = 'yes-no-modal-container';
    dialogConfig.disableClose = true;
    this.forceLockStart = true;
    this.orderFormService.disableSaveButtons$.next(true);
    const dialogRef = this.dialog.open(YesNoModalComponent, dialogConfig);
    dialogRef
      .afterClosed()
      .pipe(
        mergeMap(data => {
          if (data === 'YES') {
            return this.orderModalService.lock({ id: this.order.id, mode: 'force' });
          } else {
            el.blur();
            return of();
          }
        })
      )
      .subscribe(res => {
        this.ordersService.delDriverFromOrderStatus$.next('completed');
        this.forceLockStart = false;
        this.orderFormService.disableSaveButtons$.next(false);
        dialogConfig = {};
      });
  }

  plusRestrict(event): boolean {
    return event.charCode !== 43;
  }

  filterHotkeys(event) {
    if (
      event.keyCode === 32 ||
      event.keyCode === 37 ||
      event.keyCode === 38 ||
      event.keyCode === 39 ||
      event.keyCode === 40
    ) {
      event.stopPropagation();
    }
  }

  ngAfterViewInit() {}

  getAddresses = (search: AddressSearchInterface, isPopularAddress = false) => {
    this.lastQuery = search.query;
    const intercityStr = 'МГ';
    const intercityStrUa = 'ММ';
    let isIntercity = false;
    let additionalAddressType = null;
    if (search) {
      const queryArr = search.query.split(' ').filter(el => el);
      if ((queryArr[0] || '').toUpperCase() === intercityStr || (queryArr[0] || '').toUpperCase() === intercityStrUa) {
        isIntercity = true;
        queryArr[0] = '';
        this.cdr.detectChanges();
      }
      search.query = queryArr.join(' ');
      if (this.searchAddressMethod === 'new') {
        if (this.streetId$.value ) {
          const queryArrStreet = search.query.split(', ').filter(el => el);
          if (queryArrStreet.length > 1) {
            search.query = queryArrStreet[queryArrStreet.length - 1];
            search.streetId = this.streetId$.value;
          } else if (search.query.includes(',')) {
            search.query = '';
            search.streetId = this.streetId$.value;
          }
        }
        if (this.addressType === 'street' ) {
          additionalAddressType = 'street_name';
        }
      }
    }
    this.addressService.setAddresses(
      search.query,
      this.selectLangSearch,
      isIntercity,
      this.passangerId,
      this.addressType,
      this.intercityCities,
      search?.streetId,
      additionalAddressType
    );
  };

  ngOnDestroy() {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.unsubscribe();
  }

  setAddressSearch(addressInputValue: string, mainAddressField = false, streetId?: string) {
    this.addressService.searchingProcess$.next(true);
    if (addressInputValue.length === 0 && mainAddressField) {
      this.changeCity('default');
    }
    this.addressSearch$.next({query: addressInputValue, streetId});
  }

  addressAutocompleteSelected(event: MatAutocompleteSelectedEvent, i: number, isIntercityAddress?: boolean) {
    const selectedOptionValue: any = event.option.value; // @TODO add interface
    if (!selectedOptionValue.street_number && selectedOptionValue.address_type === 'street_name') {
      this.selectStreetWithoutNumber(selectedOptionValue, i, isIntercityAddress);
      this.openPanel(i);
      return;
    }
    this.orderFormService.disableSaveButtons$.next(true);
    let patchObj: any = {};
    if (isIntercityAddress) {
      // interciry city with addresses
      patchObj = {
        lat: selectedOptionValue.lat,
        lng: selectedOptionValue.lng,
        relation_id: selectedOptionValue.relation_id,
        cityWithAddresses: {
          name: selectedOptionValue.name,
          lat: selectedOptionValue.lat,
          lng: selectedOptionValue.lng,
          address_id: selectedOptionValue.id,
          id: selectedOptionValue.id,
          intercity: selectedOptionValue.intercity,
          ord: i,
          relation_id: selectedOptionValue.relation_id
        }
      };
    } else {
      patchObj = {
        name: selectedOptionValue.name,
        lat: selectedOptionValue.lat,
        lng: selectedOptionValue.lng,
        address_id: selectedOptionValue.id,
        id: selectedOptionValue.isCityHasAddresses ? null : selectedOptionValue.id,
        intercity: selectedOptionValue.intercity,
        ord: i,
        relation_id: selectedOptionValue.relation_id
      };
    }

    if (selectedOptionValue.isCityHasAddresses) {
      this.addressService.city = selectedOptionValue;
      patchObj.cityWithAddresses = {
        cityKey: selectedOptionValue.key
      };
    }

    if (!selectedOptionValue.isCityHasAddresses && !isIntercityAddress) {
      patchObj.cityWithAddresses = {
        cityKey: null,
        name: null,
        relation_id: null
      };
      ((this.addressesFormArray.controls[i] as UntypedFormGroup).controls
        .cityWithAddresses as UntypedFormGroup).controls.name.clearValidators();
      ((this.addressesFormArray.controls[i] as UntypedFormGroup).controls
        .cityWithAddresses as UntypedFormGroup).controls.name.updateValueAndValidity();
    }

    if (i === 0 && event) {
      (this.orderFormService.generalForm.get('type') as UntypedFormGroup).controls.incity.enable();
      if (!this.order?.id) {
        const passengerId = (this.orderFormService.generalForm.controls.basic as UntypedFormGroup).get('passengerId').value;
        const service_code = (this.orderFormService.generalForm.controls.basic as UntypedFormGroup).get('taxiService').value;
        const location = `${patchObj.lat},${patchObj.lng}`;
        this.getPassengerPopularityAddress(passengerId, service_code, location);
      }
    }
    // setting new value of address
    (this.addressForm.controls.addresses as UntypedFormArray).at(i).patchValue(patchObj);

    if (this.isIncity) {
      //  && (this.addressForm.controls.addresses as FormArray).controls[1].status === 'INVALID'
      (this.addressForm.controls.addresses as UntypedFormArray).controls[1].setValue(
        (this.addressForm.controls.addresses as UntypedFormArray).controls[0].value
      );
    }

    this.streetId$.next('');

    this.addressForm.markAsTouched();
    this.orderFormService.addressFormSelect$.next(true);
  }

  getControls(): any {
    this.orderFormService.generalForm.get('addresses');
  }

  addOneMoreAddress() {
    (this.addressForm.controls.addresses as UntypedFormArray).push(this.orderFormService.buildDestAddress(false));
    setTimeout(() => {
      this.addressSearch.last.focus();
    }, 10);

    if (this.addressForm.controls.addresses.value.length >= 5) {
      this.orderFormService.overflowAddressesContainer$.next(this.addressForm.controls.addresses.value.length);
    }
  }

  moveAddressDown(i): void {
    this.orderFormService.disableSaveButtons$.next(true);
    const before = (this.addressForm.controls.addresses as UntypedFormArray).at(i + 1);
    const after = (this.addressForm.controls.addresses as UntypedFormArray).at(i);
    (this.addressForm.controls.addresses as UntypedFormArray).setControl(i, before);
    (this.addressForm.controls.addresses as UntypedFormArray).setControl(i + 1, after);
    this.updateAddressOrd();
    this.orderFormService.generalForm.markAsTouched();
    this.orderFormService.addressFormSelect$.next(true);
  }

  moveAddressUp(i): void {
    this.orderFormService.disableSaveButtons$.next(true);
    const before = (this.addressForm.controls.addresses as UntypedFormArray).at(i);
    const after = (this.addressForm.controls.addresses as UntypedFormArray).at(i - 1);
    (this.addressForm.controls.addresses as UntypedFormArray).setControl(i - 1, before);
    (this.addressForm.controls.addresses as UntypedFormArray).setControl(i, after);
    this.updateAddressOrd();
    this.orderFormService.generalForm.markAsTouched();
    this.orderFormService.addressFormSelect$.next(true);
  }

  removeAddress(i): void {
    (this.addressForm.controls.addresses as UntypedFormArray).removeAt(i);

    if (this.addressForm.controls.addresses.value.length >= 4) {
      this.orderFormService.overflowAddressesContainer$.next(this.addressForm.controls.addresses.value.length);
    }
    this.updateAddressOrd();
    this.orderFormService.generalForm.markAsTouched();
    this.orderFormService.addressFormSelect$.next(true);
  }

  updateAddressOrd(): void {
    const count = (this.addressForm.controls.addresses as UntypedFormArray).length;
    for (let i = 0; i < count; i++) {
      (this.addressForm.controls.addresses as UntypedFormArray).at(i).patchValue({ord: i});
    }
  }

  autocompletePanelOpened(i): void {
    setTimeout(() => (this.numberOfOpenedAutocomplenePanel = i), 100);
  }

  autocompletePanelClosed(i): void {
    this.numberOfOpenedAutocomplenePanel = undefined;
    this.selectedAddressIndex = null;
  }

  focusOnAddressSearchByIndex(event, i): void {
    event.preventDefault();
    this.addressSearch.forEach((item, index: number) => {
      if (index === i) {
        item.focus();
      }
    });
  }

  deletePassAddress(event, address): void {
    event.stopPropagation();
    // open yesNoModal
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      title: 'UTAX_FRONT_PASS_ADDRESS_DELETE?'
    };
    dialogConfig.width = '400px';
    dialogConfig.panelClass = 'yes-no-modal-container';
    const dialogRef = this.dialog.open(YesNoModalComponent, dialogConfig);
    dialogRef
      .afterClosed()
      .pipe(
        mergeMap(data => {
          if (data === 'YES') {
            return this.addressService.removePassAddress(this.passangerId, address.idForRemove);
          } else {
            return of(false);
          }
        })
      )
      .subscribe((deleted: boolean) => {
        if (deleted) {
          this.addresses = this.addresses.filter(item => item.idForRemove !== address.idForRemove);
        }
      });
  }

  private changeAddressSelection(direction): void {
    if (this.numberOfOpenedAutocomplenePanel !== null && this.numberOfOpenedAutocomplenePanel !== undefined) {
      this.addressSearch.forEach((item, index: number) => {
        if (index === this.numberOfOpenedAutocomplenePanel) {
          item.focus();
        }
      });
    }
  }

  @HostListener('window:keydown', ['$event'])
  hotkeyDown($event) {
    if ($event.keyCode === 18) {
      $event.preventDefault();
    }
    if ($event.keyCode === 107) {
      $event.preventDefault();
    }
  }

  @HostListener('window:keyup', ['$event'])
  hotkeyHandler($event) {
    if ($event.keyCode === 16 && $event.altKey) {
      // ignode next call of alt
      this.ignoreNextCallOfAlt = true;
    }
    if (!$event.shiftKey && $event.keyCode === 18) {
      // alt
      if (!this.ignoreNextCallOfAlt) {
        const types = ['all', 'street', 'place'];
        this.addressType = types[(types.findIndex(el => el === this.addressType) + 1) % 3];
        this.setAddressSearch(this.lastQuery);
      } else {
        this.ignoreNextCallOfAlt = false;
      }
    }
    if ($event.keyCode === 107) {
      // plus
      if (!this.addressForm.controls.disp_comment.disabled) {
        this.addOneMoreAddress();
      }
    }
  }

  public changeSearchLang(lang: string): void {
    this.selectLangSearch = lang;
    this.passangerId = (this.orderFormService.generalForm.controls.basic as UntypedFormGroup).get('passengerId').value;
    this.numberOfOpenedAutocomplenePanel ?
      this.getAddresses({query: this.addressSearch.find((item, index) => index === this.numberOfOpenedAutocomplenePanel)?.value})
      : this.getAddresses({query: this.addressSearch.first.value});
  }

  private initDefaultSearchLang(): string {
    if (this.addressService.cityObj) {
      const serviceSystemLocale = this.addressService.cityObj?.settings.find(setting => setting.key === 'system_locale');
      return serviceSystemLocale && serviceSystemLocale?.value === 'ua' ? 'uk' : 'ru';
    }
    return 'uk';
  }

  public shortcutHelper(): void {
    this.shortcuts$ = this.globalDataService.isNewKeyboardLayout$
      .pipe(
        switchMap(isNewKeyboardLayout => {
          if (isNewKeyboardLayout) {
            return of([
              {
                key: ['down'],
                command: e => {
                  this.changeAddressSelection('down');
                },
                allowIn: [AllowIn.Input, AllowIn.Select],
                preventDefault: true
              },
              {
                key: ['up'],
                command: e => {
                  this.changeAddressSelection('up');
                },
                allowIn: [AllowIn.Input, AllowIn.Select],
                preventDefault: true
              },
              {
                key: ['home'],
                command: e => {
                  if (this.numberOfOpenedAutocomplenePanel !== undefined) {
                    this.addressSearch.forEach((item: any, index: number) => {
                      if (index === this.numberOfOpenedAutocomplenePanel) {
                        item.focus();
                        item._elementRef.nativeElement.setSelectionRange(0, 0);
                      }
                    });
                  }
                },
                allowIn: [AllowIn.Input, AllowIn.Select],
                preventDefault: false
              },
              {
                key: ['end'],
                command: e => {
                  if (this.numberOfOpenedAutocomplenePanel !== undefined) {
                    this.addressSearch.forEach((item: any, index: number) => {
                      if (index === this.numberOfOpenedAutocomplenePanel) {
                        item.focus();
                        item._elementRef.nativeElement.setSelectionRange(item.value.length, item.value.length);
                      }
                    });
                  }
                },
                allowIn: [AllowIn.Input, AllowIn.Select],
                preventDefault: false
              },
              {
                key: ['pageup'],
                command: e => {
                  if (this.numberOfOpenedAutocomplenePanel !== undefined) {
                    const lang = this.selectLangSearch === 'uk' ? 'ru' : 'uk';
                    this.changeSearchLang(lang);
                  }
                },
                allowIn: [AllowIn.Input, AllowIn.Select],
                preventDefault: true
              }
            ]);
          } else {
            return of([
              {
                key: ['down'],
                command: e => {
                  this.changeAddressSelection('down');
                },
                allowIn: [AllowIn.Input, AllowIn.Select],
                preventDefault: true
              },
              {
                key: ['up'],
                command: e => {
                  this.changeAddressSelection('up');
                },
                allowIn: [AllowIn.Input, AllowIn.Select],
                preventDefault: true
              },
              {
                key: ['home'],
                command: e => {
                  if (this.numberOfOpenedAutocomplenePanel !== undefined) {
                    this.addressSearch.forEach((item: any, index: number) => {
                      if (index === this.numberOfOpenedAutocomplenePanel) {
                        item.focus();
                        item._elementRef.nativeElement.setSelectionRange(0, 0);
                      }
                    });
                  }
                },
                allowIn: [AllowIn.Input, AllowIn.Select],
                preventDefault: false
              },
              {
                key: ['end'],
                command: e => {
                  if (this.numberOfOpenedAutocomplenePanel !== undefined) {
                    this.addressSearch.forEach((item: any, index: number) => {
                      if (index === this.numberOfOpenedAutocomplenePanel) {
                        item.focus();
                        item._elementRef.nativeElement.setSelectionRange(item.value.length, item.value.length);
                      }
                    });
                  }
                },
                allowIn: [AllowIn.Input, AllowIn.Select],
                preventDefault: false
              },
              {
                key: ['pageup'],
                command: e => {
                  if (this.numberOfOpenedAutocomplenePanel !== undefined) {
                    const lang = this.selectLangSearch === 'uk' ? 'ru' : 'uk';
                    this.changeSearchLang(lang);
                  }
                },
                allowIn: [AllowIn.Input, AllowIn.Select],
                preventDefault: true
              }
            ]);
          }
        })
      );
  }

  selectStreetWithoutNumber(address: Address, i: number, isIntercity= false) {
    if (!address.street_number && address.address_type === 'street_name') {
      this.setAddressSearch('', false, address.street_id);
      this.streetId$.next(address.street_id);
      if (isIntercity) {
        (this.addressForm.controls.addresses as UntypedFormArray)
          .at(i)
          .get('cityWithAddresses')
          .get('name')
          .patchValue(address.name + ', ');

      } else {
        (this.addressForm.controls.addresses as UntypedFormArray)
          .at(i)
          .get('name')
          .patchValue(address.name + ', ');
      }
    }
  }

  public openPanel(i: number): void {
    const self = this;
    requestAnimationFrame(() => {
      if (this.addressesFormArray.controls[i].value?.cityWithAddresses?.cityKey) {
        self.autoTriggersSecond.get(i)?.openPanel();
      } else {
        self.autoTriggers.get(i)?.openPanel();
      }
    });
  }

  checkIntercity(addressInputValue: string, i: number) {
    if (!addressInputValue.includes('мм')) {
      this.addressesFormArray.controls[i].get('cityWithAddresses').get('cityKey').patchValue(null);
      this.addressesFormArray.controls[i].get('cityWithAddresses').get('name').patchValue(null);
    }
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    if (!value || value.length < 1) {
      return [];
    }
    return this.globalDataService.operatorCommentTemplates$.value.filter(option => option.toLowerCase().startsWith(filterValue));
  }

  private getPassengerPopularityAddress(passengerId: string, service_code: string, location: string): void {
    this.apiOrdFormService.getPassengerPopularityAddress(passengerId, service_code, location)
      .pipe(
        tap(res => {
          if (res.suggestions.length) {
            const popularAddresses = res.suggestions.map(item => {
              return {
                name: item.way_point_name,
                lat: item.end_latitude,
                lng: item.end_longitude,
                id: null,
                idForRemove: null,
                relation_id: null,
                address_id: item.address_id,
                is_user_address: false,
                intercity: false,
                street_number: '',
                address_type: 'street',
                street_id: null,
                is_popular_address: true
              };
            });
            this.addressService.popularAddresses$.next(popularAddresses);
          } else {
            this.addressService.popularAddresses$.next([]);
          }
        }),
        untilDestroyed(this)
      )
      .subscribe();
  }

}
