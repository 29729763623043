import {HttpClient, HttpResponse} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, forkJoin, Observable } from 'rxjs';
import {
  IBrigade,
  ICallLine,
  ICategory,
  IRole,
  ITaxiService,
  IQueueStrategy,
  ITransferItem,
  IAppStateData, IApplicationState
} from '../models/global-data.interfaces';
import {pluck, tap} from 'rxjs/operators';
import {OrderCallDispatcherStatusInterface, Service} from "../interfaces/order.interface";
import {stringToStringArrayTransform} from "@global-utils/string-to-array-transform";
import {COMMENT_SHORTCUT} from "@global-utils/autocomplete-shortcut";

const getBrigadesUrl = 'call-centre/api/operator-brigades',
  getCategoriesUrl = 'call-centre/api/operator-categories',
  getRolesUrl = 'call-centre/api/roles',
  getCallLinesUrl = 'call-centre/api/call-lines',
  getTaxiServicesUrl = 'call-centre/api/taxi-services',
  getQueueStrategiesUrl = 'call-centre/api/queue-strategies',
  getTransferListUrl = 'call-centre/api/queues/common',
  pingCallCenter = 'call-centre/api/me/ping',
  operatorSettingState = 'operator/app/state',
  mirandaTaxiServices = 'operator/services',
  callConversationUrl = 'call-centre/api/call-conversation';

@Injectable({
  providedIn: 'root'
})
export class GlobalDataService {
  globalBrigades$: BehaviorSubject<IBrigade[]> = new BehaviorSubject([]);
  globalCategories$: BehaviorSubject<ICategory[]> = new BehaviorSubject([]);
  globalRoles$: BehaviorSubject<IRole[]> = new BehaviorSubject([]);
  globalCallLines$: BehaviorSubject<ICallLine[]> = new BehaviorSubject([]);
  globalTaxiServices$: BehaviorSubject<ITaxiService[]> = new BehaviorSubject([]);
  globalMirandaTaxiServices$: BehaviorSubject<Service[]> = new BehaviorSubject([]);
  globalQueueStrategies$: BehaviorSubject<IQueueStrategy[]> = new BehaviorSubject([]);
  globalTransferList$: BehaviorSubject<ITransferItem[]> = new BehaviorSubject([]);
  callConversationList$: BehaviorSubject<OrderCallDispatcherStatusInterface[]> = new BehaviorSubject([]);
  globalState$: BehaviorSubject<IApplicationState> = new BehaviorSubject(null);
  isNewKeyboardLayout$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  isShowBalanceInDisp$: BehaviorSubject<boolean> = new BehaviorSubject(true);
  operatorCommentTemplates$: BehaviorSubject<string[]> = new BehaviorSubject(
    stringToStringArrayTransform(COMMENT_SHORTCUT, ';')
  );

  public INCOME_CALL_AUDIO: HTMLAudioElement = new Audio('../../../assets/sounds/notifications/income-call.wav');


  constructor(
    private http: HttpClient
  ) {
    this.INCOME_CALL_AUDIO.preload = 'auto';
    this.INCOME_CALL_AUDIO.load();
  }

  async load(): Promise<any> {
    return forkJoin([
      this.getBrigades(),
      this.getCategories(),
      this.getRoles(),
      this.getCallLines(),
      this.getTaxiServices(),
      this.getQueueStrategies(),
      this.getTransferList(),
      this.getActiveConversation()
    ]).toPromise().then((data: any) => {
      this.globalBrigades$.next(data.brigades);
      this.globalCategories$.next(data.categories);
      this.globalRoles$.next(data.roles);
      this.globalCallLines$.next(data.callLines);
      this.globalTaxiServices$.next(data.taxiServices);
      this.globalQueueStrategies$.next(data.queueStrategies);
      this.globalTransferList$.next(data.transferList);
      this.callConversationList$.next(data.callConversationList);
    });
  }

  getRole(roleIds: string[]): any {
    const roles = this.globalRoles$.value;
    return roles.filter(role => roleIds.find(r => r === role.id))?.map(r => r.name) || [];
  }

  getBrigade(brigadeId: string): any {
    const brigades = this.globalBrigades$.value;
    return brigades.find(brigade => brigade.id === brigadeId) || '';
  }

  getService(serviceId: string): any {
    const services = this.globalTaxiServices$.value;
    return services.find(s => s.id === serviceId) || '';
  }

  getQueueStrategy(id: string): any {
    const data = this.globalQueueStrategies$.value;
    return data.find(item => item.id === id) || '';
  }

  getCallLine(callLineId: string): any {
    const callLines = this.globalCallLines$.value;
    return callLines.find(callLine => callLine.id === callLineId) || '';
  }

  getCategory(categoryId: string): any {
    const categories = this.globalCategories$.value;
    return categories.find(category => category.id === categoryId) || '';
  }

  getBrigades(): Observable<IBrigade[]> {
    return this.http.get<IBrigade[]>(getBrigadesUrl);
  }

  getCategories(): Observable<ICategory[]> {
    return this.http.get<ICategory[]>(getCategoriesUrl);
  }

  getActiveConversation(): Observable<OrderCallDispatcherStatusInterface[]> {
    return this.http.get<OrderCallDispatcherStatusInterface[]>(callConversationUrl);
  }

  getRoles(): Observable<IRole[]> {
    return this.http.get<IRole[]>(getRolesUrl);
  }

  getCallLines(): Observable<ICallLine[]> {
    return this.http.get<ICallLine[]>(getCallLinesUrl);
  }

  getTaxiServices(): Observable<ITaxiService[]> {
    return this.http.get<ITaxiService[]>(getTaxiServicesUrl);
  }

  getQueueStrategies(): Observable<IQueueStrategy[]> {
    return this.http.get<IQueueStrategy[]>(getQueueStrategiesUrl);
  }

  getTransferList(): Observable<any[]> {
    return this.http.get<any[]>(getTransferListUrl);
  }

  pingCallCenter(): Observable<HttpResponse<void>> {
    return this.http.get<void>(pingCallCenter, {observe: 'response'});
  }

  getGlobalState(): Observable<IAppStateData> {
    return this.http.get<{data: IAppStateData}>(operatorSettingState).pipe(pluck('data'));
  }

  getMirandaTaxiServices(): Observable<Service[]> {
    return this.http.get<{data: Service[]}>(mirandaTaxiServices).pipe(pluck('data'));
  }

  changeGlobalState(layout: IApplicationState): Observable<void> {
    const state = {...this.globalState$.value, ...layout};
    return this.http.put<void>(operatorSettingState, {state: JSON.stringify(state)}).pipe(
      tap(() => this.globalState$.next(state))
    );
  }
}
