import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { pluck } from 'rxjs/operators';
import {User, Role, UserHistoryDataInterface, UserHistoryRequestInterface} from '../../models/user.interface';
import { Service } from 'src/app/interfaces/order.interface';

@Injectable({
  providedIn: 'root'
})
export class UsersService {
  public scrollToTop$: Subject<null> = new Subject<null>();
  public loadingHistoryUser$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(private http: HttpClient) {}

  getListOfUsers(): Observable<Array<User>> {
    return this.http.get<any>('operator/users').pipe(pluck('data'));
  }

  deleteUser(id: string): Observable<any> {
    return this.http.delete('operator/users', { params: { id } });
  }

  getListOfRoles(): Observable<Array<Role>> {
    return this.http.get<any>('operator/roles').pipe(pluck('data'));
  }

  getListOfServices(): Observable<Array<Service>> {
    return this.http.get<any>('operator/accessible/services').pipe(pluck('data'));
  }

  postUser(user: { name: string; password: string; type: string }): Observable<any> {
    return this.http.post('operator/users', user).pipe(pluck('data'));
  }

  putUser(user: { id: string; password: string; type: string; name: string }): Observable<any> {
    return this.http.put('operator/users', user).pipe(pluck('data'));
  }

  putRoles(id: string, roles: string[]): Observable<any> {
    return this.http.put('operator/users/roles', { id, roles }).pipe(pluck('data'));
  }
  postRoles(id: string, roles: string[]): Observable<any> {
    return this.http.post('operator/users/roles', { id, roles }).pipe(pluck('data'));
  }

  deleteRoles(id: string, roles: string[]): Observable<any> {
    return this.http.delete('operator/users/roles', {
      params: new HttpParams({ fromObject: { id, 'roles[]': roles } })
    });
  }

  putService(user_id: string, service_ids: number[]): Observable<any> {
    return this.http.put('operator/accesses/user/services/multi', {}, { params: { user_id, 'service_ids[]': service_ids } }).pipe(pluck('data'));
  }

  deleteService(user_id: string, service_ids: number[]): Observable<any> {
    return this.http.delete('operator/accesses/user/services/multi', {params: { user_id, 'service_ids[]': service_ids }} );
  }

  // public getHistoryOfUser(id: string): Observable<UserHistoryDataInterface> {
  //   return this.http.get<UserHistoryDataInterface>(`operator/history/user`, { params: { id } });
  // }

  public getHistoryOfUser(id: string, page: number, per_page: number = 30): Observable<UserHistoryDataInterface> {
    return this.http.get<UserHistoryDataInterface>(`operator/users/history`, { params: { id, page: page.toString(), per_page: per_page.toString()} });
  }

}
