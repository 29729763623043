import { DispSettingsControlsPanelComponent } from './disp-settings-controls-panel/disp-settings-controls-panel.component';
import { DispSettingsCreateDispModalComponent } from './disp-settings-create-disp-modal/disp-settings-create-disp-modal.component';
import { DispSettingsDispQueueListComponent } from './disp/disp-settings-disp-queue-list/disp-settings-disp-queue-list.component';
import { DispSettingsDispTableComponent } from './disp/disp-settings-disp-table/disp-settings-disp-table.component';
import { DispSettingsEditHistoryComponent } from './edit/disp-settings-edit-history/disp-settings-edit-history.component';
import { DispSettingsEditTableComponent } from './edit/disp-settings-edit-table/disp-settings-edit-table.component';
import { DispSettingsRemoteTableComponent } from './remote/disp-settings-remote-table/disp-settings-remote-table.component';
import {ClearScaleCallsModalComponent} from './disp/clear-scale-calls-modal/clear-scale-calls-modal.component';
import {DispPasswordResetDialogComponent} from './disp-password-reset-dialog/disp-password-reset-dialog.component';

export const components = [
  DispSettingsDispQueueListComponent,
  DispSettingsControlsPanelComponent,
  DispSettingsDispTableComponent,
  DispSettingsEditTableComponent,
  DispSettingsEditHistoryComponent,
  DispSettingsCreateDispModalComponent,
  DispSettingsRemoteTableComponent,
  ClearScaleCallsModalComponent,
  DispPasswordResetDialogComponent
];

export * from './disp-settings-create-disp-modal/disp-settings-create-disp-modal.component';
export * from './remote/disp-settings-remote-table/disp-settings-remote-table.component';
export * from './edit/disp-settings-edit-history/disp-settings-edit-history.component';
export * from './edit/disp-settings-edit-table/disp-settings-edit-table.component';
export * from './disp/disp-settings-disp-table/disp-settings-disp-table.component';
export * from './disp-settings-controls-panel/disp-settings-controls-panel.component';
export * from './disp/disp-settings-disp-queue-list/disp-settings-disp-queue-list.component';
export * from './disp/clear-scale-calls-modal/clear-scale-calls-modal.component';
export * from './disp-password-reset-dialog/disp-password-reset-dialog.component';
