import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { pluck } from 'rxjs/operators';

export interface CustomLanguage {
  id: string;
  name: string;
  lang: string;
  lang_alias: string | null;
}

export interface Bundle {
  id: string;
  name: string;
  is_active: boolean;
  client_types: string[];
}

export interface TranslationObj {
  key: string;
  translations: TranslationItem[];
}

export interface TranslationItem {
  id: string;
  value: string;
  bundle_ids?: string[];
  locale_id: string;
}

export interface NotCreatedTranslationItem {
  bundle_ids: string[];
  locale_id: string;
  value: string;
}

const listOfLanguagesUrl = 'operator/localization/locales',
  listOfBundlesUrl = 'operator/localization/bundles',
  listOfTranslationsUrl = 'operator/localization/keys',
  listOfBundleTranslationsUrl = 'operator/localization/keys/bundle',
  translationsByKeyUrl = 'operator/localization/translations/key',
  translationItemUrl = 'operator/localization/translations',
  createkeyWithTranslations = 'operator/localization/keys/translations';

@Injectable({
  providedIn: 'root'
})
export class CustomTranslationService {
  langs: CustomLanguage[] = [];
  langs$: BehaviorSubject<CustomLanguage[]> = new BehaviorSubject([]);
  translations: TranslationObj[] = [];

  constructor(private http: HttpClient) {}

  updateTranslations(translationObj: TranslationObj): void {
    this.translations = this.translations.map(tranObj => {
      if (tranObj.key === translationObj.key) {
        return translationObj;
      } else {
        return tranObj;
      }
    });
  }

  getListOfLanguages(): Observable<Array<CustomLanguage>> {
    return this.http.get<{data: CustomLanguage[]}>(listOfLanguagesUrl).pipe(pluck('data'));
  }

  getListOfBundles(): Observable<Array<Bundle>> {
    return this.http.get<{data: Bundle[]}>(listOfBundlesUrl).pipe(pluck('data'));
  }

  getListOfTranslations(): Observable<Array<TranslationObj>> {
    return this.http.get<{data: TranslationObj[]}>(listOfTranslationsUrl).pipe(pluck('data'));
  }

  getTranslationsOfBundle(bundleId: string): Observable<Array<TranslationObj>> {
    return this.http.get<{data: TranslationObj[]}>(listOfBundleTranslationsUrl, { params: { id: bundleId } }).pipe(pluck('data'));
  }

  getTranslationsByKey(key: string): Observable<Array<TranslationItem>> {
    return this.http.get<{data: TranslationItem[]}>(translationsByKeyUrl, { params: { key } }).pipe(pluck('data'));
  }

  postTranslationKey(): Observable<{ key: string }> {
    return this.http.post<{data: { key: string }}>(listOfTranslationsUrl, {}).pipe(pluck('data'));
  }

  postTranslationItem(item: {
    bundle_ids: string[];
    locale_id: string;
    key: string;
    value: string;
  }): Observable<TranslationItem> {
    return this.http.post<{data: TranslationItem}>(translationItemUrl, item).pipe(pluck('data'));
  }

  postTranslation(values: NotCreatedTranslationItem[]): Observable<any> {
    return this.http.post(createkeyWithTranslations, { values }).pipe(pluck('data'));
  }

  putTranslation(item: {
    id: string;
    value: string;
    bundle_ids?: string[];
  }): Observable<{ id: string; value: string; bundle_ids: string[] }> {
    return this.http.put<any>(translationItemUrl, item).pipe(pluck('data'));
  }
}
