import {Passenger, Fare, Order, AddedKmToOrderInterface} from './../../../interfaces/order.interface';
import { ApiOrderFormService } from './../../services/api-order-form.service';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { StateService } from './../../../services/state.service';
import { OrderFormService } from './../../services/order-form.service';
import {
  Component,
  OnInit,
  Optional,
  Inject,
  OnDestroy,
  AfterContentChecked,
  ChangeDetectorRef,
  HostListener
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {mergeMap, debounceTime, filter, takeUntil, skip, distinctUntilChanged, tap, shareReplay} from 'rxjs/operators';
import { TaxiServicesService } from '@global-services/taxi-services.service';
import {Subject, of, merge, pipe, BehaviorSubject, Observable} from 'rxjs';
import {GlobalDataService} from '@global-services/global-data.service';
import {OrderClass} from '@global-classes/order.class';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {GeneralOrdersApiService} from '@global-services/general-orders-api.service';

@UntilDestroy()
@Component({
  selector: 'utax-order-form',
  templateUrl: './order-form.component.html',
  styleUrls: ['./order-form.component.scss']
})
export class OrderFormComponent implements OnInit, OnDestroy, AfterContentChecked {
  mapOrder: any = {};
  order: OrderClass;
  store: any;
  globalServices: any[];
  taxiServices: any;
  saveOrderEvent$ = new Subject(); // for saving from other places;
  private componentDestroyed$ = new Subject();
  private manualChangedTariff = false;
  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<OrderFormComponent>,
    public orderFormService: OrderFormService,
    private fb: UntypedFormBuilder,
    public stateService: StateService,
    private apiOrdFormService: ApiOrderFormService,
    private globalDataService: GlobalDataService,
    public taxiServicesService: TaxiServicesService,
    public generalOrdersApiService: GeneralOrdersApiService,
    private cdr: ChangeDetectorRef
  ) {
    if (this.data && this.data.order) {
      this.orderFormService.order = this.data.order;
      this.order = this.data.order;
      this.orderFormService.initialProductId = this.order.product.id;
      if (this.order?.passenger_overdraft?.amount) {
        this.orderFormService.overdraftBalance$.next(this.order.passenger_overdraft.amount);
      }
    }

    this.globalServices = this.globalDataService.globalTaxiServices$.value;
    this.initForm();

    // CallWay form opening
    if (this.data.call) {
      const transferList = this.globalDataService.globalTransferList$.value;
      this.orderFormService.setCallWayInfo(this.data, this.globalServices, transferList);
    }
  }

  ngAfterContentChecked(): void {
        this.cdr.detectChanges();
    }

  get typeForm(): UntypedFormGroup {
    return this.orderFormService.generalForm.controls.type as UntypedFormGroup;
  }

  ngOnInit(): void {
    this.store = this.stateService.store.value;
    this.taxiServices = this.globalDataService.globalTaxiServices$.value;
    console.log('Data of order-form', this.data);

    if (this.order) {
      this.mapOrder = this.order;
      this.apiOrdFormService
        .getPassengerById(this.order.passenger.id, this.order.service_id)
        .subscribe((passenger: Passenger) => {
          this.orderFormService.setPassengerInForm(passenger);
        });

      this.apiOrdFormService.getFare(this.order.fare.id).subscribe((fare: any) => {
        // getting full Fare
        // this.orderFormService.fare = {...fare.data, paid_time_amount: fare.paid_time_amount, paid_time: fare.paid_time, paid_waiting_time: fare.paid_waiting_time, paid_waiting_time_amount: fare.paid_waiting_time_amount};
        this.orderFormService.fare = fare.data;
        this.mapOrder.fare = fare.data;
        this.orderFormService.addedValue = fare.data.added_value;
      });

      this.orderFormService.paymentType = this.order.payment_type;
    } else {
      this.generalOrdersApiService.markCreatingRequestStart()
        .pipe(
          tap(() => {
            this.generalOrdersApiService.isNewOrder = true;
          }),
          untilDestroyed(this)
        )
        .subscribe();
    }

    // value change subscriptions
    this.addressesValueChange();
    this.basicValueChange();
    this.estimation();

    this.apiOrdFormService.closeOrderForm$.pipe(takeUntil(this.componentDestroyed$)).subscribe((close: boolean) => {
      if (close) {
        this.closeOrderFormModal();
      }
    });
    this.orderFormService.orderFormOpenedStream$.next(true);
  }

  ngOnDestroy(): void {
    this.apiOrdFormService.passengerId = null;
    this.apiOrdFormService.phonePaymentId = null;
    this.apiOrdFormService.estimationObject = undefined;
    this.apiOrdFormService.fullPassenger = null;
    this.orderFormService.disableSaveButtons$.next(false);
    this.orderFormService.isEstimationProgress$.next(false);
    this.orderFormService.overdraftBalance$.next(0);
    this.orderFormService.overdraftIncluded$.next(false);
    this.orderFormService.addDistance$.next(null);
    this.orderFormService.preorderMessageActive$.next(false);
    this.orderFormService.orderFormOpened = false;
    this.orderFormService.fare = null;
    this.orderFormService.order = undefined;
    this.orderFormService.completedRequestsCount = undefined;
    this.orderFormService.bonusBalance = undefined;
    this.orderFormService.addedValue = 0;
    this.orderFormService.blockPassReason = null;
    this.orderFormService.initialProductId = null;
    this.orderFormService.orderFormOpenedStream$.next(false);
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.unsubscribe();
  }

  closeOrderFormModal(): void {
    this.dialogRef.close();
  }

  private initForm(): void {
    let serviceId;
    if (this.data.queue && this.data.queue.taxiServiceId) {
      this.globalServices.forEach(item => {
        if (item.id === this.data.queue.taxiServiceId) {
          serviceId = +item.externalId;
        }
      });
    } else {
      serviceId = this.stateService.dumbStore.service[0].id;
    }

    this.orderFormService.generalForm = this.fb.group({
      id: [''],
      addresses: this.orderFormService.generateAddressForm(this.order),
      basic: this.orderFormService.generateBasicOrderForm(this.order, serviceId),
      type: this.orderFormService.generateTypeForm(this.order)
    });
  }

  private estimation(): void {
    // addresses
    (this.orderFormService.generalForm.controls.addresses as UntypedFormGroup).controls.addresses.valueChanges
      .pipe(
        takeUntil(this.componentDestroyed$),
        debounceTime(300),
        filter(val => this.orderFormService.generalForm.valid && this.orderFormService.generalForm.touched),
        mergeMap(value => this.apiOrdFormService.doEstimate())
      )
      .subscribe(this.estimationSubFunc);

    // service
    (this.orderFormService.generalForm.controls.basic as UntypedFormGroup).controls.taxiService.valueChanges
      .pipe(
        takeUntil(this.componentDestroyed$),
        debounceTime(300),
        filter(val => this.orderFormService.generalForm.valid && this.orderFormService.generalForm.touched),
        mergeMap(value => this.apiOrdFormService.doEstimate())
      )
      .subscribe(this.estimationSubFunc);

    // delivery
    (this.orderFormService.generalForm.controls.basic as UntypedFormGroup).controls.receiver.valueChanges
      .pipe(
        takeUntil(this.componentDestroyed$),
        debounceTime(300),
        filter(val => this.orderFormService.generalForm.valid && this.orderFormService.generalForm.touched && (this.orderFormService.generalForm.controls.basic as UntypedFormGroup).controls.receiver.valid),
        mergeMap(value => this.apiOrdFormService.doEstimate())
      )
      .subscribe(this.estimationSubFunc);


    // product
    (this.orderFormService.generalForm.controls.basic as UntypedFormGroup).controls.tariff.valueChanges
      .pipe(
        takeUntil(this.componentDestroyed$),
        debounceTime(300),
        filter(val => this.orderFormService.generalForm.valid && this.orderFormService.generalForm.touched),
        mergeMap(productId => {
          if (this.apiOrdFormService.estimationObject) {
            return of(productId);
          } else {
            return this.apiOrdFormService.doEstimate();
          }
        })
      )
      .subscribe(value => {
        if (value === this.orderFormService.generalForm.value.basic.tariff) {
          const fareObj = (this.orderFormService.fare.fares || []).find(item => item.product === value);
          this.orderFormService.fare = {
            ...this.orderFormService.fare,
            ...fareObj
          };
          if (!fareObj?.discount) {
            delete this.orderFormService.fare.discount;
          }
          this.mapOrder = {
            waypoints: [...this.orderFormService.generalForm.value.addresses.addresses],
            fare: this.orderFormService.fare
          };
          this.orderFormService.disableSaveButtons$.next(fareObj?.amount < 0);
        } else {
          // estimate resp
          this.orderFormService.fare = value;
          this.mapOrder = {
            waypoints: [...this.orderFormService.generalForm.value.addresses.addresses],
            fare: value
          };
        }
        this.orderFormService.isEstimationProgress$.next(false);
      });

    // delivery

    (this.orderFormService.generalForm.controls.basic as UntypedFormGroup).get('receiver').get('phone_number').valueChanges
      .pipe(
        takeUntil(this.componentDestroyed$),
        debounceTime(300),
        filter(val => this.orderFormService.generalForm.valid && this.orderFormService.generalForm.touched),
        mergeMap(value => {
          if ( value && this.apiOrdFormService.estimationObject) {
            const obj = this.apiOrdFormService.estimationObject.fares.find(item => item.product === this.orderFormService.generalForm.value.basic.tariff);
            return of(obj);
          } else if (value) {
            return this.apiOrdFormService.doEstimate();
          } else {
            return of(null);
          }
        }),
        tap((fareObj) => {
          if (fareObj) {
            this.estimationSubFunc({...this.orderFormService.fare, ...fareObj}, true);
          }
        })
      )
      .subscribe();




    // time/preord
    merge(
      this.typeForm.controls.isPreorder.valueChanges,
      this.typeForm.controls.preorderedTime.valueChanges,
      this.typeForm.controls.preorderedDate.valueChanges,
      this.typeForm.controls.intercity.valueChanges,
      this.typeForm.controls.incity.valueChanges
    )
      .pipe(
        takeUntil(this.componentDestroyed$),
        distinctUntilChanged(),
        debounceTime(300),
        filter(val => this.orderFormService.generalForm.valid && this.orderFormService.generalForm.touched),
        mergeMap(value => this.apiOrdFormService.doEstimate())
      )
      .subscribe(this.estimationSubFunc);
  }

  private estimationSubFunc = (fare: Fare, noCheckIntercity = false): void => {
    this.orderFormService.fare = fare;
    if (!noCheckIntercity && !this.manualChangedTariff) {
      this.orderFormService.checkIntercityDistance();
    }
    this.mapOrder = {
      waypoints: [...this.orderFormService.generalForm.value.addresses.addresses],
      fare
    };
    this.orderFormService.disableSaveButtons$.next(false);
  };

  private basicValueChange(): void {
    const basicFormGroup: UntypedFormGroup = this.orderFormService.generalForm.get('basic') as UntypedFormGroup;
    merge(
      basicFormGroup.controls.passengerName.valueChanges,
      basicFormGroup.controls.commentAboutPassenger.valueChanges
    )
      .pipe(
        takeUntil(this.componentDestroyed$),
        debounceTime(300),
        filter(val => basicFormGroup.controls.clientPhone.valid || basicFormGroup.controls.clientPhone.disabled),
        skip(this.data.id ? 2 : 1),
        mergeMap(basicFormValue => this.apiOrdFormService.updatePassanger())
      )
      .subscribe(() => {});
  }

  private addressesValueChange(): void {
    this.orderFormService.generalForm
      .get('addresses')
      .valueChanges.pipe(takeUntil(this.componentDestroyed$), debounceTime(300))
      .subscribe(addressFormValue => {
        if (addressFormValue.addresses) {
          this.orderFormService.generalForm
            .get('type')
            .get('intercity')
            .patchValue(!!addressFormValue.addresses.find(address => address.intercity), { emitEvent: false });
          if (
            addressFormValue.addresses[0].lat &&
            addressFormValue.addresses[1] &&
            !addressFormValue.addresses[1].name
          ) {
            this.mapOrder = {
              waypoints: [addressFormValue.addresses[0]]
            };
          }
        }
      });
  }

  manualChangeTariff($event: boolean) {
    this.manualChangedTariff = $event;
  }
}
