import {ChangeDetectionStrategy, Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {finalize, pluck, skipWhile, tap} from "rxjs/operators";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {WithdrawalApiService} from "../../services/withdrawal-api.service";
import {StateService} from "../../../services";

@UntilDestroy()
@Component({
  selector: 'utax-create-tin-dialog',
  templateUrl: './create-tin-dialog.component.html',
  styleUrls: ['./create-tin-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CreateTinDialogComponent implements OnInit {

  public disabledApproveButton: boolean;
  public withdrawalItnValue = '';
  private user: any;


  constructor(
    public dialogSelf: MatDialogRef<CreateTinDialogComponent>,
    private withdrawalApiService: WithdrawalApiService,
    private state: StateService,
  ) { }

  ngOnInit(): void {
    this.state.store
      .pipe(
        skipWhile((store) => !store?.user),
        pluck('user'),
        pluck('data'),
        untilDestroyed(this)
  ).subscribe((el) => {
      this.user = el;
    });
  }

  approve() {
    this.disabledApproveButton = true;
    this.withdrawalApiService.createTin(this.withdrawalItnValue)
      .pipe(
        tap((val) => {
          this.state.store.next({...this.state.store.value, user: {...this.user, data: {...this.user.data, tin: this.withdrawalItnValue}}});
          this.dialogSelf.close(true);
        }),
        finalize(() => {
          this.disabledApproveButton = false;
        }),
        untilDestroyed(this)
      )
      .subscribe();
  }

}
