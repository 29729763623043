export interface Queue {
  id: string;
  is_default: boolean;
  use_default_product: boolean;
  product_id: string;
  queue_id: number;
  service_id: number;
  isSpecial?: boolean;
  name?: string;
  taxiServiceId?: string;
  nameService?: string;
  transferId?: string;
  type?: 'driver' | 'company';
}

export interface CallWayCall {
  message: string;
  call_uuid: string;
  call_id: string;
  phone_number: string;
  queue: string;
  cseq?: any;
  call_type?: string;
  prevQueue?: any;
  tbrs?: string;
}

export interface DispatcherFilters {
  checkBoxes: any;
  services: any;
  additionalFilters: any;
  search: string;
  remoted: boolean;
}

export interface ServiceFilter {
  displayName: string;
  id: number;
  name: string;
  status: boolean;
  onlineCabs?: string;
  cantBlockOperator?: boolean; // @TODO mock data, remove later
  waitingCallsNumber?: number; // @TODO mock data, remove later
}

export interface Waypoint {
  address_id?: string;
  id?: number;
  lat: number;
  lng: number;
  name: string;
  ord: number;
  relation_id?: string;
  type?: string;
}

export interface ReqViewOrdLiteInterface   {
  requestId: string;
}

export interface ChatListenerObjectInterface {
  message_type: 'chat:sniff:on' | 'chat:sniff:off' | 'get_chat_updates' | 'user_action';
  payload: ChatListenerObjectPayloadInterface;
  id?: 'OPTIONAL';
}

export interface ChatUserActionInterface {
  id?: number;
  chat_id: number;
  user_id: number;
  action: number;
}

export interface ChatListenerObjectPayloadInterface {
  chat_uuid?: string;
  chat_id?: number;
  user_id?: number;
  action?: number;
}

export enum ChatUserActionEnum {
  empty,
  kick,
  ban,
  mute,
  unban,
  unmute,
}
