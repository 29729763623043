import { Injectable } from '@angular/core';
import {Observable} from "rxjs";
import {IAuthOctobusData} from "../interfaces/auth.interface";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {environment} from "../../environments/environment";

const dispatcherToken = 'dispatcher/oauth';
const octobusHttp = '/v1/dispatcher/ws';

@Injectable({
  providedIn: 'root'
})
export class OctobusService {

  constructor(private http: HttpClient) { }

  octobusLogin(): Observable<IAuthOctobusData> {
    return this.http.get<IAuthOctobusData>(`${dispatcherToken}/token`);
  }
  octobusRefresh(body: IAuthOctobusData): Observable<IAuthOctobusData> {
    return this.http.post<IAuthOctobusData>(`${dispatcherToken}/refresh`, body);
  }
  octobusCheck(token: string): Observable<any> {
    const headers = new HttpHeaders();
    return this.http.get<any>(environment.config.octobusHttp + octobusHttp, {params: {token}, headers});
  }
}
