import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {pluck} from "rxjs/operators";
import {DriverActivityCategoryInterface} from "../interfaces/driver-activity-category.interface";

const activityCategories = 'operator/tariff-plans/activity-categories';


@Injectable({
  providedIn: 'root'
})
export class DriverActivityCategoryService {

  constructor(private http: HttpClient) { }

  // categories

  getListOfCategoriesByService(serviceId: number): Observable<DriverActivityCategoryInterface[]> {
    return this.http.get<{data: DriverActivityCategoryInterface[]}>(activityCategories, { params: { service: `${serviceId}` } }).pipe(pluck('data'));
  }

  postCategory(service: number, category: DriverActivityCategoryInterface): Observable<DriverActivityCategoryInterface> {
    return this.http.post<{data: DriverActivityCategoryInterface}>(activityCategories, category, {params: {service}}).pipe(pluck('data'));
  }

  putCategory(service: number, category: DriverActivityCategoryInterface): Observable<DriverActivityCategoryInterface> {
    return this.http.put<{data: DriverActivityCategoryInterface}>(activityCategories, category, {params: {service}}).pipe(pluck('data'));
  }

  deleteCategory(id: string): Observable<any> {
    return this.http.delete(activityCategories, { params: { id } });
  }
}
