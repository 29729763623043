import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit, Optional} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {HandleEventService} from '../../../services';
import {DispatcherAccount, PreCheckInterface} from "../../models/dispatcher.models";
import {CreateTinDialogComponent} from "../create-tin-dialog/create-tin-dialog.component";
import {DispatcherApiService} from "../../services/dispatcher-api.service";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";

@UntilDestroy()
@Component({
  selector: 'utax-pre-check-dialog',
  templateUrl: './pre-check-dialog.component.html',
  styleUrls: ['./pre-check-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PreCheckDialogComponent implements OnInit {
  public isLoading = false;
  public addWithdrawalCardLoading = false;
  public tinIsExist = false;
  public cardIsExist = false;
  private withdrawalCardWindowTimer: number;


  constructor(public dialogRef: MatDialogRef<PreCheckDialogComponent>,
              private matDialog: MatDialog,
              private dispatcherApiService: DispatcherApiService,
              private changeDetectorRef: ChangeDetectorRef,
              @Optional() @Inject(MAT_DIALOG_DATA) public data: {account: DispatcherAccount, check: PreCheckInterface, tin: string},
              private handleEventService: HandleEventService) {
    this.tinIsExist = !!data.tin;
    this.cardIsExist = !!data.account?.withdrawal_cards?.length;
  }

  ngOnInit() {
  }


  public openAddTinDialog() {
    this.matDialog.open(CreateTinDialogComponent, {
      panelClass: 'request-activate-dialog-container',
      backdropClass: 'request-dialog-backdrop',
      width: '376px',
      disableClose: true,
    }).afterClosed().subscribe((result) => {
      if (result) {
        this.tinIsExist = result;
        this.handleEventService.openSnackBar(`UTAX_OPERATOR_TIN_ADD_SUCCESS`);
        this.changeDetectorRef.detectChanges();
        this.openWork();
      }
    });
  }

  addWithdrawalCard(): void {
    this.addWithdrawalCardLoading = true;

    this.dispatcherApiService.addWithdrawalCard()
      .pipe(
        untilDestroyed(this)
      )
      .subscribe(result => {
        this.addWithdrawalCardLoading = false;
        this.changeDetectorRef.detectChanges();

        const windowRef = window.open(result.redirect_url, '_blank');

        this.withdrawalCardWindowTimer = window.setInterval(() => {
          if (windowRef.closed) {
            window.clearInterval(this.withdrawalCardWindowTimer);
            this.updateDispatcherAccount();
          }
        }, 1000);
      }, () => {
        this.addWithdrawalCardLoading = false;
        this.changeDetectorRef.detectChanges();
      });
  }

  private updateDispatcherAccount(): void {
    this.dispatcherApiService.getAccount()
      .pipe(untilDestroyed(this))
      .subscribe(dispatcherAccount => {
        this.cardIsExist = !!dispatcherAccount?.withdrawal_cards?.length;
        this.changeDetectorRef.detectChanges();
        this.openWork();
      });
  }

  ignoreAttention() {
    this.dialogRef.close({ignore: true, tinIsExist: this.tinIsExist, cardIsExist: this.cardIsExist});
  }

  public openWork() {
    if (this.cardIsExist && this.tinIsExist) {
      this.ignoreAttention();
    }
  }
}
