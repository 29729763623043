import { StateService } from 'src/app/services/state.service';
import { HeaderToolsHandlerService } from './../../services/header-tools-handler.service';
import {Component, Inject, OnInit} from '@angular/core';
import {HandleEventService, WebsocketService} from 'src/app/services';
import {
  Observable,
  combineLatest,
  merge,
  fromEvent,
  Observer,
  Subject,
  of,
  timer,
  forkJoin,
  BehaviorSubject, throwError
} from 'rxjs';
import { AuthService } from 'src/app/auth/services';
import {map, debounceTime, takeUntil, switchMap, catchError, tap} from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { TelephonyService } from 'src/app/telephony/services';
import {RemoteWorkService} from '../../../cabinet/services';
import {APP_CONFIG} from '@global-utils/injection-tokens';
import {fromFetch} from "rxjs/fetch";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {GlobalDataService} from "@global-services/global-data.service";
import {WebRtcLeakService} from "../../../services/web-rtc-leak.service";


export interface ConnectionLatenciesInterface {
  callCentre: number;
  estimator: number;
  miranda: number;
}

@UntilDestroy()
@Component({
  selector: 'utax-header-tools',
  templateUrl: './header-tools.component.html',
  styleUrls: ['./header-tools.component.scss']
})
export class HeaderToolsComponent implements OnInit {
  template;
  templateBalance;
  source: string;
  selectedLang = 'ru';
  loggedIn: boolean;

  internerConnection$: any; // : Subject<boolean> = new Subject();
  private isLeaked = false;


  private componentDestroyed$ = new Subject();
  public environment;
  private latencyCallCentre$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  private latencyEstimator$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  private latencyMiranda$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  private latencyMax = 0;

  constructor(
    public headerToolsHandlerService: HeaderToolsHandlerService,
    private wsService: WebsocketService,
    private authService: AuthService,
    public stateService: StateService,
    public translate: TranslateService,
    private telephonyService: TelephonyService,
    private remoteWorkService: RemoteWorkService,
    private webRtcLeakService: WebRtcLeakService,
    private handleEventService: HandleEventService,
    private globalDataService: GlobalDataService,
    @Inject(APP_CONFIG) private environments: any
  ) {
    this.environment = environments;
  }

  ngOnInit() {
    this.authService.authState.pipe(takeUntil(this.componentDestroyed$)).subscribe(state => {
      this.loggedIn = state;
      if (this.loggedIn) {
        this.pingHosts();
      }
    });
    this.internerConnection$ = merge(
      fromEvent(window, 'offline').pipe(map(() => false)),
      fromEvent(window, 'online').pipe(map(() => true)),
      new Observable((sub: Observer<boolean>) => {
        sub.next(navigator.onLine);
        sub.complete();
      })
    );

    this.translate.use('ua');
    this.selectedLang = 'ua';

    this.headerToolsHandlerService.globalLanguage$.next(this.selectedLang);

    this.headerToolsHandlerService.template
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((templateOfHeaderTools: any) => {
        if (templateOfHeaderTools) {
          this.template = templateOfHeaderTools.controlMenu;
          this.source = templateOfHeaderTools.source;
        }
      });

    this.headerToolsHandlerService.templateBalance
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((templateOfHeaderTools: any) => {
        if (templateOfHeaderTools) {
          this.templateBalance = templateOfHeaderTools.controlBalance;
          // this.source = templateOfHeaderTools.source;
        }
      });

    this.connectionIconInfo();
  }
  connectionIconInfo(): void {
    combineLatest({
      amelia: this.wsService.connections.amelia.isConnected,
      miranda: this.wsService.connections.miranda.isConnected,
      callCenter: this.wsService.connections.callCenter.isConnected,
      interner: this.internerConnection$,
      ameliaLast: this.wsService.connections.amelia.lastUpdate,
      mirandaLast: this.wsService.connections.miranda.lastUpdate,
      callCenterLast: this.wsService.connections.callCenter.lastUpdate,
      latencyMiranda: this.latencyMiranda$,
      latencyCallCentre: this.latencyCallCentre$,
      latencyEstimator: this.latencyEstimator$
    })
      .pipe(
        takeUntil(this.componentDestroyed$),
        debounceTime(200),
        map(wsStatuses => {
          const connectionsStatuses = {
            icon: 'ws-status-red',
            info: ''
          };

          switch (this.source) {
            case 'dispatcher':
              connectionsStatuses.icon = wsStatuses?.miranda || wsStatuses?.callCenter ? 'ws-status-green' : 'ws-status-red';
              connectionsStatuses.icon = this.changeIconStatus(connectionsStatuses.icon);
              connectionsStatuses.info = `
              miranda - ${wsStatuses?.miranda ? 'connected' : 'disconnected'}
              miranda last update - ${moment(wsStatuses?.mirandaLast).format('HH:mm:ss')}
              Ping miranda - ${wsStatuses?.latencyMiranda} ms,
              call-center - ${wsStatuses?.callCenter ? 'connected' : 'disconnected'}
              call-center last update - ${moment(wsStatuses?.callCenterLast).format('HH:mm:ss')}
              internet - ${wsStatuses?.interner ? 'connected' : 'disconnected'},
              Ping estimator - ${wsStatuses?.latencyEstimator} ms
            `;
              break;
            case 'logist':
              if (wsStatuses?.amelia || wsStatuses?.miranda || wsStatuses?.callCenter) {
                connectionsStatuses.icon = 'ws-status-yellow'; // some of ws disconnected
              }
              if (wsStatuses?.amelia && wsStatuses?.miranda && wsStatuses?.callCenter && wsStatuses?.interner) {
                connectionsStatuses.icon = 'ws-status-green'; // everything fine
              }
              connectionsStatuses.icon = this.changeIconStatus(connectionsStatuses.icon);
              connectionsStatuses.info = `
              amelia - ${wsStatuses?.amelia ? 'connected' : 'disconnected'},
              amelia last update - ${moment(wsStatuses?.ameliaLast).format('HH:mm:ss')},
              miranda - ${wsStatuses?.miranda ? 'connected' : 'disconnected'},
              miranda last update - ${moment(wsStatuses?.mirandaLast).format('HH:mm:ss')}
              Ping miranda - ${wsStatuses?.latencyMiranda} ms,
              call-center - ${wsStatuses?.miranda ? 'connected' : 'disconnected'}
              call-center last update - ${moment(wsStatuses?.callCenterLast).format('HH:mm:ss')}
              internet - ${wsStatuses?.interner ? 'connected' : 'disconnected'},
              Ping estimator - ${wsStatuses?.latencyEstimator} ms
            `;
              break;

            default:
              connectionsStatuses.icon = wsStatuses?.miranda ? 'ws-status-green' : 'ws-status-red';
              connectionsStatuses.icon = this.changeIconStatus(connectionsStatuses.icon);
              connectionsStatuses.info = `
              miranda - ${wsStatuses?.miranda ? 'connected' : 'disconnected'}
              miranda last update - ${moment(wsStatuses?.mirandaLast).format('HH:mm:ss')},
              Ping miranda - ${wsStatuses?.latencyMiranda} ms,
              call-center - ${wsStatuses?.callCenter ? 'connected' : 'disconnected'}
              call-center last update - ${moment(wsStatuses?.callCenterLast).format('HH:mm:ss')},
              internet - ${wsStatuses?.interner ? 'connected' : 'disconnected'}
              Ping estimator - ${wsStatuses?.latencyEstimator} ms
            `;
              break;
          }

          if (!wsStatuses.interner) {
            connectionsStatuses.icon = 'ws-status-red'; // internet connection lost
            connectionsStatuses.icon = this.changeIconStatus(connectionsStatuses.icon);
          }
          return connectionsStatuses;
        })
      )
      .subscribe(connectionsStatuses => {
        this.stateService.wsStatus$.next(connectionsStatuses);
      });
  }

  onLangChange(lang) {
    this.translate.use(lang);
    localStorage.setItem('userLang', lang);
    this.headerToolsHandlerService.globalLanguage$.next(lang);
  }

  private pingHosts() {
      const createLatencyObservable = (url: string) => {
        const startTime = window.performance.now();
        return of(url).pipe(
            switchMap(path => {
              if (path.includes('call-centre/api/me/ping')) {
                return this.globalDataService.pingCallCenter();
              } else {
                return  fromFetch(path);
              }
            }),
        ).pipe(
          switchMap(response => {
            const endTime = window.performance.now();
            const latency = endTime - startTime;
            return of(Math.round(latency));
          }),
          catchError(err => {
            return throwError(err);
          })
        );
      };

      timer(0, 10000).pipe(
        switchMap(() => forkJoin({
          // callCentre: createLatencyObservable('call-centre/api/me/ping'),
          estimator: createLatencyObservable(this.environments.config.estimate.replace('/v2/operator', '/v1/status')),
          miranda: createLatencyObservable(`${this.environments.config.http}${this.environments.config.domain}/ping`)
        })),
        tap(({estimator, miranda}) => {
          this.latencyMax = Math.max( estimator, miranda);
          // this.latencyCallCentre$.next(callCentre);
          this.latencyEstimator$.next(estimator);
          this.latencyMiranda$.next(miranda);
        }),
        untilDestroyed(this)
      ).subscribe();
      this.checkWebRtcLeak();
  }

  private checkWebRtcLeak() {
    timer(10000, 10000)
      .pipe(
        tap(() => this.webRtcLeakService.checkRTCLeak()),
        untilDestroyed(this)
      ).subscribe();
    this.webRtcLeakService.isLeaked$
      .pipe(
        tap(isLeaked => {
          if (isLeaked) {
            this.isLeaked = isLeaked;
            this.handleEventService.openSnackBar(
              'UTAX_WEBRTC_LEAK',
              100000,
              true
            );
          } else {
            if (this.isLeaked) {
              this.handleEventService.closeSnackBar();
              this.isLeaked = isLeaked;
            }
          }
        }),
        untilDestroyed(this)
      )
      .subscribe();
  }


  private changeIconStatus(currentIconStatus: string ): string {
    if (this.latencyMax < 60 ) {
      return currentIconStatus === 'ws-status-green' ?
        'ws-status-green' : currentIconStatus;
    } else if (this.latencyMax < 100) {
      return currentIconStatus === 'ws-status-yellow' || currentIconStatus === 'ws-status-green' ?
        'ws-status-yellow' : currentIconStatus;
    } else if (this.latencyMax < 1000) {
      return currentIconStatus === 'ws-status-red' || currentIconStatus === 'ws-status-yellow' || currentIconStatus === 'ws-status-green' ?
        'ws-status-red' : currentIconStatus;
    } else {
      return 'ws-status-fail';
    }
  }

  logOut() {
    const isActiveCallCenter = localStorage.getItem('callCenterActive');
    if (isActiveCallCenter || (!this.remoteWorkService.workState$.value && this.environment.liteVersion)) {
      this.authService.logout();
    } else {
      this.telephonyService.disconnect().subscribe(arg => {
        console.log('🚀 ~ disconnect ~ arg', arg);
        if (arg) {
          this.authService.logout();
        }
      });
    }
  }
}
