import { CookieService } from 'ngx-cookie-service';
import { environment } from '../../environments/environment';
import {BehaviorSubject, Observable, Subject, throwError} from 'rxjs';
import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpClient,
  HttpHeaders
} from '@angular/common/http';
import {catchError, filter, map, switchMap, take, tap} from 'rxjs/operators';
import { Router } from '@angular/router';
import { uuidv4 } from './uuid';
import { StateService } from '../services';
import getToken from './get-token';
import {SharedWorkerService} from '@global-services/shared-worker.service';
import {IAuthOctobusData} from "../interfaces/auth.interface";
import {LocalStorageService} from "ngx-webstorage";
import {OctobusService} from "../services/octobus.service";
const { version } = require('../../../package.json');

const REFRESH_ERROR = 'Unauthorized';

@Injectable()
export class RequestBuildInterceptor implements HttpInterceptor {
  settings: any = {};
  position = null;

  isRefreshRequest = false;
  refreshTokenInProgress = false;
  tokenRefreshedSource = new Subject();
  tokenRefreshed$ = this.tokenRefreshedSource.asObservable();
  private notRefresh = false;
  private refreshTokenSubject: Subject<any> = new Subject<any>();

  private isRefreshingOctobus = false; // Чи оновлюється токен
  private refreshTokenSubjectOcsobus$: BehaviorSubject<IAuthOctobusData | null> =
    new BehaviorSubject<IAuthOctobusData | null>(null);

  constructor(
    private http: HttpClient,
    private router: Router,
    private cookieService: CookieService,
    private stateService: StateService,
    private octobusService: OctobusService,
    private localStorageService: LocalStorageService,
    private sharedWorkerService: SharedWorkerService
  ) {
    this.sharedWorkerService.startSharedWorker();

    this.sharedWorkerService.sharedWorker.port.onmessage = ({data}) => {
      this.refreshTokenSubject.next(data);
    };

  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    navigator.geolocation.getCurrentPosition(position => {
      this.position = position;
    });
    return next.handle(this.setHeaders(req)).pipe(
      catchError(error => {
        return this.handleResponseError(error, req, next);
      })
    );
  }

  public handleResponseError(error, request?, next?): Observable<any> {
    if (error.status === 401) {
      this.notRefresh = localStorage.getItem('unauthorizedOperatorLogin') === 'true';
      if (
        (error.url.indexOf('callee') < 0) &&
        (error.url.indexOf('octobus') < 0) &&
        (error.url.indexOf('supporter') < 0) &&
        (error.url.indexOf('ms.utaxcloud') < 0) &&
        (error.url.indexOf('dispatcher/oauth/refresh') < 0) ||
        error.error.code === 'not_allowed_ip' ||
        (error.error.error === REFRESH_ERROR && error.error.path.includes('/api/auth/refresh') && !this.notRefresh)
      ) {
        this.logout();
        return throwError(error);
      } else if (error.url.includes(environment.config.octobus.substring(6)) || error.url.includes('dispatcher/oauth/refresh')) {
        return throwError(error);
      } else if (!!~error.url.indexOf('supporter')) {
        // Якщо помилка 401, пробуємо оновити токен
        if (error.status === 401 && !this.isRefreshingOctobus) {
          return this.handle401ErrorOctobus(request, next);
        } else if (error.status === 401 && this.isRefreshingOctobus) {
          // Очікуємо завершення оновлення токена
          return this.waitForTokenRefreshOctobus(request, next);
        } else {
          return throwError(() => error); // Інші помилки
        }
      } else {
        return this.refreshToken().pipe(
          switchMap((res) => {
            request = this.setHeaders(request, res?.accessToken);
            return next.handle(request);
          }),
          catchError(e => {
            if (e.status !== 401) {
              return this.handleResponseError(e);
            } else {
              if (!this.notRefresh) {
                this.logout();
              }
              return throwError(e.error);
            }
          }));
      }
    } else if (error.status === 403) {
      if (error.error.type === 'UNAUTHORIZED_OPERATOR_LOGIN') {
        localStorage.setItem('unauthorizedOperatorLogin', 'true');
        this.notRefresh = localStorage.getItem('unauthorizedOperatorLogin') === 'true';
        return throwError(error);
      } else {
        return throwError(error);
      }
    } else if (error.status === 422) {
      if (!!error.url.indexOf('operator/login') && error?.error?.errors?.otp) {
        return throwError(error);
      } else if (error.url.includes('operator/requests')) {
        return throwError(error);
      } else {
        return throwError(error);
      }
    } else if ( error.status === 404) {
      return throwError(error);
    } else if (error.status === 400) {
      if (
        error.error.type === 'NO_ACTIVE_WORK_SHIFT' ||
        error.error?.code ||
        !['No route found', 'dispatcher not found', 'octobus', 'cannot get start point'].includes(error?.error)) {
        return throwError(error);
      }
    } else if (error.status === 0) {
        return throwError(error);
    } else {
      return throwError(error);
    }
  }

  refreshToken(): Observable<any> {
    if (this.refreshTokenInProgress) {
      return new Observable(observer => {
        this.tokenRefreshed$.subscribe(() => {
          observer.next();
          observer.complete();
        });
      });
    } else {
      this.refreshTokenInProgress = true;
      const accessToken = this.cookieService.get('callCenterToken') || localStorage.getItem('callCenterToken');
      const refreshToken = this.cookieService.get('callCenterRefreshToken') || localStorage.getItem('callCenterRefreshToken');
      const authCallCenterHeader = accessToken ? `Bearer ${accessToken}` : '';

      this.sharedWorkerService.sendMessageSharedWorker({
        body: { accessToken, refreshToken},
        url: `${environment.config.callCentre}/api/auth/refresh`,
        headers: {authorization: accessToken || authCallCenterHeader}
      });

      return this.refreshTokenSubject.asObservable().pipe(
        map((res: any) => {
          if (res.accessToken) {
            return res;
          } else {
            return throwError(res);
          }
        }),
        tap((res: any) => {
          this.cookieService.set('callCenterToken', res.accessToken, 365, '/', environment.config.domain, false, 'Lax');
          this.cookieService.set('callCenterRefreshToken', res.refreshToken, 365, '/', environment.config.domain, false, 'Lax');
          localStorage.setItem('callCenterToken', res.accessToken);
          localStorage.setItem('callCenterRefreshToken', res.refreshToken);
          this.refreshTokenInProgress = false;
          this.tokenRefreshedSource.next(null);
          return res;
        }),
        take(1),
        catchError(err => {
          this.refreshTokenInProgress = false;
          return throwError(err);
        }));
    }
  }

  sendReq(req: HttpRequest<any>) {
    return this.http.request(req);
  }

  setHeaders(req: HttpRequest<any>, accessToken?: string): HttpRequest<any> {
    let request = req.clone(this.setAuthHeader(req, accessToken));
    if (~req.url.indexOf(environment.config.routingMachine)) {
      return request;
    }
    let oldHeaders;
    request.headers.keys().forEach(key => {
      oldHeaders = { ...oldHeaders, [key]: request.headers.get(key) };
    });
    if (oldHeaders && oldHeaders.key === '') {
      oldHeaders = null;
    }

    request = request.clone({
      headers: new HttpHeaders({
        ...oldHeaders,
        // 'X-Request-ID': reqId,
        // 'x-megakit-session-id': this.stateService.sessionId
        //   ? this.stateService.sessionId
        //   : '',
        // 'x-megakit-redirectcount': window.history.state
        //   ? window.history.state.navigationId
        //   : 0,
        // 'x-megakit-client-name': 'dispatcher',
        // 'x-megakit-client-id':
        //   this.stateService.dumbStore.length > 0
        //     ? this.stateService.dumbStore.user.data.id
        //     : '',
        // 'x-megakit-client-version': version,
        // 'x-megakit-device': this.getOS(),
        // 'x-megakit-device-epoch': Date.now(),
        // 'x-megakit-device-id': browserId,
        // 'x-megakit-device-mobile-iso2': window.navigator.language.slice(-2),
        'x-megakit-device-language': window.navigator.language,
        'Accept-Language': 'uk-UA',
        // 'x-megakit-request-uuid': reqId,
        // 'x-megakit-device-location-accuracy': this.position
        //   ? this.position.coords.accuracy
        //   : 0,
        // // 'x-megakit-device-location-altitude': this.position
        // //   ? this.position.coords.altitude
        // //   : 0,
        // 'x-megakit-device-location-latitude': this.position
        //   ? this.position.coords.latitude
        //   : 0,
        // 'x-megakit-device-location-longitude': this.position
        //   ? this.position.coords.longitude
        //   : 0,
        // // 'x-megakit-device-location-speed': this.position
        // //   ? this.position.coords.speed
        // //   : 0,
        // 'x-megakit-network-classifier': 'fast',
        // 'x-megakit-device-location-provider': 'fuse',
        // 'x-megakit-client-session': clientSessionId,
        // 'x-megakit-client-user-session-id': userSessionId
      })
    });
    return request;
  }

  setAuthHeader(req: HttpRequest<any>, accessToken?: string): HttpRequest<any> {
    let token;
    let callCenterToken;
    if (location.hostname === 'localhost' || location.hostname.startsWith('192.168')) {
      token = localStorage.getItem('token');
      callCenterToken = localStorage.getItem('callCenterToken');
    } else {
      token = this.cookieService.get('token');
      callCenterToken = this.cookieService.get('callCenterToken');
    }
    const authHeader = token ? `Bearer ${token}` : '';
    const authCallCenterHeader = callCenterToken ? `Bearer ${callCenterToken}` : '';
    let authReq: any;
    const index = req.url.indexOf('#');
    if (~index) {
      const server = req.url.substr(0, index);
      console.log(req.url);

      if (server) {
        req = req.clone({
          url: `${environment.config[server]}/${req.url.replace(server + '#', '')}`,
          headers: req.headers.set('Accept', 'application/json, text/plain, */*').set('Authorization', authHeader)
        });
      }
    }
    // if (!!~req.url.indexOf('192.168.1.24')) {
    //   authReq = req.clone({
    //     url: `${environment.config.http}${req.url}`,
    //     headers: req.headers
    //   });
    //   return authReq;
    // } else
    if (!~req.url.indexOf('imgs/') &&
      !~req.url.indexOf('http') &&
      !~req.url.indexOf('call-centre') &&
      !~req.url.indexOf('dispatcher') &&
      !~req.url.indexOf('passenger-service') &&
      !~req.url.indexOf('driver-registration') &&
      !~req.url.indexOf('driver-application') &&
      !~req.url.indexOf('operator-manage') &&
      !~req.url.indexOf('media') &&
      !~req.url.indexOf('supporter')
    ) {
      authReq = req.clone({
        url: `${environment.config.http}${environment.config.domain}/${environment.config.client}/${req.url}`,
        headers: req.headers.set('Authorization', authHeader)
      });
      return authReq;
    } else if (!!~req.url.indexOf('supporter')) {
      return req.clone({
        url: `${environment.config.support}/${req.url}`,
        headers: req.headers.set('Authorization', 'Bearer ' + this.localStorageService.retrieve('octobusToken')?.token)
      });
    } else if (!!~req.url.indexOf('AUTH/')) {
      authReq = req.clone({
        url: req.url.slice(5),
        headers: req.headers.set('Authorization', authHeader)
      });
      return authReq;
    } else if (!!~req.url.indexOf('estimate')) {
      // estimate request
      authReq = req.clone({
        url: req.url,
        headers: req.headers.set('Authorization', authHeader)
      });
      return authReq;
    } else if (!!~req.url.indexOf('call-centre')) {
      authReq = req.clone({
        // 12
        url: `${environment.config.callCentre}/${req.url.substring(12)}`,
        headers: req.headers.set('Authorization', accessToken || authCallCenterHeader)
      });
      return authReq;
    } else if (!!~req.url.indexOf('callee')) {
      authReq = req.clone({
        headers: req.headers.set('Authorization', accessToken || authCallCenterHeader)
      });
      return authReq;
    } else if (!!~req.url.indexOf('dispatcher') && !req.url.includes('octobus')) {
      authReq = req.clone({
        url: `${environment.config.dispatcher}/${req.url.substring('dispatcher'.length + 1)}`,
        headers: req.headers.set('Authorization', authHeader)
      });
      return authReq;
    } else if (!!~req.url.indexOf('passenger-service') && !req.url.includes('octobus')) {
      authReq = req.clone({
        url: `${environment.config.passenger}/${req.url.substring('passenger-service'.length + 1)}`,
        headers: req.headers.set('Authorization', authHeader)
      });
      return authReq;
    } else if (!!~req.url.indexOf('driver-registration') || !!~req.url.indexOf('media/api/images')) {
      // requests to registration server
      return req.clone({
        url: `${environment.config.registrationServer}/${req.url}`,
        headers: req.headers.set('Authorization', getToken(this.cookieService, true))
      });
    } else if (!!~req.url.indexOf('driver-application')) {
      return req.clone({
        url: `${environment.config.registrationServer}/${req.url.substring('driver-application'.length + 1)}`,
        headers: req.headers.set('Authorization', getToken(this.cookieService, true))
      });
    } else if (!!~req.url.indexOf('media')) {
      return req.clone({
        url: `${environment.config.mediaServer}/${req.url}`,
        headers: req.headers.set('Authorization', getToken(this.cookieService, true))
      });
    } else if (!!~req.url.indexOf('operator-manage')) {
      authReq = req.clone({
        url: `${environment.config.dispatcherManage}/${req.url.substring('operator-manage'.length + 1)}`,
        headers: req.headers.set('Authorization', authHeader)
      });
      return authReq;
    } else {
      return req;
    }
  }


  private handle401ErrorOctobus(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.isRefreshingOctobus = true;

    // Викликаємо оновлення токена
    return this.octobusService.octobusRefresh(this.localStorageService.retrieve('octobusToken')).pipe(
      tap((newTokens) => {
        this.localStorageService.store('octobusToken', newTokens);
        this.refreshTokenSubjectOcsobus$.next(newTokens);
      }),
      switchMap((newTokens) => {
        // Повторно виконуємо оригінальний запит із новим токеном
        return next.handle(this.addTokenOctobus(request, newTokens));
      }),
      tap(() => {
        this.isRefreshingOctobus = false;
      }),
      catchError((err) => {
        // Якщо оновлення токена не вдалося, перенаправляємо на авторизацію
        if (err.status === 401) {
          return this.octobusService.octobusLogin().pipe(
            tap((newTokens) => {
              this.localStorageService.store('octobusToken', newTokens);
              this.refreshTokenSubjectOcsobus$.next(newTokens);
              this.isRefreshingOctobus = false;
            }),
            switchMap((newTokens) => {
              return next.handle(this.addTokenOctobus(request, newTokens));
            }),
            catchError(() => {
              return throwError(() => err);
            })
          );
        } else {
          this.isRefreshingOctobus = false;
          this.refreshTokenSubjectOcsobus$.next(null);
          return throwError(() => err);
        }
      })
    );
  }

  private addTokenOctobus(request: HttpRequest<any>, token: IAuthOctobusData): HttpRequest<any> {
    if (token) {
      return request.clone({
        setHeaders: { Authorization: `Bearer ${token.token}` },
      });
    }
    return request;
  }

  private waitForTokenRefreshOctobus(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Очікуємо завершення оновлення токена
    return this.refreshTokenSubjectOcsobus$.pipe(
      filter((token) => token !== null), // Пропускаємо тільки, коли токен оновлено
      take(1), // Беремо тільки перше значення
      switchMap((token) => {
        // Повторно виконуємо оригінальний запит із новим токеном
        return next.handle(this.addTokenOctobus(request, token));
      })
    );
  }

  private getUserSessionId() {
    let userSessionId;
    if (!this.cookieService.get('userSessionId') && !this.stateService.dumbStore) {
      userSessionId = uuidv4();
      this.cookieService.set('userSessionId', userSessionId);
    } else {
      userSessionId = this.cookieService.get('userSessionId');
    }
    return userSessionId;
  }

  private getClientSessionId() {
    let clientSessionId;
    if (!this.cookieService.get(version)) {
      clientSessionId = uuidv4();
      this.cookieService.set(version, clientSessionId);
    } else {
      clientSessionId = this.cookieService.get(version);
    }
    return clientSessionId;
  }

  private getBrowserId() {
    let browserId = this.cookieService.get('browserId');
    if (!browserId) {
      browserId = uuidv4();
      this.cookieService.set('browserId', browserId);
    }
    return browserId;
  }

  logout() {
    this.cookieService.delete('token', '/', environment.config.domain);
    this.cookieService.delete('callCenterToken', '/', environment.config.domain);
    this.cookieService.delete('callCenterRefreshToken', '/', environment.config.domain);
    localStorage.removeItem('token');
    localStorage.removeItem('callCenterToken');
    localStorage.removeItem('callCenterRefreshToken');
    localStorage.removeItem('service');
    this.router.navigate(['/login']);
    location.reload();
  }
}
