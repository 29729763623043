import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {BehaviorSubject, Observable} from "rxjs";
import {map, pluck} from "rxjs/operators";
import {
  CallSign, CallSignAvailable,
  CarClass, ClassAssosiation,
  CompleteRequestvalue,
  DriverRegistrationRejectionReason,
  ReqsStatisticItem, RequestHistoryItem
} from "../applications/models/applications.interface";

const requestsUrl = 'driver-registration/api/operator/requests',
  requestsMultiUrl = 'driver-registration/api/operator/requests/multi',
  rejectReasonsUrl = 'driver-registration/api/operator/requests/reject/reasons',
  cancelRequestUrl = 'driver-registration/api/operator/requests/cancel',
  completeRequestUrl = 'driver-registration/api/operator/requests/complete',
  rejectRequestUrl = 'driver-registration/api/operator/requests/reject',
  reqsStatUrl = 'driver-registration/api/operator/requests/count',
  requestViewUrl = 'driver-registration/api/operator/requests/view',
  reqHistoryUrl = 'driver-registration/api/operator/requests/history',
  detectReqUrl = 'operator/vehicle-model/associations/detect',
  detectClassReqUrl = 'operator/vehicle-class/associations/detect',
  callSignsReqUrl = 'operator/drivers/callsigns/next',
  callSignAvailableUrl = 'operator/drivers/callsigns/status';

@Injectable({
  providedIn: 'root'
})
export class DriverApplicationsService {
  public viewRequestSearch = false;
  public requestSearchString = '';
  public requestSearchString$: BehaviorSubject<string> = new BehaviorSubject('');


  constructor(private http: HttpClient) {}

  getListOfReqs(page: number, service_id: number): Observable<any> {
    return this.http
      .get<any>(requestsUrl, { params: { page: `${page}`, service_id: `${service_id}` } })
      .pipe(map(res => ({ ...res, service_id })));
  }

  getListOfReqsMultiService(page: number, service_id: string[], query: string, status: string[], types: string[]): Observable<any> {
    let params = new HttpParams();
    params = params.append('service_id', service_id.join(','));
    params = params.append('page', `${page}`);
    params = params.append('size', '20');
    params = params.append('query', query.replace('+', ''));
    params = params.append('status', status.join(','));
    params = params.append('driver_app_type', types.join(','));

    return this.http.get(requestsMultiUrl, { params });
  }

  getRejectReasons(): Observable<Array<DriverRegistrationRejectionReason>> {
    return this.http.get<Array<DriverRegistrationRejectionReason>>(rejectReasonsUrl);
  }

  cancelRequest(reason_id: string, comment: string, request_id: string): Observable<any> {
    // Cancel driver registration request
    return this.http.post(cancelRequestUrl, { reason_id, comment, request_id });
  }

  completeRequest(reqValue: CompleteRequestvalue): Observable<any> {
    // Confirm driver registration request and create driver
    return this.http.post(completeRequestUrl, reqValue);
  }

  rejectRequest(comment: string, rejected_images: string[], request_id: string): Observable<any> {
    // Reject driver registration request and send for revision
    return this.http.post(rejectRequestUrl, {
      comment,
      rejected_images: rejected_images.map(img => ({ type: img, comment: '' })),
      request_id
    });
  }

  getRequestsStat(serviceIds: string[]): Observable<Array<ReqsStatisticItem>> {
    let params = new HttpParams();
    params = params.append('service_id', serviceIds.join(','));
    return this.http.get<Array<ReqsStatisticItem>>(reqsStatUrl, { params });
  }

  requestView(request_id: string): Observable<any> {
    return this.http.get(requestViewUrl, { params: { request_id } });
  }

  getRequestHistory(request_id): Observable<Array<RequestHistoryItem>> {
    return this.http.get<Array<RequestHistoryItem>>(reqHistoryUrl, { params: { request_id } });
  }

  getClassOfCar(model_id, year, body_type_id): Observable<CarClass> {
    return this.http
      .get<{data: CarClass}>(detectReqUrl, { params: { model_id, year, body_type_id } })
      .pipe(pluck('data'));
  }

  getInfoAboutCarByClass(params: { service_id: string; vehicle_class_id: string }): Observable<ClassAssosiation> {
    return this.http.get<{data: ClassAssosiation}>(detectClassReqUrl, { params }).pipe(pluck('data'));
  }

  public getNextCallSigns(serviceId: string): Observable<CallSign> {
    return this.http.get<{data: CallSign}>(callSignsReqUrl, { params: { service_id: serviceId } })
      .pipe(
        pluck('data')
      );
  }

  public checkCallSign(serviceId: string, callSign: string): Observable<CallSignAvailable> {
    return this.http.get<{data: CallSignAvailable}>(callSignAvailableUrl, {params: {service_id: serviceId, callsign: callSign}})
      .pipe(
        pluck('data')
      );
  }
}
