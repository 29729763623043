import { Order, Service } from '../../../interfaces/order.interface';
import { StateService } from 'src/app/services/state.service';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import {CallWayService, DispOrdersService, OrderatorsInfoService} from '../../services';
import { HeaderToolsHandlerService } from '../../../navigation/services';
import {
  AfterViewInit,
  Component,
  ElementRef, Inject,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { OrderFormService, OrderModalService } from 'src/app/order-form/services';
import {AllowIn, ShortcutInput} from 'ng-keyboard-shortcuts';
import {delayWhen, filter, map, mergeMap, retryWhen, switchMap, takeUntil} from 'rxjs/operators';
import {forkJoin, interval, Observable, of, Subject, timer} from 'rxjs';
import {
  MessageToDriverComponent
} from 'src/app/shared/components/modals/message-to-driver/message-to-driver.component';
import * as moment from 'moment';
import { BlockDriverModalComponent } from 'src/app/shared/components/modals/block-driver/block-driver.component';
import { HandleEventService } from 'src/app/services/handle-event-service.service';
import { SpaceForCallwayService } from 'src/app/shared/services/space-for-callway.service';
import { OrdersService } from 'src/app/services/orders.service';
import {TaxiServicesService, WebsocketService} from 'src/app/services';
import { TransferQueueModalComponent } from '../../components';
import { YesNoModalComponent } from 'src/app/shared/components';
import { normalizePhoneNumber } from 'src/app/shared/utils/normalize-number';
import { RemoteWorkService } from '@global-cabinet/services';
import { TelephonySharedService } from 'src/app/telephony/services/telephony-shared.service';
import { TelephonyService } from 'src/app/telephony/services';
import { PhoneNumberService } from '../../../services';
import {BalanceService} from '@global-services/balance.service';
import {GlobalDataService} from '@global-services/global-data.service';
import {APP_CONFIG} from '@global-utils/injection-tokens';
import {OrderClass} from '@global-classes/order.class';
import {StatisticService} from '@global-services/statistic.service';
import {ICallStatistics} from '../../../models/global-data.interfaces';
import {ChatApiService} from "../../../shared/services";
import {OrderFormComponent} from "../../../order-form/containers/order-form/order-form.component";

@Component({
  selector: 'utax-dispatcher',
  templateUrl: './dispatcher.component.html',
  styleUrls: ['./dispatcher.component.scss']
})
export class DispatcherComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('controlMenu') controlMenu: TemplateRef<any>;
  @ViewChild('controlBalance') controlBalance: TemplateRef<any>;
  @ViewChild('searchFilter') searchFilter: ElementRef;

  dialogRef: MatDialogRef<| OrderFormComponent
    | TransferQueueModalComponent
    | YesNoModalComponent
    | MessageToDriverComponent
    | BlockDriverModalComponent>;
  services: Service[] = [];
  shortcuts$: Observable<ShortcutInput[]>;
  remoted = false;
  subCall;
  private componentDestroyed: Subject<boolean> = new Subject();
  public isLiteVersion = false;
  public searchLiteVersionInput = '';
  public callStatistics$: Observable<ICallStatistics>;
  public permissions: any[];
  public currentDate = Date.now();


  constructor(
    private headerToolsHandlerService: HeaderToolsHandlerService,
    public dispOrdersService: DispOrdersService,
    private dialog: MatDialog,
    public orderFormService: OrderFormService,
    public callWayService: CallWayService,
    public stateService: StateService,
    private handleEventService: HandleEventService,
    private orderModalService: OrderModalService,
    public spaceForCallwayService: SpaceForCallwayService,
    public ordersService: OrdersService,
    private taxiServicesService: TaxiServicesService,
    public telephonyService: TelephonyService,
    public telephonySharedService: TelephonySharedService,
    private remoteWorkService: RemoteWorkService,
    private phoneNumberService: PhoneNumberService,
    public balanceService: BalanceService,
    public globalDataService: GlobalDataService,
    private statisticService: StatisticService,
    private orderatorsInfoService: OrderatorsInfoService,
    @Inject(APP_CONFIG) private environment: any
  ) {
    this.isLiteVersion = this.environment.liteVersion;
    setInterval(() => {this.currentDate = Date.now(); }, 1);
  }

  ngOnInit(): void {
    this.callStatistics$ = timer(0, 5000).pipe(
      mergeMap(() => this.statisticService.getCurrentCallsStatistic()),
      retryWhen(errors =>
        errors.pipe(
          delayWhen(val => timer(5000))
        )
      )
    );
    if (this.isLiteVersion) {
      this.balanceService.getCurrentModifier()
        .pipe(takeUntil(this.componentDestroyed))
        .subscribe(response => {
        this.telephonyService.currentModifier$.next(response.status === 200 ? response.body.multiplier : null);
      });
    }
    this.stateService.hideCabinetOpenIcon = true;
    this.dispOrdersService.enableService();
    this.callWayService.enableService();
    this.callWayService.callWaySocketConnected$
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe((connected: boolean) => {
        if (connected) {
          this.telephonySharedService.disconnect();
        }
      });
    this.callWayService.call$
      .pipe(
        // filter pongs
        filter((result: any) => !result.pong),
        takeUntil(this.componentDestroyed),
        map(call => {
          if (call && call.phone_number) {
            return { ...call, phone_number: normalizePhoneNumber(call.phone_number) };
          } else {
            return call;
          }
        })
      )
      .subscribe((call: any) => {
        this.handleCallFunc(call);
      });
    this.telephonySharedService.incomeCalls$
      .pipe(
        takeUntil(this.componentDestroyed),
        filter(res => {
          const response = Boolean(res);
          if (!response) {
            this.dispOrdersService.clearCallWayOrders();
            this.subCall = null;
            // this.orderModalService.closeCreateOrderDialog();
          }
          return response;
        })
      )
      .subscribe((event: any) => {
        this.handleCallFunc(event);
      });

    this.stateService.store.pipe(takeUntil(this.componentDestroyed)).subscribe(store => {
      if (store && store.service && store.service.length > 0) {
        this.services = store.service;
        if (this.isLiteVersion) {
          this.orderatorsInfoService.getReqViewOrdLite()
            .pipe(takeUntil(this.componentDestroyed))
            .subscribe(response => this.dispOrdersService.requiredViewOrdersLite$.next(response));
        }

        this.createTaxiServisesSub();
      }
      if (store && store.permissions && store.permissions.length > 0) {
        this.permissions = store.permissions;
      }

    });

    if (this.remoteWorkService.remoteWorkState$.value.is_remote_allowed || this.isLiteVersion) {
      this.remoted = true;
    }
    this.shortcutHelper();
  }

  ngAfterViewInit(): void {
    this.headerToolsHandlerService.template.next({
      controlMenu: this.controlMenu,
      source: 'dispatcher'
    });
    this.headerToolsHandlerService.templateBalance.next({
      controlBalance: this.controlBalance,
      source: 'dispatcher'
    });
  }

  ngOnDestroy(): void {
    this.telephonySharedService.disconnect();
    this.componentDestroyed.next(true);
    this.componentDestroyed.unsubscribe();
  }

  openCreateOrederModal(): void {
    if (this.subCall) {
      console.log(this.subCall);
      console.log(this.callWayService.transferQueues);
      console.log('---------1---------');
      console.log('---------1---------');
      console.log('---------1---------');
      const queue = this.callWayService.transferQueues.find((que: any) => String(que.mappingId) === String(this.subCall.queue));
      console.log(queue);
      // console.log(this.callWayService.queues2);
      console.log('---------2---------');
      console.log('---------2---------');
      console.log('---------2---------');
      // queue = this.callWayService.queues2.find((que: Queue) => String(que.queue_id) === String(queue.id));
      this.orderModalService.createOrder(this.subCall, queue);
    } else {
      this.orderModalService.createOrder(this.subCall);
    }
  }

  filtersChange(event): void {
    // this.dispOrdersService.clearCallWayOrders();
    if (event.serviceFilter) {
      this.dispOrdersService.setFilters(event, true);
    } else {
      // setting new filters and rebuild list of orders
      this.dispOrdersService.setFilters(event);
    }
  }

  openMessageModal() {
    this.dispOrdersService.modalOpened = true;
    this.dialogRef = this.dialog.open(MessageToDriverComponent, {
      panelClass: 'message-to-driver-modal-container'
    });

    this.dialogRef
      .afterClosed()
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe(() => {
        this.dispOrdersService.modalOpened = false;
        this.dialogRef = null;
      });
  }

  blockDriver(): void {
    this.dialogRef = this.dialog.open(BlockDriverModalComponent, {
      disableClose: true,
      panelClass: 'message-to-driver-modal-container'
    });
    this.dialogRef
      .afterClosed()
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe(data => {
        if (data) {
          this.handleEventService.openSnackBar('UTAX_DRIVER_LOCKED_SUCCESS', 3);
        }
        this.dialogRef = null;
      });
  }

  closeOrderForm(event: { cseq: number }): void {
    console.log(
      '🚀 ~ this.orderModalService.dialogRef.componentInstance.data',
      this.orderModalService.dialogRef,
      event
    );
    if (event?.cseq && this.orderModalService?.dialogRef?.componentInstance?.data?.queue?.cseq === event.cseq) {
      // if order form opened from with cancel call
      this.orderModalService.dialogRef.close();
    }
  }

  saveUseCallWayValue(event): void {
    localStorage.setItem('useCallWay', `${event.checked}`);
  }

  private handleCallFunc(call): void {
    if (call && call.cmd === 'error') {
      this.handleEventService.openSnackBar('TRANSFER_ERROR');
    } else if (call.cmd === 'ok') {
      this.handleEventService.openSnackBar('TRANSFER_SUCCESS');
    }

    // check if form is opened
    if (this.orderModalService.dialogRef) {
      // @TODO refactor
      const congif = new MatDialogConfig();
      congif.data = {
        title: 'WHAT_WILL_DO_WITH_OLD_ORDER',
        buttons: {
          yes: {
            title: 'SAVE',
            disabled: !this.orderFormService.generalForm.valid || this.orderFormService.disableSaveButtons$.value
          },
          no: { title: 'CANCEL', disabled: false }
        }
      };
      congif.panelClass = 'yes-no-modal-container';
      this.dialogRef = this.dialog.open(YesNoModalComponent, congif);
      this.dialogRef
        .afterClosed()
        .pipe(
          takeUntil(this.componentDestroyed),
          mergeMap(data => {
            if (data === 'YES') {
              this.orderModalService?.dialogRef?.componentInstance?.saveOrderEvent$?.next(true);
              return this.orderModalService.dialogRef?.afterClosed() || of();
            } else {
              this.orderModalService.dialogRef.close();
              return forkJoin([this.orderModalService.dialogRef.afterClosed(), of('NO')]);
            }
          })
        )
        .subscribe((res: any) => {
          this.orderModalService.dialogRef = null;
          this.dialogRef = null;
          this.callHandeling(call);
        });
    } else if (this.callWayService.transferQueueIds.some(item => item === call.queue)) {
      // first(transfer) call
      this.openTransferModal();
    } else {
      // regular call
      this.callHandeling(call);
    }
  }

  private createTaxiServisesSub(): void {
    this.taxiServicesService
      .getInfoAboutServices(this.services.map(service => `${service.id}`))
      .subscribe(this.taxiServicesSubFunc);

    interval(31000)
      .pipe(
        takeUntil(this.componentDestroyed),
        mergeMap(() => this.taxiServicesService.getInfoAboutServices(this.services.map(service => `${service.id}`)))
      )
      .subscribe(this.taxiServicesSubFunc);
  }

  private taxiServicesSubFunc = (servicesInfo: any[]) => {
    this.services = this.services.map(service => {
      const item = servicesInfo?.find(servinfo => +servinfo.sid === service.id);
      return {
        ...service,
        onlineCabs:
          item?.online_cabs !== undefined && item.busy_cabs !== undefined ? `${item.online_cabs + item.busy_cabs}` : '-'
      };
    });
  };

  private openTransferModal(permanentalOpen?: boolean): void {
    console.log('🚀 this.dialogRef', this.dialogRef);
    if (!this.telephonySharedService.incomeCalls$.value && this.telephonyService.sessions$.value &&
      !this.telephonyService.sessions$.value?.length &&
      Object.keys(this.telephonyService.sessions$.value).length) {
      this.handleEventService.openSnackBar('UTAX_OUTCOME_CALL_TRANSFER_NOT_ALLOWED');
      return;
    }
    if (!this.dialogRef) {
      const config = new MatDialogConfig();
      config.panelClass = 'transfer-call-modal-container';
      config.data = {
        queues: this.callWayService.transferQueues,
        permanentalOpen,
        transferQueueIds: this.callWayService.transferQueueIds,
        showHelpInfo: true
      };
      config.disableClose = true;
      if (permanentalOpen) {
        config.position = {
          top: '6vh',
          left: '0vw'
        };
        config.height = '94vh';
      } else {
        config.position = {
          top: '6vh',
          left: '0vw'
        };
        config.height = '94vh';
      }
      this.dialogRef = this.dialog.open(TransferQueueModalComponent, config);
      this.dialogRef
        .afterClosed()
        .pipe(takeUntil(this.componentDestroyed))
        .subscribe(data => {
          if (data && !permanentalOpen) {
            this.callWayService.callWaySocket$.next({
              cmd: 'call_transfer',
              queue: data.queue_id
            });
          }
          this.dialogRef = null;
        });
    }
  }

  private callHandeling(call): void {
    this.subCall = call;
    if (+call.queue === 17284) {
      call.queue = 15991;
    } else if (+call.queue === 19345) {
      call.queue = 13486;
    } else if (+call.queue === 11849) {
      call.queue = 16177;
    }
    const queue = this.callWayService.transferQueues.find((que: any) => String(que.mappingId) === String(call.queue));
    if (queue && queue.transferId === null) {
      this.openTransferModal(true);
    }
    if (
      queue &&
      queue.transferId !== null &&
      call &&
      !this.callWayService.transferQueueIds.some(item => item === call.queue)
    ) {
      const callPhoneNumber = this.phoneNumberService.getFormattedPhoneNumber(call.phone_number);
      const callShortPhoneNumber = this.phoneNumberService.getShortFormattedPhoneNumber(call.phone_number);
      const orders = Array.from(this.ordersService.currentOrders.values()).filter((order: Order) => (
        (order.passenger && order.passenger.phone_number === callPhoneNumber) ||
        (order?.phone_number === callPhoneNumber) ||
        (order?.product.type === 'delivery' && order?.receiver?.phone_number === callPhoneNumber) ||
        (order.cab && order.cab.driver.phone_number_list.some(phone => phone === callShortPhoneNumber))
      ));
      const isDriverCall = orders.some(order =>
        order.cab && order.cab.driver.phone_number_list.some(phone => phone === callShortPhoneNumber)
      );
      if (orders && orders.length > 0) {
        const additionalOrders = orders.map(item => item.id);
        if (this.isLiteVersion && additionalOrders.length) {
          const prevReqViewOrdLite = this.dispOrdersService.requiredViewOrdersLite$.value;
          if (!prevReqViewOrdLite.length) {
            this.dispOrdersService.requiredViewOrdersLite$.next(
              additionalOrders.map(id => ({requestId: id}))
            );
          } else {
            additionalOrders.forEach(id => {
              if (!prevReqViewOrdLite.some(req => req.requestId === id)) {
                prevReqViewOrdLite.push({requestId: id});
              }
            });
            this.dispOrdersService.requiredViewOrdersLite$.next(prevReqViewOrdLite);
          }
        }
        this.dispOrdersService.setCallWayOrderIds(additionalOrders);
        this.dispOrdersService.selectOrder(this.getOrderToSelect(orders, isDriverCall), true);
      } else {
        this.dispOrdersService.setCallWayOrderIds([]);
        if (call.call_type !== 'INCOMING_FROM_COMPANY_DRIVERS') {
          this.orderModalService.createOrder(call, queue);
        }
      }
    } else if (this.callWayService.transferQueueIds.some(item => item === call.queue)) {
      // first(transfer) call
      this.openTransferModal();
    }
  }

  private getOrderToSelect(orders: OrderClass[], isDriver: boolean): OrderClass {
    if (isDriver) {
      orders.sort((a, b) => {
        return moment(b.sending_at).diff(a.sending_at);
      });
    } else {
      // pass call
      orders.sort((a, b) => {
        return moment(b.created_at).diff(a.created_at);
      });
    }
    return orders[0];
  }
  public shortcutHelper(): void {
    this.shortcuts$ = this.globalDataService.isNewKeyboardLayout$
      .pipe(
        switchMap(isNewKeyboardLayout => {
          if (isNewKeyboardLayout) {
            return of([
              {
                key: ['ins'],
                command: () => {
                  this.openCreateOrederModal();
                },
                preventDefault: true
              },
              {
                key: ['space'],
                command: () => {
                  this.dispOrdersService.holdUnholdOrder();
                },
                preventDefault: true
              },
              {
                key: ['f7'],
                command: () => {
                  this.openTransferModal(true);
                },
                preventDefault: true,
                allowIn: [AllowIn.Textarea, AllowIn.Input, AllowIn.Select]
              }
            ]).pipe(
              map(shortcuts => {
                if (this.isLiteVersion) {
                  return [...shortcuts, {
                    key: ['esc'],
                    command: () => {
                      this.clearFilter();
                    },
                    preventDefault: true,
                    allowIn: [AllowIn.Input, AllowIn.Select]
                  },
                    {
                      key: ['f8'],
                      command: () => {
                        this.searchFilter.nativeElement.focus();
                      },
                      preventDefault: true
                    }];
                } else {
                  return shortcuts;
                }
              })
            );
          } else {
            return of([
              {
                key: ['ins'],
                command: () => {
                  this.openCreateOrederModal();
                },
                preventDefault: true
              },
              {
                key: ['space'],
                command: () => {
                  this.dispOrdersService.holdUnholdOrder();
                },
                preventDefault: true
              },
              {
                key: ['esc'],
                command: () => {
                  if (this.dispOrdersService.callWayOrderIds.length > 0) {
                    this.dispOrdersService.clearCallWayOrders();
                  }
                },
                preventDefault: true
              },
              {
                key: ['f7'],
                command: () => {
                  this.openTransferModal(true);
                },
                preventDefault: true,
                allowIn: [AllowIn.Textarea, AllowIn.Input, AllowIn.Select]
              }
            ]).pipe(
              map(shortcuts => {
                if (this.isLiteVersion) {
                  return [...shortcuts, {
                    key: ['f8'],
                    command: () => {
                      this.clearFilter();
                    },
                    preventDefault: true,
                    allowIn: [AllowIn.Input, AllowIn.Select]
                  }];
                } else {
                  return shortcuts;
                }
              })
            );
          }
        })
      );
  }

  searchInput() {
    if (this.isLiteVersion) {
      this.dispOrdersService.filterLiteSearchStr$.next(this.searchLiteVersionInput);
    }
  }

  clearFilter() {
    this.searchLiteVersionInput = '';
    this.dispOrdersService.filterLiteSearchStr$.next(this.searchLiteVersionInput);
  }

  openNewTabLink(link: string) {
    window.open(link, '_blank');
  }
}
