import { DispatcherComponent } from '../dispatcher/containers';
import { ArchiveComponent } from '../archive/containers';
import { AuthentificationComponent } from '../auth/containers';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth.guard';
import { RemoteWorkGuard } from './remote-work.guard';
import { LogistComponent } from '../logist/containers';
import { CabinetBalanceComponent, CabinetComponent, CabinetMainComponent } from '../cabinet/containers';
import { IsLoginGuard } from './is-login.guard';
import {PassengersGuard} from "../guards/passengers.guard";
import {UsersGuard} from "../guards/users.guard";
import {SupportGuard} from "../guards/support.guard";
import {SupportSettingGuard} from "../guards/support-setting.guard";
import {SupportManagementGuard} from "../guards/support-management.guard";
import {SupportWorkGuard} from "../guards/support-work.guard";

const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full',
  },
  {
    path: 'autocolumn',
    loadChildren: () => import('./../autocolumn/autocolumn.module').then(m => m.AutocolumnModule),
    canActivate: [AuthGuard, RemoteWorkGuard]
  },
  {
    path: 'logist',
    component: LogistComponent,
    canActivate: [AuthGuard, RemoteWorkGuard]
  },
  {
    path: 'archive',
    component: ArchiveComponent,
    canActivate: [AuthGuard, RemoteWorkGuard]
  },
  {
    path: 'dashboard',
    component: DispatcherComponent,
    canActivate: [AuthGuard, RemoteWorkGuard]
  },
  {
    path: 'dashboard-no-gzip',
    component: DispatcherComponent,
    canActivate: [AuthGuard, RemoteWorkGuard]
  },
  {
    path: 'driver-tariffs',
    loadChildren: () => import('./../driver-tariffs/driver-tariffs.module').then(m => m.DriverTariffsModule),
    canActivate: [AuthGuard, RemoteWorkGuard]
  },
  {
    path: 'debts',
    loadChildren: () => import('./../debt-passenger/debt-passenger.module').then(m => m.DebtPassengerModule),
    canActivate: [AuthGuard, RemoteWorkGuard]
  },
  {
    path: 'passengers',
    loadChildren: () => import('./../passengers/passengers.module').then(m => m.PassengersModule),
    canActivate: [AuthGuard, RemoteWorkGuard, PassengersGuard]
  },
  {
    path: 'call-centre',
    loadChildren: () => import('./../call-centre/call-centre.module').then(m => m.CallCentreModule),
    canActivate: [AuthGuard, RemoteWorkGuard]
  },
  {
    path: 'users',
    loadChildren: () => import('../users/users-page.module').then(m => m.UsersPageModule),
    canActivate: [AuthGuard, RemoteWorkGuard, UsersGuard]
  },
  {
    path: 'configuration',
    loadChildren: () => import('../configuration/configuration.module').then(m => m.ConfigurationModule),
    canActivate: [AuthGuard, RemoteWorkGuard]
  },
  {
    path: 'car-settings',
    loadChildren: () => import('../car-settings/car-settings.module').then(m => m.CarSettingsModule),
    canActivate: [AuthGuard, RemoteWorkGuard]
  },
  {
    path: 'support',
    loadChildren: () => import('../support/work/support.module').then(m => m.SupportModule),
    canActivate: [AuthGuard, RemoteWorkGuard, SupportWorkGuard]
  },
  {
    path: 'support-setting',
    loadChildren: () => import('../support/setting/support-setting.module').then(m => m.SupportSettingModule),
    canActivate: [AuthGuard, RemoteWorkGuard, SupportSettingGuard]
  },
  {
    path: 'support-management',
    loadChildren: () => import('../support/management/management.module').then(m => m.ManagementModule),
    canActivate: [AuthGuard, RemoteWorkGuard, SupportManagementGuard]
  },
  {
    path: 'rebinding',
    loadChildren: () => import('../rebinding/rebinding.module').then(m => m.RebindingModule),
    canActivate: [AuthGuard, RemoteWorkGuard]
  },
  {
    path: 'cabinet',
    component: CabinetComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: CabinetMainComponent,
        pathMatch: 'full',
      },
      {
        path: 'balance',
        component: CabinetBalanceComponent,
        pathMatch: 'full',
      },
    ],
  },
  {
    path: 'login',
    canActivate: [IsLoginGuard],
    component: AuthentificationComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true, relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
