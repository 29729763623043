import {ChangeDetectionStrategy, Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {finalize, tap} from "rxjs/operators";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {DispSettingsApiService} from "../../services";

@UntilDestroy()
@Component({
  selector: 'utax-disp-password-reset-dialog',
  templateUrl: './disp-password-reset-dialog.component.html',
  styleUrls: ['./disp-password-reset-dialog.component.scss']
})
export class DispPasswordResetDialogComponent implements OnInit {
  public disabledApproveButton: boolean;


  constructor(
    public dialogSelf: MatDialogRef<DispPasswordResetDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      phone_number: string;
      id: string;
    },
    private dispSettingsApiService: DispSettingsApiService,
  ) { }

  ngOnInit(): void {
  }

  approve() {
    this.disabledApproveButton = true;
    this.dispSettingsApiService.resetDispPassword(this.data.id).pipe(
      tap(() => this.dialogSelf.close(true)),
      finalize(() => {
        this.disabledApproveButton = false;
      }),
      untilDestroyed(this)
    )
      .subscribe();
  }

}
