import { Component, OnInit, ViewChild, TemplateRef, AfterViewInit, OnDestroy } from '@angular/core';
import { HeaderToolsHandlerService } from 'src/app/navigation/services';
import { UsersService } from '../../services';
import {forkJoin, Subject, of, Observable} from 'rxjs';
import {Role, User, UserHistoryInterface} from 'src/app/users/models/user.interface';
import { Service } from 'src/app/interfaces/order.interface';
import { MatDialogRef, MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { UserDialogComponent } from '../../components';
import {takeUntil, mergeMap, pluck, tap, finalize} from 'rxjs/operators';
import { YesNoModalComponent } from 'src/app/shared/components';
import { ShortcutInput } from 'ng-keyboard-shortcuts';
import {HandleEventService} from "@global-services/handle-event-service.service";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {StateService} from "@global-services/state.service";
import {TranslateService} from "@ngx-translate/core";
import {GlobalDataService} from "@global-services/global-data.service";

@UntilDestroy()
@Component({
  selector: 'utax-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('controlMenu') controlMenu: TemplateRef<any>;

  roles: Role[];
  users: User[];
  services: Service[];
  selectedUser: User;
  public usersHistory: UserHistoryInterface[];
  public isPermission = false;
  public isUsersAllowEdit = false;
  public isUsersViewHistory = false;
  public mirandaServices: Service[];
  public page = 1;
  public lastPage = 10;

  shortcuts: ShortcutInput[] = [];
  dialogRef: MatDialogRef<UserDialogComponent | YesNoModalComponent>;
  private componentDestroyed$ = new Subject();
  constructor(
    private usersService: UsersService,
    private headerToolsHandlerService: HeaderToolsHandlerService,
    private dialog: MatDialog,
    private stateService: StateService,
    private globalDataService: GlobalDataService,
    private translateService: TranslateService,
    private handleEventService: HandleEventService
  ) {
    const reqs = [];
    reqs.push(this.usersService.getListOfRoles());
    reqs.push(this.usersService.getListOfUsers());
    reqs.push(this.usersService.getListOfServices());

    // @ts-ignore
    forkJoin(reqs).subscribe(([roles, users, services]) => {
      this.roles = roles as Role[];
      this.users = users as User[];
      this.services = services as Service[];
    });
  }

  ngOnInit(): void {
    this.stateService.getStoreParamSub('permissions').pipe(
      tap(permissions => this.isPermission = !!permissions.length),
      tap(permissions => {
        this.isUsersAllowEdit = permissions.some(permission => permission.name === 'operator_users_update');
        this.isUsersViewHistory = permissions.some(permission => permission.name === 'operator_users_history_view');
      }),
      untilDestroyed(this)
    ).subscribe();
    this.globalDataService.globalMirandaTaxiServices$
      .pipe(
        tap((services) => this.mirandaServices = services),
        untilDestroyed(this))
      .subscribe();
  }

  ngAfterViewInit(): void {
    this.headerToolsHandlerService.template.next({
      controlMenu: this.controlMenu,
      source: 'users'
    });
    this.shortcuts.push(
      {
        key: ['del'],
        command: () => {
          this.deleteUser();
        },
        preventDefault: true
      },
      {
        key: ['ins'],
        command: () => {
          this.createUser();
        },
        preventDefault: true
      },
      {
        key: ['home'],
        command: () => {
          this.editUser();
        },
        preventDefault: true
      }
    );
  }

  selecteUserEvent(event: User): void {
    if (this.selectedUser?.id !== event?.id && this.isUsersViewHistory) {
      this.usersService.loadingHistoryUser$.next(true);
      this.usersService.getHistoryOfUser(event.id, 1)
        .pipe(
          tap((obj) => {
            this.page = obj.meta.current_page;
            this.lastPage = obj.meta.last_page;
          }),
          pluck('data'),
          finalize(() => this.usersService.loadingHistoryUser$.next(false)),
          untilDestroyed(this)
        ).subscribe((data: UserHistoryInterface[]) => {
        this.usersHistory = this.convertToUserHistory(data);
        this.usersService.scrollToTop$.next(null);
      });
    }
    this.selectedUser = event;
    if (!this.selectedUser) {
      this.usersHistory = [];
    }
  }

  deleteUser(): void {
    if (this.selectedUser) {
      const config = new MatDialogConfig();
      config.data = {
        title: `UTAX_FRONT_DELETE_USER`,
        extra: this.selectedUser.name
      };
      config.panelClass = 'yes-no-modal-container';

      this.dialogRef = this.dialog.open(YesNoModalComponent, config);
      this.dialogRef
        .afterClosed()
        .pipe(mergeMap(data => (data === 'YES' ? this.usersService.deleteUser(this.selectedUser.id) : of())))
        .subscribe((res: any) => {
          if (res) {
            this.users = [...this.users.filter((item: User) => item.id !== this.selectedUser.id)];
            this.selectedUser = this.users[0];
          }
          this.dialogRef = null;
        });
    }
  }

  createUser(): void {
    const config = new MatDialogConfig();
    config.autoFocus = false;
    config.disableClose = true;
    config.width = '824px';
    config.backdropClass = 'request-dialog-backdrop';
    config.panelClass = 'request-reject-dialog-container';
    config.data = {
      roles: this.roles,
      services: this.services
    };
    this.openUserDialog(config);
  }

  editUser(): void {
    if (this.selectedUser && this.isUsersAllowEdit) {
      const config = new MatDialogConfig();
      config.autoFocus = false;
      config.disableClose = true;
      config.width = '824px';
      config.backdropClass = 'request-dialog-backdrop';
      config.panelClass = 'request-reject-dialog-container';
      config.data = {
        roles: this.roles,
        services: this.services,
        user: this.selectedUser
      };
      this.openUserDialog(config);
    }
  }

  private openUserDialog(config): void {
    this.dialogRef = this.dialog.open(UserDialogComponent, config);
    this.dialogRef
      .afterClosed()
      .pipe(takeUntil(this.componentDestroyed$), mergeMap(this.getUsersListAfterDialogCloseFunc))
      .subscribe(this.closeUserDialogSubFunc);
  }

  private closeUserDialogSubFunc = (users: User[]) => {
    if (users) {
      this.users = [...users];
      this.selectedUser = this.users[0];
      this.usersHistory = [];
    }
    this.dialogRef = null;
  };

  private getUsersListAfterDialogCloseFunc = res => {
    if (res) {
      this.handleEventService.openSnackBar(
        res.action === 'update' ? 'UTAX_FRONT_USER_UPDATED_SUCCESS' : 'UTAX_FRONT_USER_CREATED_SUCCESS');
      return this.usersService.getListOfUsers();
    } else {
      return of(false);
    }
  };

  private convertToUserHistory(data: UserHistoryInterface[]): UserHistoryInterface[] {
    return data.map((item: UserHistoryInterface) => {
      if (item.changes) {
        item.changes = item.changes.map((chan: any) => {
          if (chan?.key === 'roles' && chan?.type === 'dictionary') {
             chan.values = chan.values.map((val: any) => {
              if (val?.a) {
                val.a = this.roles.find((role: Role) => role.id === val.a)?.display_name;
              }
              if (val?.b) {
                val.b = this.roles.find((role: Role) => role.id === val.b)?.display_name;
              }
              return val;
            });
          }
          return chan;
        });
      } else if (item?.additional) {
        if (item.additional?.service_id) {
          const serviceFull = this.mirandaServices.find((service: Service) => service.id === item.additional.service_id);
          item.additional.service_id = serviceFull ? `${serviceFull?.name} - ${this.translateService.instant(serviceFull?.city?.name)}` : '';
        } else if (item.additional?.service_ids) {
          item.additional.service_ids = item.additional.service_ids.map((serviceId: number) => {
            const serviceFull = this.mirandaServices.find((service: Service) => service.id === serviceId);
            if (serviceFull) {
              return `${serviceFull?.name} - ${this.translateService.instant(serviceFull?.city?.name)}`;
            } else {
              return '';
            }
          });
          item.additional.service_ids = item.additional.service_ids.join(', ');
        } else if (item.additional?.roles) {
          item.additional.roles = item.additional.roles.map((roleId: string) => {
            const roleFull = this.roles.find((role: Role) => role.id === roleId);
            return `${this.translateService.instant(roleFull?.display_name ? roleFull.display_name : 'UTAX_ROLE_DELETED')}`;
          });
          item.additional.roles = item.additional.roles.join(', ');
        }
      }
      return item;
    });
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.unsubscribe();
  }

  getNextPage($event: number) {
    if (this.lastPage > this.page) {
      this.usersService.loadingHistoryUser$.next(true);
      this.usersService.getHistoryOfUser(this.selectedUser.id, this.page + $event)
        .pipe(
          tap((obj) => {
            this.page = obj.meta.current_page;
            this.lastPage = obj.meta.last_page;
          }),
          pluck('data'),
          finalize(() => this.usersService.loadingHistoryUser$.next(false)),
          untilDestroyed(this)
        ).subscribe((data: UserHistoryInterface[]) => {
        this.usersHistory = this.usersHistory.concat(this.convertToUserHistory(data));
      });
    }
  }
}
