import {Component, OnInit, OnDestroy, HostListener, Renderer2, Inject} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {TimezoneService, StateService, TaxiServicesService, WebsocketService, HandleEventService} from './services';
import { uuidv4 } from './utils/uuid';
import { AuthService } from './auth/services';
import * as moment from 'moment';
import { RemoteWorkService } from './cabinet/services';
import {catchError, filter, finalize, first, map, pluck, switchMap, switchMapTo, takeUntil, tap} from 'rxjs/operators';
import { RemoteWorkOperatorStatus } from './cabinet/models';
import {ActivatedRoute, Router} from '@angular/router';
import { TelephonySharedService } from './telephony/services/telephony-shared.service';
import { TelephonyService } from './telephony/services';
import {environment} from '../environments/environment';
import {BalanceService} from './services/balance.service';
import {GlobalDataService} from '@global-services/global-data.service';
import {forkJoin, interval, Observable, of, pipe, throwError, timer} from 'rxjs';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {ChristmasService} from "@global-services/christmas.service";
import {LocalStorageService} from "ngx-webstorage";
import {DOCUMENT} from "@angular/common";
import {AnimationOptions} from "ngx-lottie";
import {OctobusService} from "./services/octobus.service";

@UntilDestroy()
@Component({
  selector: 'utax-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'utax-front';
  private callCenterPingInterval: Observable<number> = interval(60000);
  options: AnimationOptions = {
    path: '/assets/lottie/egg.json'
  };


  constructor(
    private translate: TranslateService,
    private tzService: TimezoneService,
    private globalDataService: GlobalDataService,
    private authService: AuthService,
    private octobusService: OctobusService,
    private remoteWorkService: RemoteWorkService,
    private stateService: StateService,
    private taxiServicesService: TaxiServicesService,
    private telephonyService: TelephonyService,
    private telephonySharedService: TelephonySharedService,
    private websocketService: WebsocketService,
    private router: Router,
    private balanceService: BalanceService,
    private renderer2: Renderer2,
    @Inject(DOCUMENT) private documentAng: Document,
    private christmasService: ChristmasService,
    private localStorageService: LocalStorageService,
  ) {
    translate.addLangs(['ua']);
    translate.setDefaultLang('ua');
    moment.locale('ua');
    this.stateService.sessionId = uuidv4();
    if (window.location.href.includes('-no-gzip')) {
      this.websocketService.enableGzip = 0;
    } else {
      this.websocketService.enableGzip = 1;
    }
  }

  ngOnInit() {
    this.christmasService.isEaster = !!this.localStorageService.retrieve('isEaster');
    this.getGlobalState();
    this.updateSystemSettingGlobal();
    /* if (
       moment().diff(moment(localStorage.getItem('lastLogin')), 'seconds') > 10
     ) {
       this.authService.logout();
       return;
     }*/
    this.authService.authState
      .pipe(
        filter(state => state),
        switchMapTo(this.remoteWorkService.remoteWorkState$),
        filter(data => Boolean(data))
      )
      .subscribe(data => {
        if (data.status === RemoteWorkOperatorStatus.ready) {
          this.tzService.loadTimezones();
          this.taxiServicesService.createSubscription();
          environment.liteVersion && this.balanceService.createSubscription();
          const mirandaConnection = this.websocketService.openConnection('miranda');
          mirandaConnection.on('remote_work_finished')
            .pipe(
              switchMapTo(this.telephonySharedService.incomeCalls$),
              filter(calls => !calls)
            )
            .subscribe(msg => {
              this.checkSessionsWhenWorkFinished();
            });
        }
      });

    this.authService.authState.subscribe(val => {
      if (val) {
        forkJoin([
          this.globalDataService.getBrigades(),
          this.globalDataService.getCategories(),
          this.globalDataService.getRoles(),
          this.globalDataService.getCallLines(),
          this.globalDataService.getTaxiServices(),
          this.globalDataService.getQueueStrategies(),
          this.globalDataService.getTransferList(),
          this.globalDataService.getActiveConversation(),
        ]).subscribe(([brigades, categories, roles, callLines, taxiServices, queueStrategies, transferList, callConversationList]) => {
          this.globalDataService.globalBrigades$.next(brigades);
          this.globalDataService.globalCategories$.next(categories);
          this.globalDataService.globalRoles$.next(roles);
          callLines = callLines.map(line => {
            line.type = `INCOMING_FROM_${line.type === 'PASSENGERS' ? 'PASSENGER' : line.type}`;
            return line;
          });
          this.globalDataService.globalCallLines$.next(callLines);
          this.globalDataService.globalTaxiServices$.next(taxiServices);
          this.globalDataService.globalQueueStrategies$.next(queueStrategies);
          this.globalDataService.globalTransferList$.next(transferList);
          this.globalDataService.callConversationList$.next(callConversationList);
        });
        this.globalDataService.getMirandaTaxiServices()
          .pipe(
            tap((mirandaTaxiServices) => this.globalDataService.globalMirandaTaxiServices$.next(mirandaTaxiServices)),
            untilDestroyed(this)
          )
          .subscribe();
        if (!(localStorage.getItem('callCenterActive'))) {
          this.callCenterPingInterval.pipe(untilDestroyed(this)).subscribe(() => {
            this.globalDataService.pingCallCenter()
              .pipe(
                first(),
                untilDestroyed(this)
              )
              .subscribe((res) => {
                if (res.status === 204) {
                  console.log('Call center is active - PING');
                } else {
                  console.log('Call center is inactive - PING ERROR');
                }
              });
          });
        }
      }
    });

  }

  private checkSessionsWhenWorkFinished(): void {
    this.telephonyService.sessions$.subscribe(sessions => {
      if (
        (Array.isArray(sessions) && sessions.length === 0) ||
        !sessions ||
        Object.keys(sessions).length == 0
      ) {
        this.router.navigateByUrl('cabinet').then(() => location.reload());
      }
    });
  }
  private getGlobalState() {
    this.authService.authState
      .pipe(
        switchMap(val => val ? this.globalDataService.getGlobalState() : of(null)),
        untilDestroyed(this)
      ).subscribe( value => {
        if (value) {
          const parsedValue = JSON.parse(value.state);
          this.globalDataService.globalState$.next(parsedValue);
          this.globalDataService.isNewKeyboardLayout$.next(parsedValue?.keyboardSet === 'new');
          this.globalDataService.isShowBalanceInDisp$.next(parsedValue?.showBalanceInDisp !== 'hide');
        }
    });
    this.getOctobusToken();
  }

  private getOctobusToken() {
    this.authService.octobusUpdateSubject$.asObservable()
      .pipe(
        switchMap(() => {
          return this.stateService.getStoreParamSub('permissions').pipe(
            map((permissions) => {
              return permissions.some(p => p.name === 'operator_requests_view_chat');
            }
          ));
        }),
        switchMap((isAllow) => {
          if (!isAllow) {
            return of(true);
          } else if (localStorage.getItem('octobusToken')) {
            return this.octobusService.octobusCheck(this.localStorageService.retrieve('octobusToken')?.token)
              .pipe(
              switchMap(res => of(true)),
              catchError((err) => {
                if (err.status === 401) {
                  return this.octobusService.octobusRefresh(this.localStorageService.retrieve('octobusToken'))
                    .pipe(
                      tap(res => {
                        this.localStorageService.store('octobusToken', res);
                      }));
                } else if (err.status === 0 || err.status === 400) {
                  return this.octobusService.octobusRefresh(this.localStorageService.retrieve('octobusToken'))
                    .pipe(
                      tap(res => {
                        this.localStorageService.store('octobusToken', res);
                      }));
                } else {
                  return throwError(err);
                }
              })
              );
          } else {
            return of(false);
          }
        }),
        switchMap((isActive) => {
          if (isActive) {
            return of(isActive);
          } else {
            return this.octobusService.octobusLogin().pipe(
              tap(res => {
                this.localStorageService.store('octobusToken', res);
              })
            );
          }
        }),
        untilDestroyed(this)
      )
      .subscribe();
  }


  @HostListener('window:beforeunload', ['$event'])
  ngOnDestroy() {
    localStorage.setItem('lastLogin', moment().format());
  }

  private updateSystemSettingGlobal() {

    this.stateService.store.pipe(
      filter(state => state && state.systemSetting),
      pluck('systemSetting'),
      tap((state) => {
        if(state?.themes_easter_enabled === '1') {
          this.christmasService.isEaster = true;
          this.localStorageService.store('isEaster', true);
        } else {
          this.christmasService.isEaster = false;
          this.localStorageService.clear('isEaster');
        }
        if (state?.themes_valentines_day_enabled === '1') {
          this.christmasService.isValentine = true;
          this.activateScriptValentine();
          this.valentineInit();
        } else {
          this.christmasService.isValentine = false;
        }
      }),
      untilDestroyed(this)
    ).subscribe();
  }

  private activateScriptValentine() {
    if (!this.documentAng.getElementById('valentineDay')) {
      const script = this.renderer2.createElement('script');
      script.type = `text/javascript`;
      script.id = `valentineDay`;
      script.text = `
      const valentine = new Snowflakes({
      count: 15,
      wind: false,
      minOpacity: 0.3,
      maxOpacity: 0.9,
      speed: 0.7,
      minSize: 20,
      maxSize: 30,
      rotation: false
      });
        `;
      this.renderer2.appendChild(this.documentAng.body, script);
    }
  }

  private valentineInit() {
    const mood = this.localStorageService.retrieve('valentineMood');
    this.christmasService.toggleSnow = !mood || mood === 'active';
    const ev = new Event('click');
    const el = document.getElementById(mood === 'inactive' ? 'valentineHide' : 'valentineShow');
    if (el) {
      el.dispatchEvent(ev);
    }
    this.localStorageService.observe('valentineMood')
      .pipe(
        tap((value) => {
          this.christmasService.toggleSnow = !value || value === 'active';
          if (value === 'active' || !value) {
            const event = new Event('click');
            const elem = document.getElementById('valentineShow');
            if (elem) {
              elem.dispatchEvent(event);
            }
          } else if (value === 'inactive') {
            const event = new Event('click');
            const elem = document.getElementById('valentineHide');
            if (elem) {
              elem.dispatchEvent(event);
            }
          }
        }),
        untilDestroyed(this)
      )
      .subscribe();
  }
}
