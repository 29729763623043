import { Router, Event, NavigationStart } from '@angular/router';
import { BehaviorSubject, Subject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class HeaderToolsHandlerService {
  template: Subject<any> = new Subject();
  templateBalance: Subject<any> = new Subject();
  public globalLanguage$ = new BehaviorSubject('ru');

  constructor(private router: Router) {
    router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
        // if goes in autocolumn do not clean template
        this.templateBalance.next(null);
        if (event.url.split('/')[1] !== 'autocolumn') {
          this.template.next(null);
        }
      }
    });
  }
}
