import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import * as fromContainers from './containers';
import * as fromComponents from './components';
import * as fromPipes from './pipes';

import { TranslateModule } from '@ngx-translate/core';
import { MaterialModule } from 'src/app/utils/material.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { SharedModule } from 'src/app/shared/shared.module';
import { UserSearchComponent } from './components/user-search/user-search.component';
import { KeyboardShortcutsModule } from 'ng-keyboard-shortcuts';
import {NgxMatSelectSearchModule} from "ngx-mat-select-search";
import {MatTreeModule} from "@angular/material/tree";

@NgModule({
  declarations: [...fromContainers.containers, ...fromComponents.components, ...fromPipes.pipes, UserSearchComponent],
  imports: [
    CommonModule,
    TranslateModule,
    MaterialModule,
    ReactiveFormsModule,
    FormsModule,
    InfiniteScrollModule,
    SharedModule,
    KeyboardShortcutsModule,
    NgxMatSelectSearchModule,
    MatTreeModule
  ]
})
export class UsersModule {}
