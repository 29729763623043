import { Injectable } from '@angular/core';
import is_ip_private from 'private-ip';
import {catchError, switchMap, tap} from 'rxjs/operators';
import {BehaviorSubject, of} from 'rxjs';
import {fromFetch} from 'rxjs/fetch';
import {fromPromise} from "rxjs/internal/observable/innerFrom";


@Injectable({
  providedIn: 'root'
})
export class WebRtcLeakService {

  public isLeaked$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private ip: string;
  private publicIp: string;
  private pending = false;
  private dataChannel: RTCDataChannel;
  private pc = new RTCPeerConnection({
    iceServers: [{urls: 'stun:stun.l.google.com:19302'}]
  });

  constructor() { }

  public checkRTCLeak(callback?: any) {
    if (this.pending) {
      return;
    }
    this.pending = true;
    console.log('😝 Почнемо перевірку на витік WebRTC. Це може зайняти декілька секунд.');

    if (!window.RTCPeerConnection) {
      console.log('‼️ ВебРТЦ не підтримується.');
      return;
    }

    if (this.dataChannel) {
      switch (this.dataChannel.readyState) {
        case 'connecting':
          this.checkRTCLeak();
          break;
        case 'open':
          break;
        case 'closing':
          this.checkRTCLeak();
          break;
        case 'closed':
          this.dataChannel = this.pc?.createDataChannel('');
          break;
      }
    } else {
      this.dataChannel = this.pc?.createDataChannel('');
    }
    this.getMyVpnOrPublicIP().pipe(
        tap(data => {
          if (data?.ip) {
            this.ip = data.ip;
            console.log(`⚫️ Ваш публічний IP: ${this.ip}.`);
          } else {
            this.ip = null;
          }
        }),
        switchMap((data: any) => {
          if (this.ip) {
            return fromPromise(this.pc.createOffer())
                .pipe(
                    switchMap(offer => {
                      console.log('✅ Е пропозиція для кандидатів.');
                      return this.pc.setLocalDescription(offer);
                    }),
                    catchError(error => {
                      console.error('❌ Нема пропозиції:', error);
                      return error;
                    })
                );
          } else {
            console.log(data.message);
            return of(data);
          }
        }
    )).subscribe(
        () => {
          this.pc.restartIce();
          this.pc.onicecandidate =  (ice) => {
            if (!ice || !ice.candidate || !ice.candidate.candidate) {
              return;
            }

            const candidate = ice.candidate.candidate;
            const parts = candidate.split(' ');
            const address = parts[4];

            // skip if address is not ip v4
            if (address.split('.').length !== 4 || is_ip_private(address)) {
              console.log('🎱 Це не та адреса що ми шукаємо');
              return;
            }

            if (address) {
              this.publicIp = address;
              this.isVPN();
              return  console.log(`🔵 Реальний IP: ${address}`);
            } else {
              this.publicIp = null;
            }
          };

          this.pc.onicegatheringstatechange = () => {
            console.log('Прилетіла зміна стану для кандидатів:', this.pc.iceGatheringState);

            if (this.pc.iceGatheringState === 'complete' && !this.isLeaked$.value) {
              this.isLeaked$.next(false);
              console.log('⚠️ Ми не змогли визначити вашу IP-адресу через WebRTC.');
            }
          };
          this.pending = false;
        });
  }

  private getMyVpnOrPublicIP() {
    return fromFetch('https://api.ipify.org?format=json')
        .pipe(
            switchMap(response => {
              if (response.ok) {
                return response.json();
              } else {
                return of({ error: true, message: `‼️ Ми не змогли отримати публічний IP: ${response.status}` });
              }
            }),
            catchError(err => {
              // console.error(err);
              this.pending = false;
              return of({ error: true, message: err.message });
            })
        );
  }

  private isVPN() {
        if (this.ip === this.publicIp) {
          console.log('✅ Немає витоку WebRTC, то можливо нема і ВПН');
          this.isLeaked$.next(false);
        } else {
          console.error('❌ Є витік WebRTC, то можливо є і ВПН');
          this.isLeaked$.next(true);
        }
  }
}
