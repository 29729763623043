import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  Optional
} from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {combineLatest, Observable, Subject} from 'rxjs';
import {distinctUntilChanged, finalize, map, takeUntil, tap} from 'rxjs/operators';
import { Service } from 'src/app/interfaces/order.interface';
import {HandleEventService, StateService} from 'src/app/services';
import { QueueGroupModel } from '../../../queue/models';
import {DispatcherModel, DispCaterogy, DispRole, IBrigade, ICardBilling} from '../../models';
import { DispSettingsApiService, DispSettingsService } from '../../services';
import { TranslateService } from '@ngx-translate/core';
import {ICallLine} from '../../../../../models/global-data.interfaces';
import {MatCheckboxChange} from '@angular/material/checkbox';
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {ClearScaleCallsModalComponent} from "../disp/clear-scale-calls-modal/clear-scale-calls-modal.component";
import {DispPasswordResetDialogComponent} from "../disp-password-reset-dialog/disp-password-reset-dialog.component";

const FIRST_CONTACT_ID = '1000050000';
const DROGOBYCH_ID = '2933999283'; // 3341312913
const TRUSKAVETS_ID = '1167282226'; // 2067800749

@UntilDestroy()
@Component({
  selector: 'utax-disp-settings-create-disp-modal',
  templateUrl: './disp-settings-create-disp-modal.component.html',
  styleUrls: ['./disp-settings-create-disp-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DispSettingsCreateDispModalComponent implements OnInit, OnDestroy {
  form: UntypedFormGroup;

  private componentDestroyed$ = new Subject();
  private accessServices: Service[] = [];
  private initialUserServices = [];
  public currentUserType: string;
  public filteredRoles: DispRole[];
  public needToUpdateServices: boolean;
  public disabledAccessibleAll = false;
  public userCards: ICardBilling[];
  public loadingResetCards = false;
  public daysOfWeek: string[] = ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY', 'SUNDAY'];
  private selectedWeekends = [];
  public canResetPassword: boolean;

  constructor(
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: {
      roles: DispRole[];
      categories: DispCaterogy[];
      brigades: IBrigade[];
      queueGroups: any[];
      callLines: ICallLine[];
      user?: DispatcherModel;
    }, // roles === groups
    public dialogSelf: MatDialogRef<DispSettingsCreateDispModalComponent>,
    public dialog: MatDialog,
    private fb: UntypedFormBuilder,
    private dispSettingsApiService: DispSettingsApiService,
    private dispSettingsService: DispSettingsService,
    private stateService: StateService,
    private translateService: TranslateService,
    private handleEventService: HandleEventService,
    private cdr: ChangeDetectorRef,
  ) {
  }

  get queueServiceGroupsFormArray(): UntypedFormArray {
    if (this.form) {
      return this.form.controls.queue_service_groups as UntypedFormArray;
    }
  }

  ngOnInit(): void {
    if (this.data.user) {
      this.dispSettingsApiService.getDispatcherCards(this.data.user.externalId)
        .pipe(
          map(cards => cards.filter(card => card.type === 'card')),
          tap(cards => this.userCards = cards),
          untilDestroyed(this)
        )
        .subscribe();
    }
    this.stateService.store
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(store => {
        this.accessServices = [];
        this.accessServices = store.service.slice();
        const findDrogobych = store.service.find(ser => String(ser.id) === DROGOBYCH_ID);
        const findTruskavets = store.service.find(ser => String(ser.id) === TRUSKAVETS_ID);
        this.currentUserType = store.user.data.type;
        if (findDrogobych && !findTruskavets) {
          // If we find Drogobych we can to add Truskavets
          this.accessServices.push({id: +TRUSKAVETS_ID});
        }
        this.filterRoles();
        this.generateForm();
      });

    this.form.get('email').valueChanges
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(value => {
        if (value) {
          // tslint:disable-next-line:max-line-length
          const reg = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
          if (!reg.test(value)) {
            this.form.get('email').setErrors({'incorrect': true});
          }
        } else {
          this.form.get('email').setErrors(null);
        }
      });
    this.form.get('firstName').valueChanges
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(value => {
        const regSpaces = /^\S.*$/;
        const reg = /^[0-9-A-Za-zа-яА-ЯїЇєЄґҐіІ_'ʼ\-\s]+$/;
        (!reg.test(value)) ?
          this.form.get('firstName').setErrors({'incorrect': true}) :
          (!regSpaces.test(value)) ?
            this.form.get('firstName').setErrors({'incorrect': true}) :
            this.form.get('firstName').setErrors(null);
      });
    this.form.get('lastName').valueChanges
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(value => {
        const regSpaces = /^\S.*$/;
        const reg = /^[0-9-A-Za-zа-яА-ЯїЇєЄґҐіІ_'ʼ\-\s]+$/;
        (!reg.test(value)) ?
          this.form.get('lastName').setErrors({'incorrect': true}) :
          (!regSpaces.test(value)) ?
            this.form.get('lastName').setErrors({'incorrect': true}) :
            this.form.get('lastName').setErrors(null);
      });
    this.form.get('middleName').valueChanges
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(value => {
        const regSpaces = /^\S.*$/;
        const reg = /^[0-9-A-Za-zа-яА-ЯїЇєЄґҐіІ_'ʼ\-\s]+$/;
        (!reg.test(value)) ?
          this.form.get('middleName').setErrors({'incorrect': true}) :
          (!regSpaces.test(value)) ?
            this.form.get('middleName').setErrors({'incorrect': true}) :
            this.form.get('middleName').setErrors(null);
      });
    this.form.get('queue_service_groups').valueChanges
      .pipe(
        distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
        tap(() => this.needToUpdateServices = true),
        takeUntil(this.componentDestroyed$)
      )
      .subscribe();
    this.stateService.getStoreParamSub('permissions').pipe(
      map((permissions) => {
          return permissions.some(p => p.name === 'operator_reset_password');
        }),
      tap((canResetPassword) => {
        this.canResetPassword = canResetPassword;
      }),
      untilDestroyed(this)
      ).subscribe();
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  checkPasswords(group: UntypedFormGroup) {
    const pass = group.get('password').value;
    const confirmPass = group.get('password_confirm').value;

    return pass === confirmPass ? null : {notSame: true};
  }

  saveClick(): void {
    const formValue = this.form.value;
    delete formValue.password_confirm;
    if (!this.data.user) {
      // creation
      delete formValue.id;
    } else {
      // editing
      delete formValue.login;
    }
    formValue.firstName = formValue.firstName.trim();
    formValue.lastName = formValue.lastName.trim();
    formValue.middleName = formValue.middleName.trim();
    delete formValue.isActiveAllServices;
    delete formValue.isAccessibleServices;
    // TODO add services to created user operator/accesses/user/services

    const allowsData = {};
    this.queueServiceGroupsFormArray.controls.forEach(control => {
      const group = control.value;
      if (this.data.callLines.map(line => line.id).indexOf(group.id) >= 0) {
        allowsData[group.id] = !!group.active;
      }
    });

    if (!this.data.user) {
      this.createUser(formValue, allowsData, []);
    } else {
      this.updateUser(formValue, allowsData);
    }
  }

  cancleClick(): void {
    this.dialogSelf.close(false);
  }

  accessibleChange(event, i): void {
    if (!event.checked && this.queueServiceGroupsFormArray.controls[i].value.active) {
      (this.queueServiceGroupsFormArray.controls[i] as UntypedFormGroup).controls.active.patchValue(false);
    }
    this.form.get('isAccessibleServices')
      .setValue(this.checkAccessibleServices());
  }

  activeChange(event, i): void {
    if (event.checked && !this.queueServiceGroupsFormArray.controls[i].value.accessible) {
      (this.queueServiceGroupsFormArray.controls[i] as UntypedFormGroup).controls.accessible.patchValue(true);
    }
    this.form.get('isActiveAllServices')
      .setValue(this.checkAllServices());
    this.form.get('isAccessibleServices')
      .setValue(this.checkAccessibleServices());
  }

  private sortServices(): void {
    const cartItemsFormArr = this.form.get('queue_service_groups') as UntypedFormArray;
    this.queueServiceGroupsFormArray.patchValue(
      cartItemsFormArr.controls.sort((a, b) => {
        if (!b.value.externalId) {
          return 1;
        }

        if (a.value.active && !b.value.active) {
          return -1;
        } else if (!a.value.active && b.value.active) {
          return 1;
        } else {
          if (a.value.accessible && !b.value.accessible) {
            return -1;
          } else if (!a.value.accessible && b.value.accessible) {
            return 1;
          } else {
            return new Intl.Collator().compare(a.value.name, b.value.name);
          }
        }
      })
    );
  }

  private getRequestServices(services: any[]): { id: number, active: boolean }[] {
    const anyServices = this.getNotAvailableServices();
    const updatedServices = [...anyServices, ...services].map(service => {
      return {
        id: service.id,
        active: service.active
      };
    });
    return updatedServices;
  }

  private getNotAvailableServices(): any[] {
    return this.initialUserServices.filter(item => {
      const finded = this.accessServices.find((service: any) => String(service.id) === item.externalId);
      if (!finded && item.externalId) {
        return item;
      }
    });
  }

  private getQueueGroupsToSave(formValue): { active: boolean; id: string, externalId: string }[] {
    return formValue.queue_service_groups
      .filter(group => {
        return this.data.callLines.map(line => line.id).indexOf(group.id) === -1;
      })
      .filter(group => group.accessible)
      .map(group => {
        return {
          id: group.id,
          externalId: group.externalId,
          active: group.active
        };
      });
  }

  private generateForm(): void {
    this.form = this.fb.group({
      id: [this.data?.user?.id ? this.data?.user?.id : ''],
      lastName: [
        this.data?.user?.lastName ? this.data.user.lastName : '',
        [Validators.required]
      ],
      firstName: [
        this.data?.user?.firstName ? this.data.user.firstName : '',
        [Validators.required]
      ],
      middleName: [
        this.data?.user?.middleName ? this.data.user.middleName : ''
      ],
      phone_number: [
        this.data?.user?.phoneNumber ? this.data.user.phoneNumber : '',
        [Validators.required, Validators.pattern(/^\+[0-9]{12}$/)]
      ],
      email: [this.data?.user?.email ? this.data.user.email : ''],
      login: [this.data?.user?.name ? this.data.user.name : '', [Validators.required]],
      group_ids: [this.data?.user?.roleIds?.length > 0 ? this.data?.user?.roleIds : '', [Validators.required]], // roles
      queue_service_groups: this.fb.array([]),
      isActiveAllServices: [false],
      isAccessibleServices: [false],
      callLineIds: [this.data?.user?.callLineIds],
      category: [this.data?.user?.categoryId ? this.data.user.categoryId : '', [Validators.required]],
      brigade: [this.data?.user?.brigadeId ? this.data.user.brigadeId : '', [Validators.required]],
      // weekends: [this.data?.user?.weekends ? this.data.user.weekends : []],
      // skipScaleResetOutWorkShift: [this.data?.user?.skipScaleResetOutWorkShift ? this.data.user.skipScaleResetOutWorkShift : false],
      skipMinCallPerDay: [this.data?.user?.skipMinCallPerDay ? this.data.user.skipMinCallPerDay : false]
    });
    this.selectedWeekends = this.data?.user?.weekends || [];

    this.addQueueFormGroup();
    this.addCustomServicesFormGroup();
    this.addPasswords();
  }

  private addPasswords(): void {
    this.form.addControl('password', this.fb.control('', this.data.user ? [] : [Validators.required]));
    this.form.addControl('password_confirm', this.fb.control('', this.data.user ? [] : [Validators.required]));
    this.form.validator = this.checkPasswords;
  }

  private addCustomServicesFormGroup(): void {
   this.data.callLines.forEach(line => {
      (this.form.controls.queue_service_groups as UntypedFormArray).controls.unshift(
        this.fb.group({
          id: [line.id],
          name: [this.translateService.instant('CALL_RULE_' + line.type)],
          // name: [this.translateService.instant('CALL_RULE_')],
          externalId: [null],
          index: [0],
          active: [this.data.user?.callLineIds?.includes(line.id)],
          accessible: [true],
          accessibleDisabled: [true],
          type: [line.type]
        })
      );
    });
  }

  private addQueueFormGroup(): void {
    this.data.queueGroups.forEach((queue, i) => {
      const userQueue = (this.data?.user?.taxiServiceIds || []).find(uQueue => uQueue === queue.id);
      let foundService: QueueGroupModel | Service = this.accessServices.find(service => String(service.id) === queue.externalId);
      if (queue && !queue.externalId) {
        foundService = queue;
      }

      if (foundService) {
        (this.form.controls.queue_service_groups as UntypedFormArray).push(
          this.fb.group({
            id: [queue.id],
            name: [queue.displayName],
            externalId: [queue.externalId],
            index: [i + 2],
            active: [Boolean(userQueue && this.data?.user?.activeTaxiServiceIds?.includes(userQueue))],
            accessible: [Boolean(userQueue)]
          })
        );
      }

      if (Boolean(userQueue)) {
        this.initialUserServices.push(this.data.queueGroups.find(q => q.id === userQueue));
      }
    });
    this.sortServices();
    this.form.get('isActiveAllServices')
      .setValue(this.queueServiceGroupsFormArray.controls.some((control: UntypedFormGroup) => {
        return control.get('active').value &&
          (control.get('externalId').value ||
          this.data.queueGroups.find(q => q.id === control.get('id').value)?.parentId);
      }));
    this.form.get('isAccessibleServices').setValue(this.checkAccessibleServices());
  }

  // tslint:disable-next-line:variable-name
  private createUser(formValue, allowsData, queue_service_groups): void {
    const newUser = this.getDataToRequest(
      {
        ...formValue,
        ...allowsData,
        queue_service_groups
      },
      'create'
    );
    this.dispSettingsApiService.createDisp(newUser)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(user => {
        this.data.user = user;
        this.dispSettingsService.allDispatchers$.next([
          ...this.dispSettingsService.allDispatchers$.value,
          user
        ]);
        this.updateUser(formValue, allowsData);
      });
  }

  private getDataToRequest(data, mode: string): any {
    const response = {
      firstName: data.firstName,
      lastName: data.lastName,
      middleName: data.middleName,
      phoneNumber: data.phone_number,
      email: data.email || null,
      brigadeId: data.brigade,
      categoryId: data.category,
      roleIds: data.group_ids,
      callLineIds: this.getCallLines(data),
      taxiServiceIds: data.queue_service_groups && data.queue_service_groups.map(item => item.id) || [],
      activeTaxiServiceIds: data.queue_service_groups && data.queue_service_groups.filter(item => item.active).map(item => item.id) || [],
      // weekends: data.weekends,
      // skipScaleResetOutWorkShift: data.skipScaleResetOutWorkShift,
      skipMinCallPerDay: data.skipMinCallPerDay
    };
    if (mode === 'create') {
      response['name'] = data.login;
      response['password'] = data.password;
    } else if (mode === 'update') {
      response['id'] = data.id;
      if (data.password) {
        response['password'] = data.password;
      }
    }

    return response;
  }

  private getCallLines(data): string[] {
    const response = [];
    const callLines = this.data.callLines;
    for (const k in data) {
      callLines.forEach(line => {
        if (line.id === k && data[k]) {
          response.push(line.id);
        }
      });
    }
    return response;
  }

  private updateUser(formValue, allowsData): void {
    const services = this.getQueueGroupsToSave(formValue);
    const activeServices = services.filter(service => service.active).map(service => service.externalId);
    const inactiveServices = [];
    this.accessServices.forEach(accessService => {
      const findedService = services.find(service => service.externalId === String(accessService.id));
      if (!findedService) {
        inactiveServices.push(String(accessService.id));
      } else {
        if (!findedService.active) {
          inactiveServices.push(String(findedService.externalId));
        }
      }
    });

    if (this.needToUpdateServices) {
      combineLatest([
        this.dispSettingsApiService.addMultiQueueGroup(
          this.data?.user?.externalId,
          activeServices.filter(s => (+s !== +FIRST_CONTACT_ID && +s !== +TRUSKAVETS_ID && +s))
        ),
        this.dispSettingsApiService.deleteMultiQueueGroup(
          this.data?.user?.externalId,
          inactiveServices.filter(s => (+s !== +FIRST_CONTACT_ID && +s !== +TRUSKAVETS_ID && +s))
        ),
      ])
        .pipe(takeUntil(this.componentDestroyed$))
        .subscribe(
          ([added, removed]) => {
            this.dialogSelf.close(this.getDataToRequest(
              {
                ...formValue,
                ...allowsData,
                queue_service_groups: this.getRequestServices(services),
                id: this.data?.user?.id
              },
              'update'));
          },
          error => {
            this.initialUserServices = [];
            this.form.removeControl('queue_service_groups');
            this.form.addControl('queue_service_groups', this.fb.array([]));
            this.queueServiceGroupsFormArray.patchValue([]);
            this.addCustomServicesFormGroup();
            this.addQueueFormGroup();
          }
        );
    } else {
      this.dialogSelf.close(this.getDataToRequest(
        {
          ...formValue,
          ...allowsData,
          queue_service_groups: this.getRequestServices(services),
          id: this.data?.user?.id
        },
        'update'));
    }
  }

  public changeActiveAll($event: MatCheckboxChange) {
    this.queueServiceGroupsFormArray.controls.forEach(control => {
      if (!control.get('type')) {
        if ((control.get('externalId').value ||
          this.data.queueGroups.find(q => q.id === control.get('id').value)?.parentId)) {
          control.get('active').setValue($event.checked);
          if ($event.checked) {
            control.get('accessible').setValue($event.checked);
          }
        }
      }
    });
    this.form.get('isAccessibleServices')
      .setValue(this.checkAccessibleServices());
  }

  public deactivateAll($event: MatCheckboxChange) {
    this.queueServiceGroupsFormArray.controls.forEach(control => {
      if (!control.value.accessibleDisabled && (control.get('externalId').value || this.data.queueGroups.find(q => q.id === control.get('id').value)?.parentId)) {
        control.get('accessible').setValue(false);
        if (control.value.active) {
          control.get('active').patchValue(false);
        }
      }
    });
    this.checkAccessibleServices();
    this.form.get('isActiveAllServices')
      .setValue(this.checkAllServices());
  }

  public checkAccessibleServices(): boolean {
    const accessibleAll =  this.queueServiceGroupsFormArray.controls
      .filter(control => {
        return !control.value.accessibleDisabled && (control.get('externalId').value || this.data.queueGroups.find(q => q.id === control.get('id').value)?.parentId)
      })
      .some((control: UntypedFormGroup) => {
      return control.value.accessible;
    });
    this.disabledAccessibleAll = !accessibleAll;
    return accessibleAll;
  }


  public checkAllServices(): boolean {
    return this.queueServiceGroupsFormArray.controls.some((control: UntypedFormGroup) => {
      return control.get('active').value &&
        (control.get('externalId').value ||
          this.data.queueGroups.find(q => q.id === control.get('id').value)?.parentId);
    });
  }

  private filterRoles(): void {
    if (this.currentUserType !== 'admin') {
      this.filteredRoles = this.data.roles.filter(role => !role.isRestricted || this.data?.user?.roleIds?.includes(role.id));
    } else {
      this.filteredRoles = this.data.roles;
    }
  }

  public resetCards() {
    this.loadingResetCards = true;
    this.dispSettingsApiService.clearDispatcherCards(this.data.user.externalId)
      .pipe(
        tap(() => {
          this.userCards = [];
          this.handleEventService.openSnackBar('UTAX_RESET_CARDS_SUCCESS');
          this.cdr.detectChanges();
        }),
        finalize(() => this.loadingResetCards = false),
        untilDestroyed(this)
      )
      .subscribe();
  }

  changed() {
    if (this.form.get('weekends').value.length < 3) {
      this.selectedWeekends = this.form.get('weekends').value;
    } else {
      this.form.get('weekends').setValue(this.selectedWeekends);
    }
  }

  clearScaleCalls() {
    const config = {
      width: '442px',
      backdropClass: 'request-dialog-backdrop',
      panelClass: 'request-reject-dialog-container',
      data: {
        id: this.data.user.id
      },
      disableClose: true
    };
    this.dialog.open(ClearScaleCallsModalComponent, config);
  }

  public passwordResetDialog(): void {
    this.dialog.open(DispPasswordResetDialogComponent, {
      panelClass: 'request-activate-dialog-container',
      backdropClass: 'request-dialog-backdrop',
      width: '500px',
      disableClose: true,
      data: {
        phone_number: this.data?.user?.phoneNumber,
        id: this.data?.user?.externalId
      }
    }).afterClosed().subscribe((result) => {
      if (result) {
        this.cdr.detectChanges();
        this.handleEventService.openSnackBar('DISP_PASSWORD_GENERATED_SUCCESS');
      }
    });
  }
}
