import {ImageInterface} from '../interfaces/image.interface';

export const DRIVER_IMAGES_EMPTY: ImageInterface[] = [
  {type: 'driver_avatar', url: ''},
  {type: 'driver_license_front', url: ''},
  {type: 'driver_license_rear', url: ''},
];

export const VEHICLE_IMAGES_EMPTY: ImageInterface[] = [
  {type: 'vehicle_registration_front', url: ''},
  {type: 'vehicle_registration_rear', url: ''},
  {type: 'vehicle_front', url: ''},
  {type: 'vehicle_rear', url: ''},
  {type: 'vehicle_left_side', url: ''},
  {type: 'vehicle_right_side', url: ''},
  {type: 'vehicle_interior_front', url: ''},
  {type: 'vehicle_interior_rear', url: ''},
];

export const CHANGE_VEHICLE_NUMBER_IMAGES_EMPTY: ImageInterface[] = [
  {type: 'vehicle_front', url: ''},
  {type: 'vehicle_rear', url: ''},
  {type: 'vehicle_left_side', url: ''},
  {type: 'vehicle_right_side', url: ''},
  {type: 'registration_front', url: ''},
  {type: 'registration_rear', url: ''}
];
export const CHANGE_VEHICLE_NUMBER_IMAGES_OLD_EMPTY: ImageInterface[] = [
  {type: 'driver', url: ''},
  {type: 'vehicle_front', url: ''},
  {type: 'vehicle_rear', url: ''},
  {type: 'vehicle_left_side', url: ''},
  {type: 'vehicle_right_side', url: ''},
  {type: 'registration_front', url: ''},
  {type: 'registration_rear', url: ''}
];

export const CHANGE_VEHICLE_IMAGES_EMPTY: ImageInterface[] = [
  {type: 'vehicle_front', url: ''},
  {type: 'vehicle_rear', url: ''},
  {type: 'vehicle_left_side', url: ''},
  {type: 'vehicle_right_side', url: ''},
  {type: 'registration_front', url: ''},
  {type: 'registration_rear', url: ''},
  {type: 'vehicle_interior_front', url: ''},
  {type: 'vehicle_interior_rear', url: ''},
];

export const CHANGE_VEHICLE_IMAGES_OLD_EMPTY: ImageInterface[] = [
  {type: 'vehicle_front', url: ''},
  {type: 'vehicle_rear', url: ''},
  {type: 'vehicle_left_side', url: ''},
  {type: 'vehicle_right_side', url: ''},
  {type: 'vehicle_registration_front', url: ''},
  {type: 'vehicle_registration_rear', url: ''},
  {type: 'vehicle_interior_front', url: ''},
  {type: 'vehicle_interior_rear', url: ''},
];

export const UPDATE_DRIVER_AVATAR_IMAGES_EMPTY: ImageInterface[] = [
  {type: 'driver', url: ''},
];
