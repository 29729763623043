import { SplitViewComponent } from './split-view/split-view.component';
import { RemoteWorkDialogComponent } from './remote-work-dialog/remote-work-dialog.component';
import {
  PhoneNumberVerificationDialogComponent
} from './phone-number-verification-dialog/phone-number-verification-dialog.component';
import {CreateTinDialogComponent} from "./create-tin-dialog/create-tin-dialog.component";
import {PreCheckDialogComponent} from "./pre-check-dialog/pre-check-dialog.component";

export const components = [
  SplitViewComponent,
  RemoteWorkDialogComponent,
  PhoneNumberVerificationDialogComponent,
  CreateTinDialogComponent,
  PreCheckDialogComponent
];

export * from './split-view/split-view.component';
export * from './remote-work-dialog/remote-work-dialog.component';
export * from './phone-number-verification-dialog/phone-number-verification-dialog.component';
export * from './create-tin-dialog/create-tin-dialog.component';
export * from './pre-check-dialog/pre-check-dialog.component';
