import { NgModule } from '@angular/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import {MatRippleModule, MAT_DATE_LOCALE, MAT_DATE_FORMATS, MatNativeDateModule} from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DomSanitizer } from '@angular/platform-browser';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';

const modules = [
  MatAutocompleteModule,
  MatButtonModule,
  MatButtonToggleModule,
  MatCardModule,
  MatCheckboxModule,
  MatChipsModule,
  MatTableModule,
  MatDatepickerModule,
  MatDialogModule,
  MatExpansionModule,
  MatFormFieldModule,
  MatGridListModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatMenuModule,
  MatPaginatorModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatRadioModule,
  MatRippleModule,
  MatSelectModule,
  MatSidenavModule,
  MatSlideToggleModule,
  MatSliderModule,
  MatSnackBarModule,
  MatSortModule,
  MatStepperModule,
  MatTabsModule,
  MatToolbarModule,
  MatTooltipModule,
  MatMomentDateModule,
  MatNativeDateModule
];

const DD_MM_YYYY_FORMAT = {
  parse: {
    dateInput: 'DD.MM.YYYY'
  },
  display: {
    dateInput: 'DD.MM.YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  }
};

@NgModule({
  declarations: [],
  imports: [...modules],
  exports: [...modules],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { displayDefaultIndicatorType: false }
    },
    { provide: MAT_DATE_LOCALE, useValue: 'uk-UA' },
    { provide: MAT_DATE_FORMATS, useValue: DD_MM_YYYY_FORMAT }
  ]
})
export class MaterialModule {
  constructor(iconRegistry: MatIconRegistry, sanitizer: DomSanitizer) {
    const icons = [
      'add',
      'create',
      'insert_chart',
      'message',
      'wheel',
      'waiting',
      'completed',
      'processing',
      'refueling',
      'washing',
      'hand',
      'delete',
      'remove',
      'call_driver',
      'logist',
      'edit_driver',
      'add_photo',
      'call_persone',
      'lock',
      'exit',
      'menu',
      'check-circle',
      'remove-circle',
      'time-back',
      'smartphone',
      'lightning',
      'magnet',
      'phone-in-arrow',
      'strange-driver',
      'wifi',
      'problem',
      'wifi-with-arrow',
      'call-end',
      'cab',
      'edit',
      'call_to_driver',
      'calendar',
      'conditioner',
      'nosmoking',
      'end_point',
      'start_point',
      'more_addresses',
      'show_more_arrow_down',
      'show_more_arrow_up',
      'cash',
      'cashless',
      'coin_dollar',
      'car_at_home',
      'drivers',
      'black_list',
      'unconfirmed_claims',
      'column',
      'map',
      'pre_order',
      'assigned',
      'english',
      'animals',
      'talktoyou',
      'urgently',
      'delivery',
      'luggage',
      'l-reserve',
      'arrow_down',
      'arrow_up',
      'clear',
      'finish_shift',
      'open_shift',
      'refill_req',
      'washing_req',
      'coffee',
      'cancel',
      'ws-status-green',
      'ws-status-yellow',
      'ws-status-red',
      'ws-status-fail',
      'add_one',
      'archive',
      'receipt',
      'sound_on',
      'sound_off',
      'meet_sign',
      'user_address',
      'is_popular_address',
      'coins',
      'zoom',
      'check-circle-full',
      'in-progress',
      'house',
      'service_filter_icon',
      'flag',
      'cab-online',
      'pre_order_disp_list',
      'sms_notification_in_progress',
      'sms_notification_error',
      'sms_notification_sended',
      'fcm_notification_error',
      'fcm_notification_in_progress',
      'fcm_notification_sended',
      'phone_missed',
      'phone_notification_error',
      'phone_notification_sended',
      'phone_notification_waiting',
      'phone_notification_pending',
      'phone_notification_sent',
      'phone_notification_undelivered',
      'hold_disp',
      'car',
      'unassign_disp',
      'empty_trunk',
      'silent_driver',
      '5passangers',
      '6passangers',
      '7passangers',
      'intercity',
      'techsupport',
      'garbage',
      'timelapse',
      'keyboard',
      'phone_payments',
      'driver_partner',
      'description',
      'assigned-white',
      'logist-persone',
      'robo-home',
      'robo-home-map',
      'group_add',
      'logist-persone',
      'call-in-action',
      'ether',
      'refill_req_crosed',
      'washing_req_crosed',
      'base',
      'base_crosed',
      'car-class-del-active',
      'car-class-del-dis',
      'car-cog',
      'bindings-default-option-inactive',
      'bindings-default-option-active',
      'arrow_right',
      'sort',
      'arrow_right',
      'end-to-end',
      'arrow_left',
      'accept-phone',
      'reject-phone',
      'pass-icon',
      'house-inactive',
      'house-active',
      'headphone',
      'simple-house',
      'phone-send-clock',
      'phone-cancel',
      'person-with-one',
      'phone-stop',
      'phone-question',
      'accept-disabled',
      'accept-active',
      'cancel-disabled',
      'cancel-active',
      'edit-disabled-in-circle',
      'sort',
      'coffee-without-fill',
      'upload',
      'download',
      'talk',
      'deafness',
      'last-call',
      'wallet',
      'information-outline',
      'alert',
      'delete-original',
      'credit-card',
      'credit-card-check',
      'credit-card-clock',
      'credit-card-lock',
      'credit-card-plus',
      'credit-card-remove',
      'credit-card-refund',
      'help-circle',
      'phone',
      'wallet-plus',
      'arrow-up-bold-box',
      'arrow-down-bold-box',
      'alert-decagram',
      'check-decagram',
      'swap-horizontal-bold',
      'arrow-right',
      'phone-in-talk',
      'phone-settings',
      'phone-incoming',
      'phone-clock',
      'phone-outgoing',
      'phone-incoming-outgoing',
      'rotate',
      'd-unlock',
      'd-lock',
      'rotate',
      'adb',
      'magnet-outlined',
      'ether-outlined',
      'waiting-calls',
      'taxi-838',
      'camera-big',
      'old-phone',
      'dial',
      'vodafone-red',
      'kyivstar-red',
      'lifecell-red',
      'ukrtelecom-red',
      'sand-clock',
      'chat-passenger',
      'chat-driver',
      'chat-passenger-kick',
      'chat-driver-kick',
      'chat-message-read',
      'chat-message-send',
      'not-allowed',
      'request-creating',
      'request-editing',
      'request-viewing',
      'unlock-tel-passenger',
      'unlock-req-passenger',
      'archive-new',
      'lock-tel-passenger',
      'lock-req-passenger',
      'plus-circle',
      'cycle-icon',
      'send_message',
      'operator_chat',
      'user_chat',
      'driver_chat',
      'system_chat'
    ];
    icons.map(icon => {
      iconRegistry.addSvgIcon(icon, sanitizer.bypassSecurityTrustResourceUrl(`assets/imgs/icons/${icon}.svg`));
    });
  }
}
